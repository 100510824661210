import { useRouteLoaderData } from "react-router-dom";
import { User } from "../../services/user/userModel";
import ContactForm from "./ContactForm";
import { ReturnButton } from "../../components/ReturnButton";
import { useTranslation } from "react-i18next";

const ContactPage = () => {
  const { t } = useTranslation();
  const data = useRouteLoaderData("logged-user") as {
    loggedUser?: User;
  } | null;

  const loggedUser = data?.loggedUser;

  return (
    <div className="layout_content --bg--organization">
      <div className="page-content container --wrap--m">
        <div>
          <ReturnButton>{t("common:contact.RETURN_BUTTON")}</ReturnButton>
        </div>
        <div className="page_head lblock">
          <h1 className="page_title">{t("common:contact.PAGE_TITLE")}</h1>
        </div>

        <div className="lblock--l">
          <ContactForm
            userMail={loggedUser?.email}
            userName={
              loggedUser?.firstname &&
              loggedUser?.lastname &&
              loggedUser?.firstname + " " + loggedUser?.lastname
            }
          />
        </div>
      </div>
    </div>
  );
};

export default ContactPage;
