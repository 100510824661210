import { ReactNode } from "react";
import DisplayPrice from "./DisplayPrice";
import { useTranslation } from "react-i18next";
import {
  Plan,
  RecurringInterval,
} from "../services/subscription/subscriptionModel";
import { ADDITIONAL_LICENCES_PRICE } from "../services/config";

const DisplayPlan = ({
  plan,
  recurrency,
  cta,
}: {
  plan: Plan;
  recurrency: RecurringInterval;
  cta?: ReactNode;
}) => {
  const { t } = useTranslation(["subscription"]);

  return (
    <div className="card --outlined">
      <div className="card_body">
        <div className="cblock title--40">{plan.label}</div>

        <div className="cblock title--70">
          <DisplayPrice
            taxFree
            amount={
              recurrency === "monthly"
                ? plan.stripeMonthlyPrice.amount
                : plan.stripeAnnualPrice.amount
            }
            currency={
              recurrency === "monthly"
                ? plan.stripeMonthlyPrice.currency
                : plan.stripeAnnualPrice.currency
            }
            addon={` ${
              recurrency === "monthly"
                ? t("subscription:PER_MONTH")
                : t("subscription:PER_YEAR")
            }`}
          />
        </div>

        <div className="cblock plan-features-list">
          <div className="feature">
            {t("subscription:UNLIMITED_ACCESS_TO_CANDIDATES")}
          </div>
          <div className="feature">
            {t("subscription:CLIENT_SUPPORT_INCLUDED")}
          </div>
          <div className="feature">{t("subscription:WITHOUT_COMMITMENT")}</div>
          {plan.type === "education" && (
            <div className="feature">
              {t("subscription:SPECIFIC_PRICE_EDUCATION")}
            </div>
          )}
          {plan.type === "company" && (
            <div className="feature">
              {t("subscription:INCLUDED_COMPANY_CAPSULES", {
                replace: {
                  count: 2,
                },
              })}
            </div>
          )}
        </div>

        <div className="info lblock">
          {t("subscription:ADDITIONNAL_LICENCES", {
            replace: {
              price: DisplayPrice({
                amount: ADDITIONAL_LICENCES_PRICE,
                currency: "eur",
                textOnly: true,
                taxFree: true,
                addon:
                  recurrency === "monthly"
                    ? t("subscription:PER_MONTH")
                    : t("subscription:PER_YEAR"),
              }),
            },
          })}
        </div>

        {cta}
      </div>
    </div>
  );
};

export default DisplayPlan;
