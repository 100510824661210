import { LoggedUserData, LoginData } from "./authModel";
import { loggerBuilder } from "../logger";
import {
  loginRequest,
  registerRequest,
  lostPasswordRequest,
  resetPasswordRequest,
  validateUserRequest,
  updatePasswordRequest,
  registerOrganizationRequest,
  acceptInvitationRequest,
} from "./authApi";
import baseAPI, { LOGGED_USER_LOCALSTORAGE_ID } from "../api";
import { NewUserData, User } from "../user/userModel";
import { toastsWithIntl } from "../toastService";
import {
  Invitation,
  NewOrganizationData,
} from "../organization/organizationModel";
import * as Sentry from "@sentry/react";
import { Plan, RecurringInterval } from "../subscription/subscriptionModel";

const logger = loggerBuilder("auth-service");

interface AuthService {
  isLoggedIn(): boolean;
  getLoggedUserId(): User["id"] | null;
  login(loginData: LoginData): Promise<void>;
  logout(): Promise<void>;
  register(newUserData: NewUserData): Promise<void>;
  registerOrganization(newOrganization: {
    user: NewUserData;
    organization: NewOrganizationData;
    profileShareUid?: string;
    paymentMethodId?: string;
    planId?: Plan["id"];
    recurrency?: RecurringInterval;
    promoCode?: string | null;
  }): Promise<User>;
  validateUser(guid: string): Promise<void>;
  lostPassword(email: User["email"]): Promise<void>;
  resetPassword(
    guid: string,
    newPassword: LoginData["password"],
  ): Promise<void>;
  updatePassword(oldPassword: string, newPassword: string): Promise<void>;
  acceptInvitation(
    invitationUid: Invitation["uid"],
    newOrganization: {
      user: NewUserData;
      organization?: NewOrganizationData;
    },
  ): Promise<void>;
}

export const authService = () => {
  const { toastError, toastSuccess } = toastsWithIntl(["auth"]);

  function setLoggedUser(loggedUser: LoggedUserData | null) {
    if (loggedUser !== null) {
      localStorage.setItem(
        LOGGED_USER_LOCALSTORAGE_ID,
        JSON.stringify(loggedUser),
      );
      baseAPI.defaults.headers.Authorization = `Bearer ${loggedUser.accessToken}`;
      logger.debug("User is authenticated");
      Sentry.setUser({ id: loggedUser.id });
    } else {
      localStorage.removeItem(LOGGED_USER_LOCALSTORAGE_ID);
      delete baseAPI.defaults.headers.Authorization;

      logger.debug("User is disconnected");
      Sentry.setUser(null);
    }
  }

  function isLoggedIn() {
    return localStorage.getItem(LOGGED_USER_LOCALSTORAGE_ID) !== null;
  }

  function getLoggedUserId() {
    const loggedUser = localStorage.getItem(LOGGED_USER_LOCALSTORAGE_ID);
    return loggedUser ? (JSON.parse(loggedUser) as LoggedUserData).id : null;
  }

  const login: AuthService["login"] = (loginData) => {
    return loginRequest(loginData).then(
      (loggedUser) => {
        setLoggedUser(loggedUser);
      },
      (err) => {
        switch (err?.response.status) {
          case 412:
            toastError("auth:login.NOT_VALIDATED_MAIL", {
              id: "login-412",
            });
            break;
          case 401:
            toastError("auth:login.INVALID_PASSWORD", {
              id: "login-401",
            });
            break;
          default:
            toastError("auth:login.ERROR", {
              id: "login-default",
            });
        }

        return Promise.reject(err);
      },
    );
  };

  const logout = () => {
    setLoggedUser(null);
    return Promise.resolve();
  };

  const register: AuthService["register"] = (newUser) => {
    return registerRequest(newUser).then(
      () => {
        toastSuccess("auth:register.user.SUCCESS");
        return Promise.resolve();
      },
      (err) => {
        if (err.response.status === 412) {
          toastError("auth:register.user.ERROR_ALREADY_REGISTERED");
        } else {
          toastError("auth:register.user.ERROR");
        }
        return Promise.reject(err);
      },
    );
  };

  const registerOrganization: AuthService["registerOrganization"] = (
    newUserAndOrganization,
  ) => {
    return registerOrganizationRequest(newUserAndOrganization).then(
      (user) => {
        toastSuccess("auth:register.organization.SUCCESS");
        return Promise.resolve(user);
      },
      (err) => {
        if (err.response.status === 412) {
          toastError("auth:register.organization.ERROR_ALREADY_REGISTERED");
        } else {
          toastError("auth:register.organization.ERROR");
        }
        return Promise.reject(err);
      },
    );
  };

  const validateUser: AuthService["validateUser"] = (guid) => {
    return validateUserRequest(guid).then(
      () => {
        toastSuccess("auth:validate-user.SUCCESS", {
          id: "validate-user-success",
        });
      },
      (err) => {
        toastError("auth:validate-user.ERROR");
        return Promise.reject(err);
      },
    );
  };

  const lostPassword: AuthService["lostPassword"] = (email) =>
    lostPasswordRequest(email);

  const resetPassword: AuthService["resetPassword"] = (guid, newPassword) =>
    resetPasswordRequest(guid, newPassword).then(
      () => {
        toastSuccess("auth:reset-password.SUCCESS");
      },
      (err) => {
        if (err.response.status === 400) {
          // TODO : erreur lien invalide (implémentation back à faire)
          toastError("auth:reset-password.ERROR_INVALID_LINK");
        } else if (err.response.status === 406) {
          toastError("auth:reset-password.ERROR_LINK_ALREADY_USE");
        } else {
          toastError("auth:reset-password.ERROR");
        }
        return Promise.reject(err);
      },
    );

  const updatePassword: AuthService["updatePassword"] = (
    oldPassword,
    newPassword,
  ) => {
    return updatePasswordRequest(oldPassword, newPassword).then(
      () => {
        toastSuccess("auth:password-changed.SUCCESS");
        return Promise.resolve();
      },
      (err) => {
        toastError("auth:password-changed.ERROR");
        return Promise.reject(err);
      },
    );
  };

  const acceptInvitation: AuthService["acceptInvitation"] = (
    invitationUid,
    newUserAndOrganization,
  ) => {
    return acceptInvitationRequest(invitationUid, newUserAndOrganization).then(
      () => {
        toastSuccess("auth:register.accept-invitation.SUCCESS");
      },
      (err) => {
        toastError("auth:register.accept-invitation.ERROR");
        return Promise.reject(err);
      },
    );
  };

  return {
    isLoggedIn,
    getLoggedUserId,
    login,
    logout,
    register,
    registerOrganization,
    validateUser,
    lostPassword,
    resetPassword,
    updatePassword,
    acceptInvitation,
  };
};
