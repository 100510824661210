import { PropsWithChildren } from "react";
import { User } from "../services/user/userModel";
import { useTranslation } from "react-i18next";

function PrivateSidebar({
  children,
  user,
  onCloseMenu,
}: PropsWithChildren<{ user: User; onCloseMenu(): void }>) {
  const { t } = useTranslation();

  return (
    <div className="layout_sidebar">
      <div className="sidebar_head">
        <button className="close-sidebar-btn" onClick={onCloseMenu}></button>

        <p className="welcome">{t("common:sidebar.WELCOME")}</p>
        <p className="user-name">{user.firstname}</p>
        <hr />
      </div>

      {children}
    </div>
  );
}

export default PrivateSidebar;
