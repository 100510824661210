import Dialog from "../../../components/Dialog";
import {
  INVITATION_MEMBERSHIP_TYPES,
  Invitation,
  Organization,
} from "../../../services/organization/organizationModel";
import { useTranslation } from "react-i18next";
import SxForm from "../../../forms/SxForm";
import { User } from "../../../services/user/userModel";
import { object, string } from "yup";
import { transformEmptyToUndefined } from "../../../react-helpers/yup";
import SxField from "../../../forms/fields/SxField";
import { useMemo } from "react";
import {
  getUserOrganizationStatus,
  organizationService,
} from "../../../services/organization/organizationService";
import SubmitButton from "../../../components/SubmitButton";
import ValidationErrors from "../../../forms/ValidationErrors";
import { authService } from "../../../services/auth/authService";
import { toastsWithIntl } from "../../../services/toastService";
import useReload from "../../../hooks/useReload";
import { userService } from "../../../services/user/userService";
import { cx } from "../../../react-helpers/css";

interface Props {
  onClose(): void;
  organization: Organization;
  user?: User;
  invitation?: Invitation;
  seatsLeft: number;
}

const { getLoggedUserId } = authService();
const { inviteToJoinOrganization, updateInvitation, resendInvitationMail } =
  organizationService();
const { updateUserById } = userService();

const InviteAndManageUserDialog = ({
  onClose,
  user,
  invitation,
  organization,
  seatsLeft,
}: Props) => {
  const { t } = useTranslation(["organization"]);
  const { toastSuccess } = toastsWithIntl(["organization"]);
  const reload = useReload();

  const userStatus = useMemo(
    () =>
      user ?? invitation
        ? getUserOrganizationStatus(organization.id, user, invitation)
        : null,
    [organization, user, invitation],
  );

  return (
    <Dialog onClose={onClose} className="--branded">
      <>
        <div className="popup_head">
          <div className="popup_title">
            {user ?? invitation
              ? t("organization:users.invitations.UPDATE_USER")
              : t("organization:users.invitations.INVITE_USER")}
          </div>
        </div>
        <div className="popup_body">
          <SxForm
            initialValues={{
              email: user?.email ?? invitation?.email ?? "",
              membershipType:
                user?.organizationId === organization.id
                  ? user?.adminOrga
                    ? "ADMIN"
                    : "MEMBER"
                  : invitation?.membershipType ?? "GUEST",
              firstname: user?.firstname ?? invitation?.firstname ?? "",
              lastname: user?.lastname ?? invitation?.lastname ?? "",
            }}
            onSubmit={async (values) => {
              if (!user && !invitation)
                await inviteToJoinOrganization(organization.id, values);
              else if (
                invitation &&
                (invitation.membershipType === "GUEST" || !invitation.userId)
              )
                await updateInvitation(organization.id, invitation.id, values);
              else if (user)
                await updateUserById(user.id, {
                  adminOrga: values.membershipType === "ADMIN",
                });
              reload();
              onClose();
            }}
            validationSchema={object({
              email: string()
                .label(t("organization:users.invitations.labels.EMAIL"))
                .transform(transformEmptyToUndefined)
                .required()
                .disabled(!!user || !!invitation?.userId),
              membershipType: string()
                .label(
                  t("organization:users.invitations.labels.MEMBERSHIP_TYPE"),
                )
                .oneOf(INVITATION_MEMBERSHIP_TYPES)
                .required()
                .disabled(!!invitation?.userId),
              firstname: string()
                .label(t("organization:users.invitations.labels.FIRSTNAME"))
                .transform(transformEmptyToUndefined)
                .required()
                .disabled(!!user || !!invitation?.userId),
              lastname: string()
                .label(t("organization:users.invitations.labels.LASTNAME"))
                .transform(transformEmptyToUndefined)
                .required()
                .disabled(!!user || !!invitation?.userId),
            })}
            disabled={user?.id === getLoggedUserId()}
          >
            {userStatus && (
              <div className="cblock">
                <span
                  className={cx([
                    "user-status-chip",
                    userStatus === "active" && "--active",
                    userStatus === "disabled" && "--disabled",
                  ])}
                >
                  {userStatus === "active" &&
                    t("organization:users.status.ACTIVE")}
                  {userStatus === "pending" &&
                    t("organization:users.status.PENDING")}
                  {userStatus === "disabled" &&
                    t("organization:users.status.DISABLED")}
                </span>
              </div>
            )}

            <div className="grid--2 cblock">
              <SxField name="email" />
              <SxField name="membershipType" as="select">
                {(!invitation?.userId && !user
                  ? INVITATION_MEMBERSHIP_TYPES
                  : invitation?.membershipType !== "GUEST"
                    ? (["ADMIN", "MEMBER"] as const)
                    : (["GUEST"] as const)
                ).map((membershipType) => (
                  <option
                    key={`membership-${membershipType}`}
                    value={membershipType}
                  >
                    {t(
                      `organization:users.invitations.membership.${membershipType}`,
                    )}
                  </option>
                ))}
              </SxField>
              <SxField name="firstname" />
              <SxField name="lastname" />
            </div>

            {(invitation ?? user) && (
              <div className="cblock">
                <div className="lrow --center--v">
                  {invitation && !invitation.userId && (
                    <div>
                      <SubmitButton
                        className="link --light"
                        type="button"
                        onClick={() => {
                          return resendInvitationMail(
                            organization.id,
                            invitation.id,
                          );
                        }}
                      >
                        {t("organization:users.invitations.RESEND_INVITATION")}
                      </SubmitButton>
                      {" | "}
                      <button
                        className="link --light"
                        type="button"
                        onClick={(ev) => {
                          if (ev.isTrusted)
                            void navigator.clipboard
                              .writeText(
                                `${location.origin}/join-org/${invitation.uid}`,
                              )
                              .then(() => {
                                toastSuccess(
                                  "organization:users.invitations.LINK_COPIED",
                                  {
                                    id: "invitation-link",
                                  },
                                );
                              });
                        }}
                      >
                        {t("organization:users.invitations.COPY_REGISTER_LINK")}
                      </button>
                    </div>
                  )}
                </div>
              </div>
            )}

            <ValidationErrors />
            <div className="form_footer --txt--center">
              <div className="cblock">
                <SubmitButton className="btn">
                  {user ?? invitation
                    ? t("organization:users.invitations.UPDATE")
                    : t("organization:users.invitations.INVITE")}
                </SubmitButton>
              </div>
              {user?.id !== getLoggedUserId() &&
                (userStatus !== "disabled" || seatsLeft > 0) &&
                (user ?? invitation?.userId) && (
                  <div className="cblock">
                    <SubmitButton
                      className="link --light"
                      type="button"
                      onClick={async () => {
                        if (invitation?.membershipType === "GUEST") {
                          await updateInvitation(
                            organization.id,
                            invitation.id,
                            {
                              disabledDate:
                                userStatus === "disabled"
                                  ? null
                                  : new Date().toISOString(),
                            },
                          );
                        } else if (user) {
                          await updateUserById(user.id, {
                            organizationMembershipStatus:
                              userStatus === "disabled" ? "active" : "disabled",
                          });
                        }

                        reload();
                        onClose();
                      }}
                    >
                      {userStatus === "disabled"
                        ? t("organization:users.invitations.ENABLE")
                        : t("organization:users.invitations.DISABLE")}
                    </SubmitButton>
                  </div>
                )}
            </div>
          </SxForm>
        </div>
      </>
    </Dialog>
  );
};

export default InviteAndManageUserDialog;
