import { object, string } from "yup";
import SxForm from "../../forms/SxForm";
import SxField from "../../forms/fields/SxField";
import SubmitButton from "../../components/SubmitButton";
import { POSITIONS, User } from "../../services/user/userModel";
import { userService } from "../../services/user/userService";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import useReload from "../../hooks/useReload";
import { useCountries } from "../../services/profile/profileService";
import { transformEmptyToNull } from "../../react-helpers/yup";

const IdentitySection = ({ user }: { user: User }) => {
  const { t } = useTranslation();
  const { updateUserById } = userService();
  const reload = useReload();
  const countries = useCountries();

  return (
    <div>
      <h2 className="section_title">{t("accounts:identity.SECTION_TITLE")}</h2>
      <div className="lblock">
        <SxForm
          initialValues={{
            firstname: user.firstname,
            lastname: user.lastname,
            email: user.email,
            phoneNumber: user.phoneNumber,
            ...(user.organizationId
              ? {
                  positionInOrganization: user.positionInOrganization,
                }
              : {
                  country: user.country,
                }),
          }}
          onSubmit={(values) => {
            return updateUserById(user.id, {
              firstname: values.firstname,
              lastname: values.lastname,
              phoneNumber: values.phoneNumber,
              positionInOrganization:
                "positionInOrganization" in values
                  ? values.positionInOrganization
                  : null,
            }).then(() => {
              reload();
            });
          }}
          validationSchema={object({
            email: string()
              .label(t("accounts:identity.labels.EMAIL"))
              .disabled(),
            firstname: string()
              .label(t("accounts:identity.labels.FIRSTNAME"))
              .required(),
            lastname: string()
              .label(t("accounts:identity.labels.LASTNAME"))
              .required(),
            ...(user.organizationId
              ? {
                  phoneNumber: string()
                    .label(t("accounts:identity.labels.PHONE"))
                    .phoneNumber()
                    .transform(transformEmptyToNull)
                    .nullable(),
                  positionInOrganization: string()
                    .label(t("accounts:identity.labels.POSITION"))
                    .required()
                    .oneOf(POSITIONS.map(([k]) => k)),
                }
              : {
                  country: string()
                    .label(t("organization:capsule-form.labels.COUNTRY"))
                    .oneOf(
                      countries.map((c) => c[0]),
                      t("validation:capsule.INVALID_COUNTRY"),
                    )
                    .required(),
                }),
          })}
        >
          <SxField name="email" />
          <SxField name="firstname" />
          <SxField name="lastname" />
          {user.organizationId ? (
            <>
              <SxField name="positionInOrganization" as="select">
                <option value={""}>
                  {t("accounts:identity.placeholders.POSITION")}
                </option>
                {POSITIONS.map(([k, v]) => (
                  <option key={k} value={k}>
                    {v}
                  </option>
                ))}
              </SxField>
              <SxField
                name="phoneNumber"
                placeholder={t("accounts:identity.placeholders.PHONE")}
              />
            </>
          ) : (
            <SxField name={"country"} as={"select"}>
              {countries.map(([code, name]) => (
                <option key={code} value={code}>
                  {name}
                </option>
              ))}
            </SxField>
          )}
          <div className="lblock--l --txt--center">
            <SubmitButton className="btn--2" type="submit">
              {t("accounts:identity.buttons.SAVE")}
            </SubmitButton>
            <div className="cblock --txt--center">
              <Link
                className="link --light --underlined"
                to={`/${user.organizationId ? "organization" : "candidate"}/contact?object=${encodeURIComponent("Suppression de compte 2 Minutes Max")}&message=${encodeURIComponent("Je souhaite supprimer mon compte")}`}
              >
                {t("accounts:identity.buttons.DELETE_ACCOUNT")}
              </Link>
            </div>
          </div>
        </SxForm>
      </div>
    </div>
  );
};

export default IdentitySection;
