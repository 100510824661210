import {
  Plan,
  RecurringInterval,
} from "../../../services/subscription/subscriptionModel";
import ChoosePlan from "../../../components/ChoosePlan";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import DisplayPrice from "../../../components/DisplayPrice";
import SubmitButton from "../../../components/SubmitButton";
import DisplayPlan from "../../../components/DisplayPlan";
import { cx } from "../../../react-helpers/css";
import { Organization } from "../../../services/organization/organizationModel";
import { subscriptionService } from "../../../services/subscription/subscriptionService";

const { subscribeToPlan } = subscriptionService();

const SubscriptionForm = ({
  plans,
  organization,
  onFinished,
}: {
  plans: Plan[];
  organization: Organization;
  onFinished?(): void;
}) => {
  const { t } = useTranslation(["auth", "subscription"]);

  const [choosenPlan, setChoosenPlan] = useState<Plan | null>(null);
  const [choosenRecurrency, setChoosenRecurrency] =
    useState<RecurringInterval>("annual");
  const [promoCode, setPromoCode] = useState("");

  if (!choosenPlan)
    return (
      <ChoosePlan
        plans={plans}
        onChoice={(plan, recurrency) => {
          setChoosenPlan(plan);
          setChoosenRecurrency(recurrency);
        }}
      />
    );
  else
    return (
      <div>
        <div className="cblock lrow --center--h">
          <div>{t("subscription:MENSUAL")}</div>
          <div>
            <button
              type="button"
              className={cx([
                "btn--toggle",
                choosenRecurrency === "monthly" && "--opt1",
                choosenRecurrency !== "monthly" && "--opt2",
              ])}
              onClick={() =>
                setChoosenRecurrency((prev) =>
                  prev === "monthly" ? "annual" : "monthly",
                )
              }
            />
          </div>
          <div>{t("subscription:ANNUAL")}</div>
        </div>

        <div className="cblock">
          <DisplayPlan
            plan={choosenPlan}
            recurrency={choosenRecurrency}
            cta={
              <div className="cblock --txt--center">
                <button onClick={() => setChoosenPlan(null)} className="link">
                  {t("auth:register.CHANGE_PLAN")}
                </button>
              </div>
            }
          />
        </div>

        <div className="cblock form-block">
          <div className="form-block">
            <label className="field-label">
              {t("register.labels.FORMULA")}
            </label>
            <select
              className="select"
              value={choosenRecurrency}
              onChange={(ev) => {
                setChoosenRecurrency(ev.target.value as any);
              }}
            >
              <option value="annual">
                {choosenPlan.label} (
                <DisplayPrice
                  textOnly
                  amount={choosenPlan.stripeAnnualPrice.amount}
                  currency={choosenPlan.stripeAnnualPrice.currency}
                  taxFree
                  addon={` ${t("subscription:PER_YEAR")}`}
                />
                )
              </option>
              <option value="monthly">
                {choosenPlan.label} (
                <DisplayPrice
                  textOnly
                  amount={choosenPlan.stripeMonthlyPrice.amount}
                  currency={choosenPlan.stripeMonthlyPrice.currency}
                  taxFree
                  addon={` ${t("subscription:PER_MONTH")}`}
                />
                )
              </option>
            </select>
          </div>

          <div className="form-block">
            <label className="field-label" htmlFor="promoCode">
              {t("auth:register.labels.PROMO_CODE")}
            </label>
            <input
              id="promoCode"
              className="input"
              value={promoCode}
              onChange={(ev) => setPromoCode(ev.target.value)}
            />
          </div>
        </div>

        <div className="lblock--l --txt--center">
          <SubmitButton
            type="button"
            className="btn--1"
            onClick={async () => {
              await subscribeToPlan(
                organization.id,
                choosenPlan.id,
                choosenRecurrency,
                promoCode,
              );
              onFinished?.();
            }}
          >
            {t("register.SUBSCRIBE")}
          </SubmitButton>
        </div>
      </div>
    );
};

export default SubscriptionForm;
