import { useRouteLoaderData } from "react-router-dom";
import { User } from "../../../services/user/userModel";
import { Organization } from "../../../services/organization/organizationModel";
import { useTranslation } from "react-i18next";
import SxForm from "../../../forms/SxForm";
import ValidationErrors from "../../../forms/ValidationErrors";
import SubmitButton from "../../../components/SubmitButton";
import { object, string } from "yup";
import { organizationService } from "../../../services/organization/organizationService";
import SxField from "../../../forms/fields/SxField";
import { useCountries } from "../../../services/profile/profileService";
import { ReturnButton } from "../../../components/ReturnButton";

const { updateOrganization } = organizationService();

const OrganizationPage = () => {
  const { loggedUser } = useRouteLoaderData("logged-user") as {
    loggedUser: User;
  };
  const { organization } = useRouteLoaderData("organization") as {
    organization: Organization;
  };
  const { t } = useTranslation([
    "organization",
    "common",
    "auth",
    "validation",
  ]);
  const countries = useCountries();

  return (
    <div className="layout_content --bg--organization">
      <div className="page-content container --wrap">
        <ReturnButton fallbackUrl="../.." />
        <h1 className="page_title">
          {t("organization:pro-account.PAGE_TITLE")}
        </h1>

        <div className="lblock">
          <h2>{t("organization:pro-account.ACCOUNT_INFOS")}</h2>
          <SxForm
            initialValues={{
              email: organization.email ?? loggedUser.email ?? "",
              phoneNumber:
                organization.phoneNumber ?? loggedUser.phoneNumber ?? "",
              legalName: organization.legalName,
            }}
            onSubmit={(values) => {
              return updateOrganization(organization.id, values);
            }}
            validationSchema={object({
              email: string()
                .email()
                .label(t("organization:pro-account.MANAGER_EMAIL"))
                .required(),
              phoneNumber: string().label(t("auth:register.labels.PHONE")),
              legalName: string()
                .label(t("auth:register.labels.LEGAL_NAME"))
                .required(),
            })}
          >
            <div className="form-block grid-2">
              <div>
                <SxField name="email" />
              </div>
              <div>
                <SxField name="phoneNumber" />
              </div>
            </div>
            <SxField name="legalName" />

            <ValidationErrors />
            <div className="lblock--l --txt--center">
              <SubmitButton type="submit" className="btn--2">
                {t("common:SAVE")}
              </SubmitButton>
            </div>
          </SxForm>
        </div>

        <div className="lblock">
          <h2>{t("organization:pro-account.BILLING_ADDRESS")}</h2>
          <SxForm
            initialValues={{
              addressLine1: organization.addressLine1 ?? "",
              addressLine2: organization.addressLine2 ?? "",
              postalCode: organization.postalCode ?? "",
              city: organization.city ?? "",
              country: organization.country ?? "FR",
            }}
            onSubmit={(values) => {
              return updateOrganization(organization.id, values);
            }}
            validationSchema={object({
              addressLine1: string()
                .label(t("auth:register.labels.ADDRESS_LINE1"))
                .required(),
              addressLine2: string().label(
                t("auth:register.labels.ADDRESS_LINE2"),
              ),
              postalCode: string()
                .label(t("auth:register.labels.POSTAL_CODE"))
                .required(),
              city: string().label(t("auth:register.labels.CITY")).required(),
              country: string()
                .label(t("auth:register.labels.COUNTRY"))

                .oneOf(
                  countries.map((c) => c[0]),
                  t("validation:register.INVALID_COUNTRY"),
                )
                .required(),
            })}
          >
            <SxField name="addressLine1" />
            <SxField name="addressLine2" />
            <div className="form-block grid-2">
              <div>
                <SxField name="postalCode" />
              </div>
              <div>
                <SxField name="city" />
              </div>
            </div>
            <SxField name={"country"} as={"select"}>
              {countries.map(([code, name]) => (
                <option key={code} value={code}>
                  {name}
                </option>
              ))}
            </SxField>

            <ValidationErrors />
            <div className="lblock--l --txt--center">
              <SubmitButton type="submit" className="btn--2">
                {t("common:SAVE")}
              </SubmitButton>
            </div>
          </SxForm>
        </div>
      </div>
    </div>
  );
};

export default OrganizationPage;
