import { RouteObject } from "react-router-dom";
import { redirectOnCondition } from "./routerHelpers";
import LoginPage from "../views/auth/login/LoginPage";
import LostPasswordPage from "../views/auth/lostPassword/LostPasswordPage";
import ResetPasswordPage from "../views/auth/lostPassword/ResetPasswordPage";
import { authService } from "../services/auth/authService";
import RegisterPage from "../views/auth/register/RegisterPage";
import { promiseAllObject } from "../react-helpers/promise";
import { subscriptionService } from "../services/subscription/subscriptionService";
import RegisterChoicePage from "../views/auth/register/RegisterChoicePage";
import { organizationService } from "../services/organization/organizationService";
import RegisterWithInvitationPage from "../views/auth/register/invitation/RegisterWithInvitationPage";

const { isLoggedIn } = authService();
const { getPlans } = subscriptionService();
const { getInvitation } = organizationService();

// NOTE: These routes are all the routes that will no longer be accessible once logged in
export const authRoutes: RouteObject = {
  path: "", // public
  children: redirectOnCondition(
    [
      {
        path: "login",
        element: <LoginPage />,
      },
      {
        path: "lost-password",
        element: <LostPasswordPage />,
      },
      {
        path: "reset-password/:guid",
        element: <ResetPasswordPage />,
      },
      {
        path: "register-choice",
        element: <RegisterChoicePage />,
      },
      {
        path: "register",
        element: <RegisterPage isOrganization={false} />,
        loader() {
          return promiseAllObject({
            plans: getPlans(),
          });
        },
      },
      {
        path: "register/organization",
        element: <RegisterPage isOrganization />,
        loader() {
          return promiseAllObject({
            plans: getPlans(),
          });
        },
      },
      {
        path: "join-org/:invitationUid",
        element: <RegisterWithInvitationPage />,
        loader({ params }) {
          return promiseAllObject({
            invitation: getInvitation(String(params.invitationUid)),
          });
        },
      },
    ],
    isLoggedIn,
    "/candidate",
  ),
};
