import { User } from "@sentry/react";
import baseAPI from "../api";
import { Profile } from "../profile/profileModel";
import {
  Invitation,
  NewOrganizationData,
  Organization,
} from "./organizationModel";

export const updateOrganizationRequest = (
  organizationId: Organization["id"],
  data: Partial<NewOrganizationData>,
): Promise<Organization> => {
  return baseAPI
    .put(`/organization/${organizationId}`, data)
    .then(({ data }) => data);
};

export const createOrganizationRequest = (
  data?: NewOrganizationData,
): Promise<Organization> => {
  return baseAPI.post("/organization", data).then(({ data }) => data);
};

export const getOrganizationRequest = (
  organizationId: Organization["id"],
): Promise<Organization> => {
  return baseAPI
    .get(`/organization/${organizationId}`)
    .then(({ data }) => data);
};

export function archiveSharedProfileRequest(
  profileUid: Profile["uid"],
  userId: User["id"],
  organizationId: Organization["id"],
): Promise<void> {
  return baseAPI.put(
    `/organization/${organizationId}/users/${userId}/share/${profileUid}/archive`,
  );
}

export function unarchiveSharedProfileRequest(
  profileUid: Profile["uid"],
  userId: User["id"],
  organizationId: Organization["id"],
): Promise<void> {
  return baseAPI.put(
    `/organization/${organizationId}/users/${userId}/share/${profileUid}/unarchive`,
  );
}

export function favoriteSharedProfileRequest(
  profileUid: Profile["uid"],
  userId: User["id"],
  organizationId: Organization["id"],
): Promise<void> {
  return baseAPI.put(
    `/organization/${organizationId}/users/${userId}/share/${profileUid}/favorite`,
  );
}

export function unfavoriteSharedProfileRequest(
  profileUid: Profile["uid"],
  userId: User["id"],
  organizationId: Organization["id"],
): Promise<void> {
  return baseAPI.put(
    `/organization/${organizationId}/users/${userId}/share/${profileUid}/unfavorite`,
  );
}

export function deleteSharedProfileRequest(
  profileUid: Profile["uid"],
  userId: User["id"],
  organizationId: Organization["id"],
): Promise<void> {
  return baseAPI.delete(
    `/organization/${organizationId}/users/${userId}/share/${profileUid}`,
  );
}

export function addPaymentMethodToOrganizationRequest(
  organizationId: Organization["id"],
  paymentMethodId: string,
): Promise<void> {
  return baseAPI
    .post(`/organization/${organizationId}/register-payment-method`, {
      paymentMethodId,
    })
    .then(({ data }) => data);
}

export function inviteToJoinOrganizationRequest(
  organizationId: Organization["id"],
  invitation: Pick<
    Invitation,
    "firstname" | "lastname" | "email" | "membershipType"
  >,
) {
  return baseAPI.post(
    `/organization/${organizationId}/invitations`,
    invitation,
  );
}

export function updateInvitationRequest(
  organizationId: Organization["id"],
  invitationId: Invitation["id"],
  invitation: Partial<
    Pick<
      Invitation,
      "firstname" | "lastname" | "email" | "membershipType" | "disabledDate"
    >
  >,
) {
  return baseAPI.put(
    `/organization/${organizationId}/invitations/${invitationId}`,
    invitation,
  );
}

export function resendInvitationMailRequest(
  organizationId: Organization["id"],
  invitationId: Invitation["id"],
) {
  return baseAPI.post(
    `/organization/${organizationId}/invitations/${invitationId}/resend`,
  );
}

export function getInvitationRequest(invitationUid: Invitation["uid"]) {
  return baseAPI
    .get(`/organization/invitations/${invitationUid}`)
    .then(({ data }) => data);
}

export function buyCapsulesCreditsRequest(
  organizationId: Organization["id"],
  body: { estimatedPrice: number; creditsCount: number },
) {
  return baseAPI.post(`/organization/${organizationId}/buy-credits`, body);
}
