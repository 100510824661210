import { ReactNode, useMemo, useState } from "react";
import { Link, useLoaderData, useRouteLoaderData } from "react-router-dom";
import { Capsule } from "../../../services/capsule/capsuleModel";
import { loggerBuilder } from "../../../services/logger";
import { toastsWithIntl } from "../../../services/toastService";
import { capsuleService } from "../../../services/capsule/capsuleService";
import { useConfirmationWithIntl } from "../../../components/ConfirmationDialog";
import { ReturnButton } from "../../../components/ReturnButton";
import SubmitButton from "../../../components/SubmitButton";
import { cx } from "../../../react-helpers/css";
import SuspenseVideoPlayer from "../../../components/SuspenseVideoPlayer";
import { downloadURL } from "../../../react-helpers/url";
import { QRCodeCanvas } from "qrcode.react";
import IconDownload from "../../../assets/icons/icon-download-blue.svg";
import IconEmbed from "../../../assets/icons/icon-embed.svg";
import IconEmail from "../../../assets/icons/icon-email-blue.svg";
import IconLinkedin from "../../../assets/icons/icon-linkedin.svg";
import IconCopy from "../../../assets/icons/icon-copy-blue.svg";
import IconImage from "../../../assets/icons/icon-image-blue.svg";
import qrCodeBrand from "../../../assets/logo-2max.png";
import { Organization } from "../../../services/organization/organizationModel";
import CapsuleThumbnailDialog from "./CapsuleThumnailDialog";
import CapsuleEditDialog from "./CapsuleEditDialog";
import CapsuleEmbedDialog from "./CapsuleEmbedDialog";
import IconEdit from "../../../assets/icons/icon-edit-blue.svg";
import { format } from "date-fns";
import { useTranslation } from "react-i18next";
import useReload from "../../../hooks/useReload";

const logger = loggerBuilder("capsule-page");

const { toastSuccess } = toastsWithIntl(["organization"]);

const CapsulePage = (): ReactNode => {
  const { t } = useTranslation();
  const { organization } = useRouteLoaderData("organization") as {
    organization: Organization;
  };
  const { capsule, video } = useLoaderData() as {
    capsule: Capsule;
    video: Promise<Blob> | null;
  };
  const { enableCapsuleById, disableCapsuleById } = capsuleService();
  const { confirm } = useConfirmationWithIntl(["organization"]);
  const reload = useReload();

  const sharedCapsuleUrl = useMemo(
    () =>
      `${window.location.origin}/employeur/${organization.slug}/${capsule.id}`,
    [capsule.id, organization.slug],
  );

  const linkedInShareURL = `https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(sharedCapsuleUrl)}`;

  const [showThumbnailDialog, setShowThumbnailDialog] = useState(false);
  const [showEditDialog, setShowEditDialog] = useState(false);
  const [showEmbedDialog, setShowEmbedDialog] = useState(false);

  const hasExpired = useMemo(
    () => new Date() > new Date(capsule.expirationDate),
    [capsule.expirationDate],
  );

  return (
    <div className="layout_content --bg--organization">
      <div className="page-content container --wrap--m ">
        <div>
          <ReturnButton forcedUrl="/organization/capsules">
            {t("organization:capsule.RETURN_TO_MY_ADS")}
          </ReturnButton>
        </div>
        <div className="page_head lblock">
          <div className="lrow --nogap--v --bottom">
            <div>
              <h1 className="page_title">{capsule.name}</h1>
            </div>
            <div>
              <div className="lrow --gap--xxs">
                {!hasExpired && (
                  <SubmitButton
                    className={cx([
                      "btn--icon --simple",
                      capsule.disabledDate ? "--unlock" : "--lock",
                    ])}
                    onClick={() =>
                      capsule.disabledDate
                        ? enableCapsuleById(capsule.id).then(() => reload())
                        : confirm(
                            "organization:disable-capsule.CONFIRM",
                            () =>
                              disableCapsuleById(capsule.id).then(() =>
                                reload(),
                              ),
                            true,
                          )
                    }
                    title={t("organization:capsule.ACTIVATION_TOGGLE_TIP", {
                      action: capsule.disabledDate
                        ? t("organization:capsule.ACTIVATE")
                        : t("organization:capsule.DEACTIVATE"),
                    })}
                  />
                )}
                <button
                  className="btn--icon --simple --edit"
                  onClick={() => setShowEditDialog(true)}
                  title={t("organization:capsule.EDIT_AD_TIP")}
                />
              </div>
            </div>
          </div>

          <div className="cblock lrow --gap--s --center--v">
            {capsule.disabledDate && (
              <div className="chip">
                {t("organization:capsule.AD_DISABLED")}
              </div>
            )}
            {hasExpired && (
              <div className="chip">{t("organization:capsule.AD_EXPIRED")}</div>
            )}
            {!hasExpired && !capsule.disabledDate && (
              <div className="chip --2">
                {t("organization:capsule.ACTIVE_UNTIL", {
                  date: format(new Date(capsule.expirationDate), "dd/MM/yyyy"),
                })}
              </div>
            )}
          </div>
        </div>

        <div className="lrow --gap--l lblock--l">
          <div className="profile_video-col">
            {video && (
              <div>
                <div className="video-container">
                  <SuspenseVideoPlayer
                    videoPromise={video}
                    thumbnail={capsule.thumbnail}
                  />
                </div>
                {!hasExpired && (
                  <div className="cblock ui-row --right">
                    <Link className="btn--white --btn--s__m" to={"record"}>
                      <img src={IconEdit} alt="update video" className="icon" />
                      {t("organization:capsule.EDIT_VIDEO")}
                    </Link>
                    <button
                      className="btn--white --btn--s__m"
                      onClick={() => setShowThumbnailDialog(true)}
                    >
                      <img src={IconImage} alt="vignette" className="icon" />
                      {t("organization:capsule.EDIT_THUMBNAIL")}
                    </button>
                  </div>
                )}
              </div>
            )}

            {!capsule.disabledDate && !video && (
              <Link to="record" className="video-placeholder">
                <canvas width="16" height="9" />
                <div>
                  <button className="btn--2">
                    {t("organization:capsule.RECORD_VIDEO")}
                  </button>
                </div>
              </Link>
            )}
          </div>

          {!!video && !capsule.disabledDate && (
            <div className="profile_sidebar">
              <div className="share_buttons">
                <button
                  className="btn--share --light"
                  onClick={() => {
                    void navigator.clipboard
                      .writeText(sharedCapsuleUrl)
                      .then(() => {
                        toastSuccess("organization:share.LINK_COPIED");
                      });
                  }}
                  title={t("organization:share.tips.SHARE_LINK_TIP")}
                >
                  <img
                    src={IconCopy}
                    alt="copy link to clipboard"
                    className="icon"
                  />
                  {t("organization:share.COPY_LINK")}
                </button>
                <button
                  className="btn--share --light"
                  onClick={() => setShowEmbedDialog(true)}
                  title={t("organization:share.tips.EMBED_CAPSULE_TIP")}
                >
                  <img
                    src={IconEmbed}
                    alt="copy link to clipboard"
                    className="icon"
                  />
                  {t("organization:share.EMBED_CAPSULE")}
                </button>
                <a
                  className="btn--share --linkedin"
                  href={linkedInShareURL}
                  target="_blank"
                  rel="noreferrer"
                  title={t("organization:share.tips.SHARE_LINKEDIN_TIP")}
                >
                  <img src={IconLinkedin} alt="Linkedin" className="icon" />
                  {t("organization:share.SHARE_ON_LINKEDIN")}
                </a>
                <a
                  className="btn--share --light"
                  href={`mailto:?body=${encodeURIComponent(sharedCapsuleUrl)}`}
                  target="_blank"
                  rel="noreferrer"
                  title={t("organization:share.tips.SHARE_EMAIL_TIP")}
                >
                  <img src={IconEmail} alt="Email" className="icon" />
                  {t("organization:share.SHARE_BY_EMAIL")}
                </a>
                <button
                  className="btn--share --light"
                  onClick={() => {
                    const qrRef = document.getElementById(
                      "qrcode",
                    ) as HTMLCanvasElement | null;
                    if (qrRef) {
                      downloadURL(qrRef.toDataURL("image/png"), "qrcode.png");
                    } else {
                      logger.warn("qr code not found");
                    }
                  }}
                  title={t("organization:share.tips.DOWNLOAD_QRCODE_TIP")}
                >
                  <img src={IconDownload} alt="download" className="icon" />
                  {t("organization:share.DOWNLOAD_QRCODE")}
                </button>
              </div>

              <QRCodeCanvas
                id={"qrcode"}
                includeMargin
                value={sharedCapsuleUrl}
                size={150}
                level="H"
                imageSettings={{
                  src: qrCodeBrand,
                  height: 50,
                  width: 50,
                  excavate: true,
                }}
                fgColor="#0c0557"
              />

              {/* REVIEW: @cedric and do we display their mails? */}
              {/* <div>
                  <h3>Les 10 dernières consultations</h3>
                  {lastsUsersVisiting.map((consultation, index) => (
                    <div key={index}>
                      <p>
                        {format(new Date(consultation.consultationDate), "P")}-{" "}
                        {consultation.user.email}
                      </p>
                    </div>
                  ))}
                </div> */}
            </div>
          )}
        </div>
      </div>
      {showEditDialog && (
        <CapsuleEditDialog
          capsule={capsule}
          onClose={() => setShowEditDialog(false)}
        />
      )}
      {video && showThumbnailDialog && (
        <CapsuleThumbnailDialog
          capsule={capsule}
          video={video}
          onClose={() => setShowThumbnailDialog(false)}
        />
      )}
      {showEmbedDialog && (
        <CapsuleEmbedDialog
          sharedCapsuleUrl={sharedCapsuleUrl}
          onClose={() => setShowEmbedDialog(false)}
        />
      )}
    </div>
  );
};

export default CapsulePage;
