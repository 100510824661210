/*
Context:
  getUser,
  checkUserValidity,
  updateUser,
  et toutes méthodes de manipulation d'un user existant en DB
*/

import { User } from "./userModel";
import {
  getAllUsersRequest,
  getUserRequest,
  updateUserRequest,
} from "./userApi";
import { toastsWithIntl } from "../toastService";
import { authService } from "../auth/authService";
import * as Sentry from "@sentry/react";

interface UserService {
  getLoggedUser: () => Promise<User>;
  getAllUsers: () => Promise<User[]>;
  updateUserById: (
    userId: User["id"],
    user: Partial<
      Pick<
        User,
        | "firstname"
        | "lastname"
        | "organizationMembershipStatus"
        | "adminOrga"
        | "phoneNumber"
        | "positionInOrganization"
      >
    >,
  ) => Promise<User>;
  updateUserPassword: (oldPassword: string, password: string) => Promise<User>;
}
export const userService = () => {
  const { getLoggedUserId } = authService();
  const { toastSuccess, toastError } = toastsWithIntl(["accounts"]);

  const getLoggedUser: UserService["getLoggedUser"] = () => {
    const loggedUserId = getLoggedUserId();
    if (!loggedUserId) return Promise.reject("not logged in");
    return getUserRequest(loggedUserId).then((u) => {
      Sentry.setUser({
        id: u.id,
        username: `${u.firstname} ${u.lastname}`,
        email: u.email,
      });

      return u;
    });
  };

  const updateUserById: UserService["updateUserById"] = (userId, user) => {
    return updateUserRequest(userId, user).then(
      (updateUser) => {
        toastSuccess("accounts:update.SUCCESS");
        return Promise.resolve(updateUser);
      },
      (err) => {
        toastError("accounts:update.ERROR");
        return Promise.reject(err);
      },
    );
  };

  return {
    getLoggedUser,
    getAllUsers: getAllUsersRequest,
    updateUserById,
  };
};
