import { toastsWithIntl } from "../toastService";
import {
  archiveCapsuleRequest,
  deleteCapsuleRequest,
  notifyCapsuleUploadErrorRequest,
  unarchiveCapsuleRequest,
  updateCapsuleRequest,
} from "./capsuleApi";
import { Capsule } from "./capsuleModel";

interface CapsuleService {
  updateCapsuleById: (
    capsuleId: Capsule["id"],
    capsule: Partial<Capsule>,
  ) => Promise<Capsule>;
  deleteCapsuleById: (capsuleId: Capsule["id"]) => Promise<void>;
  disableCapsuleById: (capsuleId: Capsule["id"]) => Promise<void>;
  enableCapsuleById: (capsuleId: Capsule["id"]) => Promise<void>;
  archiveCapsule: (capsuleId: Capsule["id"]) => Promise<void>;
  unarchiveCapsule: (capsuleId: Capsule["id"]) => Promise<void>;
  notifyCapsuleUploadError: (capsuleId: Capsule["id"]) => Promise<void>;
}
export const capsuleService = (): CapsuleService => {
  const { toastSuccess, toastError } = toastsWithIntl(["organization"]);

  const updateCapsuleById: CapsuleService["updateCapsuleById"] = (
    capsuleId,
    capsule,
  ) => {
    return updateCapsuleRequest(capsuleId, capsule).then(
      (updatedCapsule) => {
        toastSuccess("organization:update-capsule.SUCCESS");
        return Promise.resolve(updatedCapsule);
      },
      (err) => {
        toastError("organization:update-capsule.ERROR");
        return Promise.reject(err);
      },
    );
  };

  const deleteCapsuleById: CapsuleService["deleteCapsuleById"] = (
    capsuleId,
  ) => {
    return deleteCapsuleRequest(capsuleId).then(
      () => {
        toastSuccess("organization:delete-capsule.SUCCESS");
        return Promise.resolve();
      },
      (err) => {
        toastError("organization:delete-capsule.ERROR");
        return Promise.reject(err);
      },
    );
  };

  const disableCapsuleById: CapsuleService["disableCapsuleById"] = (
    capsuleId,
  ) => {
    return updateCapsuleRequest(capsuleId, {
      disabledDate: new Date().toISOString(),
    }).then(
      () => {
        toastSuccess("organization:disable-capsule.SUCCESS");
        return Promise.resolve();
      },
      (err) => {
        toastError("organization:disable-capsule.ERROR");
        return Promise.reject(err);
      },
    );
  };
  const enableCapsuleById: CapsuleService["enableCapsuleById"] = (
    capsuleId,
  ) => {
    return updateCapsuleRequest(capsuleId, {
      disabledDate: null,
    }).then(
      () => {
        toastSuccess("organization:enable-capsule.SUCCESS");
        return Promise.resolve();
      },
      (err) => {
        toastError("organization:enable-capsule.ERROR");
        return Promise.reject(err);
      },
    );
  };

  const archiveCapsule: CapsuleService["archiveCapsule"] = (capsuleId) => {
    return archiveCapsuleRequest(capsuleId).then(
      () => {
        toastSuccess("organization:capsule.archive.SUCCESS");
        return Promise.resolve();
      },
      (err) => {
        toastError("organization:capsule.archive.ERROR");
        return Promise.reject(err);
      },
    );
  };

  const unarchiveCapsule: CapsuleService["unarchiveCapsule"] = (capsuleId) => {
    return unarchiveCapsuleRequest(capsuleId).then(
      () => {
        toastSuccess("organization:capsule.unarchive.SUCCESS");
        return Promise.resolve();
      },
      (err) => {
        toastError("organization:capsule.unarchive.ERROR");
        return Promise.reject(err);
      },
    );
  };

  const notifyCapsuleUploadError: CapsuleService["notifyCapsuleUploadError"] = (
    capsuleId,
  ) => {
    return notifyCapsuleUploadErrorRequest(capsuleId).catch(() =>
      Promise.resolve(),
    );
  };

  return {
    updateCapsuleById,
    deleteCapsuleById,
    disableCapsuleById,
    enableCapsuleById,
    archiveCapsule,
    unarchiveCapsule,
    notifyCapsuleUploadError,
  };
};
