import { boolean, object, string } from "yup";
import SxForm from "../../../forms/SxForm";
import SxField from "../../../forms/fields/SxField";
import SubmitButton from "../../../components/SubmitButton";
import { useMemo, useState } from "react";
import { useSearchParams } from "react-router-dom";
import {
  COMPANY_POSITIONS,
  EDUCATION_POSITIONS,
  NewUserData,
  POSITIONS,
} from "../../../services/user/userModel";
import {
  transformEmptyToUndefined,
  transformEmptyToNull,
} from "../../../react-helpers/yup";
import {
  Plan,
  RecurringInterval,
} from "../../../services/subscription/subscriptionModel";
import ChoosePlan from "../../../components/ChoosePlan";
import DisplayPlan from "../../../components/DisplayPlan";
import { cx } from "../../../react-helpers/css";
import DisplayPrice from "../../../components/DisplayPrice";
import SxCheckbox from "../../../forms/fields/SxCheckbox";
import { NewOrganizationData } from "../../../services/organization/organizationModel";
import { useTranslation } from "react-i18next";
import { useCountries } from "../../../services/profile/profileService";

const OrganizationRegisterForm = ({
  profileShareUid,
  plans,
  onRegister,
}: {
  profileShareUid?: string;
  plans: Plan[];
  onRegister(
    registerData: {
      user: NewUserData;
      organization: NewOrganizationData;
      profileShareUid?: string;
    },
    plan: Plan,
    recurrency: RecurringInterval,
    promoCode: string | null,
  ): Promise<any>;
}) => {
  const { t } = useTranslation(["auth", "subscription", "validation"]);
  const [searchParams] = useSearchParams();
  const countries = useCountries();

  const [choosenPlan, setChoosenPlan] = useState<Plan | null>(null);
  const [choosenRecurrency, setChoosenRecurrency] =
    useState<RecurringInterval>("annual");

  const initialValues = useMemo(() => {
    return {
      firstName: "",
      lastName: "",
      email: searchParams.get("email") ?? "",
      tel: "",
      password: "",
      legalName: "",
      addressLine1: "",
      addressLine2: "",
      postalCode: "",
      city: "",
      country: "FR",
      phoneNumber: "",
      positionInOrganization: "",
      promoCode: null,
    };
  }, [searchParams]);

  const validationSchema = useMemo(() => {
    return object({
      firstName: string().label(t("auth:register.labels.FIRSTNAME")).required(),
      lastName: string().label(t("auth:register.labels.LASTNAME")).required(),
      email: string().email().label(t("auth:register.labels.EMAIL")).required(),
      password: string()
        .password()
        .label(t("auth:register.labels.PASSWORD"))
        .required(),
      legalName: string()
        .label(t("auth:register.labels.LEGAL_NAME"))
        .required(),
      positionInOrganization: string()
        .label(t("auth:register.labels.POSITION"))
        .transform(transformEmptyToUndefined)
        .required()
        .oneOf(POSITIONS.map(([k]) => k)),
      phoneNumber: string().label(t("auth:register.labels.PHONE")),
      promoCode: string()
        .label(t("auth:register.labels.PROMO_CODE"))
        .transform(transformEmptyToNull)
        .nullable(),
      cguAccepted: boolean()
        .transform((v) => !!v)
        .oneOf([true]),
      addressLine1: string()
        .label(t("auth:register.labels.ADDRESS_LINE1"))
        .required(),
      addressLine2: string().label(t("auth:register.labels.ADDRESS_LINE2")),
      postalCode: string()
        .label(t("auth:register.labels.POSTAL_CODE"))
        .required(),
      city: string().label(t("auth:register.labels.CITY")).required(),
      country: string()
        .label(t("auth:register.labels.COUNTRY"))

        .oneOf(
          countries.map((c) => c[0]),
          t("validation:register.INVALID_COUNTRY"),
        )
        .required(),
    });
  }, [t, countries]);

  if (!choosenPlan)
    return (
      <div>
        <div className="lblock title--40 --txt--center">
          {t("auth:register.PROFESSIONAL_ACCOUNT")}
        </div>

        <div className="lrow --center--h">
          <div className="info --success">
            {t("subscription:FREE_MONTH_WITHOUT_COMMITMENT")}
          </div>
        </div>

        <ChoosePlan
          plans={plans}
          onChoice={(plan, recurrency) => {
            setChoosenPlan(plan);
            setChoosenRecurrency(recurrency);
          }}
        />
      </div>
    );

  return (
    <div>
      <div className="title--40 --txt--center lblock">
        {t("auth:register.CREATE_YOUR_PROFESSIONAL_ACCOUNT")}
      </div>

      <div className="lblock--l">
        <div className="grid-1-3">
          <div>
            <div className="cblock lrow --center--h">
              <div>{t("subscription:MENSUAL")}</div>
              <div>
                <button
                  type="button"
                  className={cx([
                    "btn--toggle",
                    choosenRecurrency === "monthly" && "--opt1",
                    choosenRecurrency !== "monthly" && "--opt2",
                  ])}
                  onClick={() =>
                    setChoosenRecurrency((prev) =>
                      prev === "monthly" ? "annual" : "monthly",
                    )
                  }
                />
              </div>
              <div>{t("subscription:ANNUAL")}</div>
            </div>

            <div className="cblock">
              <DisplayPlan
                plan={choosenPlan}
                recurrency={choosenRecurrency}
                cta={
                  <div className="cblock --txt--center">
                    <button
                      onClick={() => setChoosenPlan(null)}
                      className="link"
                    >
                      {t("auth:register.CHANGE_PLAN")}
                    </button>
                  </div>
                }
              />
            </div>
          </div>

          <SxForm
            initialValues={initialValues}
            onSubmit={async (values) => {
              return onRegister(
                {
                  user: {
                    firstname: values.firstName,
                    lastname: values.lastName,
                    email: values.email,
                    password: values.password,
                    phoneNumber:
                      values.phoneNumber && values.phoneNumber.length > 0
                        ? values.phoneNumber
                        : null,
                    positionInOrganization: values.positionInOrganization,
                    searchedPost: null,
                  },
                  organization: {
                    legalName: values.legalName,
                    email: values.email,
                    phoneNumber: values.phoneNumber,
                    addressLine1: values.addressLine1,
                    addressLine2: values.addressLine2,
                    postalCode: values.postalCode,
                    city: values.city,
                    country: values.country,
                  },
                  profileShareUid,
                },
                choosenPlan,
                choosenRecurrency,
                values.promoCode,
              );
            }}
            validationSchema={validationSchema}
            enableReinitialize
          >
            <div className={"info --success"}>
              {t("register.PROFESSIONAL_TIP")}
            </div>
            <br />
            <div className="cblock">
              <div className="form-block">
                <h2 className="title--40">{t("auth:register.USER_ACCOUNT")}</h2>
              </div>
              <div className="grid-2 form-block">
                <SxField
                  name="firstName"
                  placeholder={t("register.placeholders.FIRSTNAME")}
                  noBlock
                />
                <SxField
                  name="lastName"
                  placeholder={t("register.placeholders.LASTNAME")}
                  noBlock
                />
              </div>
              <SxField
                name="email"
                placeholder={t("register.placeholders.EMAIL")}
              />
              <SxField
                name="phoneNumber"
                placeholder={t("register.placeholders.PHONE")}
              />
              <SxField
                name="password"
                placeholder={t("register.placeholders.PASSWORD")}
              />
            </div>

            <div className="cblock">
              <div className="form-block">
                <h2 className="title--40">{t("auth:register.ORGANIZATION")}</h2>
              </div>
              <div className="grid-2 form-block">
                <SxField
                  name="legalName"
                  placeholder={t("register.placeholders.LEGAL_NAME")}
                  noBlock
                />
                <SxField name="positionInOrganization" as="select" noBlock>
                  <option value="">
                    {t("register.placeholders.POSITION")}
                  </option>
                  {!choosenPlan &&
                    POSITIONS.map(([k, v]) => (
                      <option key={k} value={k}>
                        {v}
                      </option>
                    ))}
                  {choosenPlan &&
                    choosenPlan.type === "education" &&
                    EDUCATION_POSITIONS.map(([k, v]) => (
                      <option key={k} value={k}>
                        {v}
                      </option>
                    ))}
                  {choosenPlan &&
                    choosenPlan.type === "company" &&
                    COMPANY_POSITIONS.map(([k, v]) => (
                      <option key={k} value={k}>
                        {v}
                      </option>
                    ))}
                </SxField>
              </div>
              <SxField name="addressLine1" />
              <SxField name="addressLine2" />
              <div className="grid-2 form-block">
                <SxField name="postalCode" noBlock />
                <SxField name="city" noBlock />
              </div>
              <SxField name={"country"} as={"select"}>
                {countries.map(([code, name]) => (
                  <option key={code} value={code}>
                    {name}
                  </option>
                ))}
              </SxField>
            </div>

            {choosenPlan && (
              <div className="cblock form-block">
                <div className="grid-2 cblock">
                  <div>
                    <label className="field-label">
                      {t("register.labels.FORMULA")}
                    </label>
                    <select
                      className="select"
                      value={choosenRecurrency}
                      onChange={(ev) => {
                        setChoosenRecurrency(ev.target.value as any);
                      }}
                    >
                      <option value="annual">
                        {choosenPlan.label} (
                        <DisplayPrice
                          textOnly
                          amount={choosenPlan.stripeAnnualPrice.amount}
                          currency={choosenPlan.stripeAnnualPrice.currency}
                          taxFree
                          addon={` ${t("subscription:PER_YEAR")}`}
                        />
                        )
                      </option>
                      <option value="monthly">
                        {choosenPlan.label} (
                        <DisplayPrice
                          textOnly
                          amount={choosenPlan.stripeMonthlyPrice.amount}
                          currency={choosenPlan.stripeMonthlyPrice.currency}
                          taxFree
                          addon={` ${t("subscription:PER_MONTH")}`}
                        />
                        )
                      </option>
                    </select>
                  </div>

                  <SxField name="promoCode" noBlock />
                </div>

                <div className="cblock">
                  <SxCheckbox
                    name="cguAccepted"
                    label={
                      <span
                        dangerouslySetInnerHTML={{
                          __html: t("auth:register.ACCEPT_CGU"),
                        }}
                      />
                    }
                  />
                </div>
              </div>
            )}

            <div className="lblock--l --txt--center">
              <SubmitButton type="submit" className="btn--2">
                {t("register.SUBSCRIBE")}
              </SubmitButton>
            </div>
          </SxForm>
        </div>
      </div>
    </div>
  );
};

export default OrganizationRegisterForm;
