import { object, string } from "yup";
import SxForm from "../../../forms/SxForm";
import SxField from "../../../forms/fields/SxField";
import SubmitButton from "../../../components/SubmitButton";
import { authService } from "../../../services/auth/authService";
import { useMemo } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { POSTS } from "../../../services/user/userModel";
import { transformEmptyToUndefined } from "../../../react-helpers/yup";
import { t } from "i18next";
import { useCountries } from "../../../services/profile/profileService";

const CandidateRegisterForm = () => {
  const { register } = authService();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const countries = useCountries();

  const initialValues = useMemo(() => {
    return {
      firstName: "",
      lastName: "",
      email: searchParams.get("email") ?? "",
      password: "",
      searchedPost: "",
      country: "FR",
    };
  }, [searchParams]);

  const validationSchema = useMemo(() => {
    return object({
      firstName: string().label(t("auth:register.labels.FIRSTNAME")).required(),
      lastName: string().label(t("auth:register.labels.LASTNAME")).required(),
      email: string().email().label(t("auth:register.labels.EMAIL")).required(),
      password: string()
        .password()
        .label(t("auth:register.labels.PASSWORD"))
        .required(),
      searchedPost: string()
        .label(t("auth:register.labels.SEARCHED_POST"))
        .transform(transformEmptyToUndefined)
        .required()
        .oneOf(POSTS.map(([k]) => k)),
      country: string()
        .label(t("organization:capsule-form.labels.COUNTRY"))
        .oneOf(
          countries.map((c) => c[0]),
          t("validation:capsule.INVALID_COUNTRY"),
        )
        .required(),
    });
  }, [countries]);

  return (
    <SxForm
      initialValues={initialValues}
      onSubmit={async (values) => {
        return register({
          firstname: values.firstName,
          lastname: values.lastName,
          email: values.email,
          password: values.password,
          phoneNumber: null,
          positionInOrganization: null,
          searchedPost: values.searchedPost,
        }).then(
          () => {
            navigate("/login");
          },
          () => {
            // do nothing
          },
        );
      }}
      validationSchema={validationSchema}
      enableReinitialize
    >
      <div className={"info --success"}>{t("register.CANDIDATE_TIP")}</div>
      <br />
      <SxField
        name="firstName"
        placeholder={t("register.placeholders.FIRSTNAME")}
      />
      <SxField
        name="lastName"
        placeholder={t("register.placeholders.LASTNAME")}
      />
      <SxField name="email" placeholder={t("register.placeholders.EMAIL")} />
      <SxField
        name="password"
        placeholder={t("register.placeholders.PASSWORD")}
      />
      <SxField name="searchedPost" as="select">
        <option value="">{t("register.placeholders.SEARCHED_POST")}</option>
        {POSTS.map(([k, v]) => (
          <option key={k} value={k}>
            {v}
          </option>
        ))}
      </SxField>
      <SxField name={"country"} as={"select"}>
        {countries.map(([code, name]) => (
          <option key={code} value={code}>
            {name}
          </option>
        ))}
      </SxField>

      <div className="lblock--l --txt--center">
        <SubmitButton type="submit" className="btn--1">
          {t("register.SIGN_UP")}
        </SubmitButton>
      </div>
    </SxForm>
  );
};

export default CandidateRegisterForm;
