import { Organization } from "../organization/organizationModel";
import { toastsWithIntl } from "../toastService";
import {
  getPlansRequest,
  cancelSubscriptionRequest,
  subscribeToPlanRequest,
} from "./subscriptionApi";
import { Plan, Subscription } from "./subscriptionModel";

interface SubscriptionService {
  getPlans(): Promise<Plan[]>;
  cancelSubscription(stripeSubscriptionId: string): Promise<void>;
  subscribeToPlan(
    organizationId: Organization["id"],
    planId: Plan["id"],
    recurrency: Subscription["recurringInterval"],
    promoCode: string,
  ): Promise<void>;
}

export function subscriptionService(): SubscriptionService {
  const { toastSuccess, toastError } = toastsWithIntl(["subscription"]);

  const getPlans: SubscriptionService["getPlans"] = () => {
    return getPlansRequest();
  };

  const cancelSubscription: SubscriptionService["cancelSubscription"] = (
    stripeSubscriptionId,
  ) => {
    return cancelSubscriptionRequest(stripeSubscriptionId);
  };

  const subscribeToPlan: SubscriptionService["subscribeToPlan"] = (
    organization,
    planId,
    recurrency,
    promoCode,
  ) => {
    return subscribeToPlanRequest(
      organization,
      planId,
      recurrency,
      promoCode,
    ).then(
      () => {
        toastSuccess("subscription:subscribe.SUCCESS");
      },
      (err) => {
        toastError("subscription:subscribe.ERROR");
        return Promise.reject(err);
      },
    );
  };

  return { getPlans, cancelSubscription, subscribeToPlan };
}

function generateLogPrice(oneUnitPrice: number, minUnitPrice: number) {
  const C = -0.2; // Constant found using R1 (also might be found using mathematical solving)
  const B =
    (100 - oneUnitPrice - 2 * minUnitPrice) / Math.log10((3 + C) / (1 + C));
  const A = oneUnitPrice - minUnitPrice - B * Math.log10(1 + C);

  return function (creditsCount: number) {
    return Math.floor(
      minUnitPrice * creditsCount + A + B * Math.log10(creditsCount + C),
    );
  };
}
export const calculateCreditsPrice = generateLogPrice(50, 10);
