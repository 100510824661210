import { ErrorMessage } from "formik";
import { deepEqual } from "../react-helpers/any";
import { useYupFields } from "./validation/useYupField";

const ValidationErrors = () => {
  const getYupFields = useYupFields();

  return (
    <ErrorMessage
      name="errors"
      className={"field-errors"}
      render={(err: any) => {
        const fields = getYupFields(Object.keys(err));

        return (
          !deepEqual(err, {}) && (
            <div>
              {Object.entries(err).map(([field, errors]) => (
                <div key={field}>
                  {fields[field]?.spec.label}
                  {(errors as any[]).map((err) => (
                    <div key={err.type}>{err.message}</div>
                  ))}
                </div>
              ))}
            </div>
          )
        );
      }}
    />
  );
};

export default ValidationErrors;
