import { Link, useLoaderData } from "react-router-dom";
import Logo from "../../../../assets/logo-2MAX-large.png";
import { Invitation } from "../../../../services/organization/organizationModel";
import { useTranslation } from "react-i18next";
import CommonFooter from "../../../../components/CommonFooter";
import RegisterWithInvitationForm from "./RegisterWithInvitationForm";

const RegisterWithInvitationPage = () => {
  const { invitation } = useLoaderData() as {
    invitation: Invitation;
  };
  const { t } = useTranslation(["auth", "validation"]);
  if (invitation.userId)
    return (
      <div className="auth-screen">
        <img src={Logo} alt="2 Minutes Max" className="logo" />
        <div className="auth-card">
          <div className="side-image"></div>

          <div className="card_body">
            <div className="--txt--center">
              <div className="cblock info --compact">
                {t("auth:join-org.ALREADY_USED")}
              </div>
              <div className="cblock">
                <Link className="link" to="/login">
                  {t("auth:register.LOGIN")}
                </Link>
              </div>
            </div>
          </div>
        </div>
        <CommonFooter />
      </div>
    );

  return (
    <div className="auth-screen">
      <img src={Logo} alt="2 Minutes Max" className="logo" />
      <div className="auth-card --wide">
        <div className="side-image"></div>

        <div className="card_body">
          <div className="lblock --txt--center">
            {invitation.membershipType === "GUEST" ? (
              <>
                <div>
                  {t("auth:join-org.INVITED_BY", {
                    replace: {
                      organization: invitation.organization?.legalName,
                    },
                  })}
                </div>
                <h1 className="title--60">
                  {t("auth:register.CREATE_YOUR_PROFESSIONAL_ACCOUNT")}
                </h1>
                <div>{t("auth:join-org.TO_BENEFIT_FROM_DIRECT_ACCESS")}</div>
              </>
            ) : (
              <h1 className="title--60">
                {t("auth:join-org.JOIN_ORG", {
                  replace: {
                    organization: invitation.organization?.legalName,
                  },
                })}
              </h1>
            )}
          </div>

          <div className="lblock">
            <RegisterWithInvitationForm invitation={invitation} />
          </div>
        </div>
      </div>
      <CommonFooter />
    </div>
  );
};

export default RegisterWithInvitationPage;
