import { useLoaderData } from "react-router-dom";
import { Dispatch, SetStateAction, useCallback, useState } from "react";
import ReviewComponent from "./ReviewComponent";
import { Profile } from "../../services/profile/profileModel";
import { ProvideRecorder } from "../../services/recorderService";
import RecorderComponent from "./RecorderComponent";
import { Capsule } from "../../services/capsule/capsuleModel";
import { updateProfileRequest } from "../../services/profile/profileApi";
import { updateCapsuleRequest } from "../../services/capsule/capsuleApi";
import { useDebouncedCallback } from "../../react-helpers/function";

export const MAX_RECORD_LENGTH_SEC = 60 * 2;
export const PRE_TIMER_DURATION_SEC = 3;
export const MAX_UNCOMPRESSED_SIZE_MB = 40;

export interface RecorderConfig {
  interlocutor: string | null;
  notes: string;
  showNotes: boolean;
  initialized: boolean;
}

export type RecordType = "profile" | "capsule";

const RecordPage = ({ type }: { type: RecordType }) => {
  const { profile, capsule } = useLoaderData() as {
    profile?: Profile;
    capsule?: Capsule;
  };
  const [showReview, setShowReview] = useState(false);
  const [videos, setVideos] = useState<[string, Blob][]>([]);
  const [config, rawSetConfig] = useState<RecorderConfig>({
    interlocutor: null,
    notes: profile?.note ?? capsule?.note ?? "",
    showNotes: false,
    initialized: false,
  });

  const updateNotes = useDebouncedCallback(
    (note?: string) => {
      // TODO: Debounce without notification
      if (profile) {
        return updateProfileRequest(profile.id, {
          note,
        });
      }
      if (capsule) {
        return updateCapsuleRequest(capsule.id, {
          note,
        });
      }
    },
    [capsule, profile],
    10 * 1000,
    20 * 1000,
  );

  const setConfig: Dispatch<SetStateAction<RecorderConfig>> = useCallback(
    (config) => {
      if (typeof config === "function") {
        rawSetConfig((prev) => {
          const newConfig = config(prev);

          void updateNotes(newConfig.notes);

          return newConfig;
        });
      } else {
        void updateNotes(config.notes);
        rawSetConfig(config);
      }
    },
    [rawSetConfig, updateNotes],
  );

  return (
    <ProvideRecorder
      maxUncompressedSize={MAX_UNCOMPRESSED_SIZE_MB * 1024 * 1024}
    >
      {showReview && (
        <ReviewComponent
          type={type}
          profileId={profile?.id}
          capsuleId={capsule?.id}
          setShowReview={setShowReview}
          videos={videos}
          clearVideos={() => setVideos([])}
        />
      )}
      {!showReview && (
        <RecorderComponent
          type={type}
          profile={profile}
          capsule={capsule}
          setShowReview={setShowReview}
          setVideos={(values) => {
            setVideos(values);
            setShowReview(true);
          }}
          config={config}
          setConfig={setConfig}
        />
      )}
    </ProvideRecorder>
  );
};

export default RecordPage;
