import { Link } from "react-router-dom";
import { useMemo } from "react";
import { cx } from "../../../react-helpers/css";
import { Capsule } from "../../../services/capsule/capsuleModel";
import { useTranslation } from "react-i18next";
import {
  useCountries,
  useLangs,
} from "../../../services/profile/profileService";

const CapsuleCard = ({
  capsule,
  isArchived,
  onArchive,
  onUnarchive,
}: {
  capsule: Capsule;
  isArchived?: boolean;
  onArchive?: () => void;
  onUnarchive?: () => void;
}) => {
  const { t } = useTranslation();
  const countries = useCountries();
  const langs = useLangs();
  const country = useMemo(
    () => countries.filter((c) => c[0] === capsule.country)[0][1],
    [capsule.country, countries],
  );
  const language = useMemo(
    () => langs.filter((c) => c[0] === capsule.lang)[0][1],
    [capsule.lang, langs],
  );
  const hasExpired = useMemo(
    () => new Date() > new Date(capsule.expirationDate),
    [capsule.expirationDate],
  );

  return (
    <Link
      className={cx([
        "profile-card",
        !!capsule.disabledDate && "disabled",
        "--org",
      ])}
      to={
        !capsule.organization?.legalName
          ? `/capsule-share/${capsule.uid}`
          : `../capsules/${capsule.id}`
      }
    >
      {/* TODO : décommenter + onClick confirmation dialog archivage */}
      <div className="card--actions">
        {!!capsule.organizationId &&
          (!isArchived ? (
            <button
              className="btn--archive"
              onClick={(e) => {
                e.preventDefault();
                onArchive && onArchive();
              }}
            />
          ) : (
            <button
              className="btn--unarchive"
              onClick={(e) => {
                e.preventDefault();
                onUnarchive && onUnarchive();
              }}
            />
          ))}
      </div>
      <div className="card_image_container">
        <canvas
          className="card_image_bg"
          width="16"
          height="9"
          style={{
            backgroundImage: capsule.thumbnail
              ? `url(${capsule.thumbnail}`
              : undefined,
          }}
        ></canvas>
        <canvas
          className="card_image"
          style={{
            backgroundImage: capsule.thumbnail
              ? `url(${capsule.thumbnail}`
              : undefined,
          }}
        />
      </div>

      <div className="card_body">
        <p className="profile_name">{capsule.name}</p>
        <div className="profile_flags">
          <p className="chip">{country}</p>
          <p className="chip">{language}</p>
        </div>
      </div>
      {!!capsule.disabledDate && !hasExpired && (
        <div className="disabled-overlay">
          {t("organization:capsule.card.DISABLE")}
        </div>
      )}
      {hasExpired && (
        <div className="disabled-overlay">
          {t("organization:capsule.card.EXPIRED")}
        </div>
      )}
    </Link>
  );
};

export default CapsuleCard;
