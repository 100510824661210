import {
  CardElement,
  Elements,
  IbanElement,
  useElements,
} from "@stripe/react-stripe-js";
import { organizationService } from "../../../services/organization/organizationService";
import { Organization } from "../../../services/organization/organizationModel";
import { toastsWithIntl } from "../../../services/toastService";
import SxForm from "../../../forms/SxForm";
import SubmitButton from "../../../components/SubmitButton";
import {
  ProvideStripeService,
  useStripeService,
} from "../../../services/stripe/stripeService";
import { useLoaderData } from "react-router";
import { prettifySepa } from "../../../react-helpers/stripe";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import { StripeError } from "@stripe/stripe-js";
import { cx } from "../../../react-helpers/css";
import IconStripeCard from "../../../assets/icons/icon-stripe-card.svg";
import IconStripeSepa from "../../../assets/icons/icon-stripe-sepa.svg";

const { addPaymentMethodToOrganization } = organizationService();

const OrganizationPaymentMethodForm = ({
  organization,
  onSubmit,
}: {
  organization: Organization;
  onSubmit(): void;
}) => {
  const { toastSuccess, toastError } = toastsWithIntl(["organization"]);
  const elements = useElements();
  const { stripe } = useStripeService();
  const { t } = useTranslation(["subscription", "organization"]);

  const card = organization?.stripeCustomer?.card;
  const sepa = organization?.stripeCustomer?.sepa;
  const [paymentMethod, setPaymentMethod] = useState<"card" | "iban">(
    sepa ? "iban" : "card",
  );

  const handleSubmit = async () => {
    if (!stripe || !elements) return;

    let stripeError: StripeError | undefined;
    let paymentMethodId: string | undefined;

    if (paymentMethod === "card") {
      const cardElement = elements.getElement(CardElement);
      if (cardElement) {
        const { error, paymentMethod } = await stripe.createPaymentMethod({
          type: "card",
          card: cardElement,
        });

        stripeError = error;
        paymentMethodId = paymentMethod?.id;
      }
    } else if (paymentMethod === "iban") {
      const ibanElement = elements.getElement(IbanElement);
      if (ibanElement) {
        const { error, paymentMethod } = await stripe.createPaymentMethod({
          type: "sepa_debit",
          sepa_debit: ibanElement,
          billing_details: {
            name: `${organization.legalName}`,
            email: organization.email!,
          },
        });

        stripeError = error;
        paymentMethodId = paymentMethod?.id;
      }
    }

    if (!stripeError && paymentMethodId) {
      // On envoie ici le paymentMethod.id
      await addPaymentMethodToOrganization(
        organization.id,
        paymentMethodId,
      ).then(
        () => {
          toastSuccess("organization:register-payment-method.SUCCESS");
          onSubmit();
        },
        () => {
          toastError("organization:register-payment-method.ERROR");
        },
      );
    }
  };

  return (
    <SxForm initialValues={{}} onSubmit={handleSubmit}>
      <div>
        {card && (
          <div className="cblock info">
            <div className="body--40">
              {card.brand.toUpperCase()} XXXX-XXXX-XXXX-{card.last4} [
              {card.exp_month.toString().padStart(2, "0")}/{card.exp_year}]
            </div>
          </div>
        )}
        {sepa && (
          <div className="cblock info">
            <div className="body--40">{prettifySepa(sepa)}</div>
          </div>
        )}
        <div className="lblock payment-method-selector">
          <button
            className={cx([
              "btn--payment-method --card",
              paymentMethod === "card" && "--active",
            ])}
            type="button"
            onClick={() => {
              setPaymentMethod("card");
            }}
          >
            <img src={IconStripeCard} alt="stripe card" className="icon" />
            {t("subscription:PAYMENT_METHOD_CARD")}
          </button>
          <button
            className={cx([
              "btn--payment-method --sepa",
              paymentMethod === "iban" && "--active",
            ])}
            type="button"
            onClick={() => {
              setPaymentMethod("iban");
            }}
          >
            <img src={IconStripeSepa} alt="stripe iban" className="icon" />
            {t("subscription:PAYMENT_METHOD_IBAN")}
          </button>
        </div>

        <div
          className="lblock card-element-wrapper"
          hidden={paymentMethod !== "card"}
        >
          <CardElement
            options={{
              hidePostalCode: true,
            }}
          />
        </div>
        <div
          className="lblock iban-element-wrapper"
          hidden={paymentMethod !== "iban"}
        >
          <IbanElement
            options={{
              supportedCountries: ["SEPA"],
              placeholderCountry: "FR",
            }}
          />
        </div>
      </div>

      <div className="lblock --txt--center">
        <SubmitButton type="submit" className="btn">
          {!!card || !!sepa
            ? t("organization:register-payment-method.UPDATE")
            : t("organization:register-payment-method.REGISTER")}
        </SubmitButton>
      </div>
    </SxForm>
  );
};

const OrganizationRegisterPaymentMethod = () => {
  const { organization } = useLoaderData() as {
    organization: Organization;
  };
  const { t } = useTranslation(["organization"]);

  const { stripe } = useStripeService();
  return (
    <div className="payment-method-card">
      <div className="card_head">
        <div className="card_title">
          {organization?.stripeCustomer?.card ??
          organization?.stripeCustomer?.sepa
            ? t("organization:register-payment-method.UPDATE_TITLE")
            : t("organization:register-payment-method.REGISTER_TITLE")}
        </div>
      </div>

      <div className="card_body">
        <Elements
          stripe={stripe}
          options={{
            appearance: {
              theme: "stripe",
            },
          }}
        >
          <OrganizationPaymentMethodForm
            organization={organization}
            onSubmit={() => {
              window.close();
            }}
          />
        </Elements>
      </div>
    </div>
  );
};

const OrganizationRegisterPaymentMethodPage = () => {
  return (
    <div className="app-layout --h">
      <div className="layout_content --bg--organization">
        <div className="page-content container lrow --center--h">
          <ProvideStripeService>
            <OrganizationRegisterPaymentMethod />
          </ProvideStripeService>
        </div>
      </div>
    </div>
  );
};

export default OrganizationRegisterPaymentMethodPage;
