// NOTE: Required to load stripe without them loading stripe on the whole app event before kloading
// SOURCE: https://github.com/stripe/stripe-js/tree/af12a8e4e860764ac6af77d3eea2cb45496c9446?tab=readme-ov-file#importing-loadstripe-without-side-effects
import { loadStripe } from "@stripe/stripe-js/pure";
import { Stripe } from "@stripe/stripe-js/dist/stripe-js/stripe";
import { STRIPE_PUBLIC_KEY } from "../config";
import {
  PropsWithChildren,
  createContext,
  useContext,
  useEffect,
  useState,
} from "react";

interface StripeService {
  stripe: Stripe | null;
}

const StripeContext = createContext<StripeService | null>(null);

/**
 * HACK: FUCK STRIPE
 * SOURCE: https://github.com/stripe/stripe-js/issues/417
 *
 * TLDR: Stripe does not support CORP headers required for
 * SharedArrayBuffer to work (required by ffmpeg.wasm)
 * they have no roadmap for this and their only solution
 * is to load stripe on an another page than the ones that
 * require CORP headers.
 */

function useProvideStripe(): StripeService {
  const [stripe, setStripe] = useState<Stripe | null>(null);

  useEffect(() => {
    void loadStripe(STRIPE_PUBLIC_KEY).then((s) => {
      setStripe(s);
    });
  }, []);

  return {
    stripe,
  };
}

export function ProvideStripeService({ children }: PropsWithChildren) {
  const stripeProvider = useProvideStripe();

  return (
    <StripeContext.Provider value={stripeProvider}>
      {children}
    </StripeContext.Provider>
  );
}

// eslint-disable-next-line react-refresh/only-export-components
export function useStripeService() {
  return useContext(StripeContext)!;
}
