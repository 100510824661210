import { Invitation, Organization } from "../organization/organizationModel";
import organization from "../../internationalization/organization/en.json";
import candidate from "../../internationalization/candidate/en.json";
import { t } from "i18next";
import { Capsule } from "../capsule/capsuleModel";

export interface User {
  id: number;
  email: string;
  password?: string | null;
  firstname: string;
  lastname: string;
  admin?: boolean | null;
  validEmail?: boolean;
  adminOrga: boolean;
  phoneNumber?: string | null;
  positionInOrganization?: string | null;
  organizationMembershipStatus?: "active" | "disabled";
  capsules?: Capsule[];
  organizationId?: Organization["id"] | null;
  organization?: Organization | null;
  subscriptionStartDate?: string;
  subscriptionEndDate?: string;
  country?: string;
  invitations?: Invitation[];
}

export interface NewUserData {
  email: string;
  firstname: string;
  lastname: string;
  password: string;
  phoneNumber: string | null;
  positionInOrganization: string | null;
  searchedPost: string | null;
}

const COMMON_POSITIONS = Object.entries(organization.positions.common).map(
  ([k]) => [k, t(`organization:positions.common.${k}`)] as [string, string],
);

const EDUCATION_POSITIONS_WITHOUT_COMMON = Object.entries(
  organization.positions.education,
).map(
  ([k]) => [k, t(`organization:positions.education.${k}`)] as [string, string],
);
export const EDUCATION_POSITIONS = [
  ...EDUCATION_POSITIONS_WITHOUT_COMMON,
  ...COMMON_POSITIONS,
];

const COMPANY_POSITIONS_WITHOUT_COMMON = Object.entries(
  organization.positions.company,
).map(
  ([k]) => [k, t(`organization:positions.company.${k}`)] as [string, string],
);
export const COMPANY_POSITIONS = [
  ...COMPANY_POSITIONS_WITHOUT_COMMON,
  ...COMMON_POSITIONS,
];

export const POSITIONS = [
  ...EDUCATION_POSITIONS_WITHOUT_COMMON,
  ...COMPANY_POSITIONS_WITHOUT_COMMON,
  ...COMMON_POSITIONS,
];

export const POSTS = Object.entries(candidate.posts).map(
  ([k]) => [k, t(`candidate:posts.${k}`)] as [string, string],
);
