import { authService } from "../auth/authService";
import { Profile } from "../profile/profileModel";
import { toastsWithIntl } from "../toastService";
import { User } from "../user/userModel";
import {
  addPaymentMethodToOrganizationRequest,
  archiveSharedProfileRequest,
  buyCapsulesCreditsRequest,
  deleteSharedProfileRequest,
  favoriteSharedProfileRequest,
  getInvitationRequest,
  inviteToJoinOrganizationRequest,
  resendInvitationMailRequest,
  unarchiveSharedProfileRequest,
  unfavoriteSharedProfileRequest,
  updateInvitationRequest,
  updateOrganizationRequest,
} from "./organizationApi";
import {
  Invitation,
  NewOrganizationData,
  Organization,
} from "./organizationModel";

export interface OrganizationService {
  updateOrganizationById: (
    organizationId: Organization["id"],
    data: Partial<NewOrganizationData>,
  ) => Promise<Organization>;
  archiveSharedProfile: (
    profileUid: Profile["uid"],
    organizationId: Organization["id"],
  ) => Promise<void>;
  unarchiveSharedProfile: (
    profileUid: Profile["uid"],
    organizationId: Organization["id"],
  ) => Promise<void>;
  favoriteSharedProfile: (
    profileUid: Profile["uid"],
    organizationId: Organization["id"],
  ) => Promise<void>;
  unfavoriteSharedProfile: (
    profileUid: Profile["uid"],
    organizationId: Organization["id"],
  ) => Promise<void>;
  deleteSharedProfile: (
    profileUid: Profile["uid"],
    organizationId: Organization["id"],
  ) => Promise<void>;
  addPaymentMethodToOrganization: (
    organizationId: Organization["id"],
    paymentMethodId: string,
  ) => Promise<void>;
  inviteToJoinOrganization: (
    organizationId: Organization["id"],
    invitation: Pick<
      Invitation,
      "firstname" | "lastname" | "email" | "membershipType"
    >,
  ) => Promise<void>;
  updateInvitation: (
    organizationId: Organization["id"],
    invitationId: Invitation["id"],
    invitation: Partial<
      Pick<
        Invitation,
        "firstname" | "lastname" | "email" | "membershipType" | "disabledDate"
      >
    >,
  ) => Promise<void>;
  resendInvitationMail: (
    organizationId: Organization["id"],
    invitationId: Invitation["id"],
  ) => Promise<void>;
  getInvitation: (invitationUid: Invitation["uid"]) => Promise<Invitation>;
  buyCapsulesCredits: (
    organizationId: Organization["id"],
    request: { estimatedPrice: number; creditsCount: number },
  ) => Promise<void>;
}

const { getLoggedUserId } = authService();

export const organizationService = () => {
  const { toastSuccess, toastError } = toastsWithIntl(["organization"]);

  const updateOrganization: OrganizationService["updateOrganizationById"] = (
    organizationId,
    data,
  ): Promise<Organization> => {
    return updateOrganizationRequest(organizationId, data).then(
      (updatedOrganization) => {
        toastSuccess("organization:update.SUCCESS");
        return Promise.resolve(updatedOrganization);
      },
      (err) => {
        toastError("organization:update.ERROR");
        return Promise.reject(err);
      },
    );
  };

  const archiveSharedProfile: OrganizationService["archiveSharedProfile"] = (
    profileUid,
    organizationId,
  ) => {
    return archiveSharedProfileRequest(
      profileUid,
      getLoggedUserId()!,
      organizationId,
    ).then(
      () => {
        toastSuccess("organization:shared-profile.archive.SUCCESS");
      },
      () => {
        toastError("organization:shared-profile.archive.ERROR");
      },
    );
  };

  const unarchiveSharedProfile: OrganizationService["unarchiveSharedProfile"] =
    (profileUid, organizationId) => {
      return unarchiveSharedProfileRequest(
        profileUid,
        getLoggedUserId()!,
        organizationId,
      ).then(
        () => {
          toastSuccess("organization:shared-profile.unarchive.SUCCESS");
        },
        () => {
          toastError("organization:shared-profile.unarchive.ERROR");
        },
      );
    };

  const favoriteSharedProfile: OrganizationService["favoriteSharedProfile"] = (
    profileUid,
    organizationId,
  ) => {
    return favoriteSharedProfileRequest(
      profileUid,
      getLoggedUserId()!,
      organizationId,
    ).then(
      () => {
        toastSuccess("organization:shared-profile.favorite.SUCCESS");
      },
      () => {
        toastError("organization:shared-profile.favorite.ERROR");
      },
    );
  };

  const unfavoriteSharedProfile: OrganizationService["unfavoriteSharedProfile"] =
    (profileUid, organizationId) => {
      return unfavoriteSharedProfileRequest(
        profileUid,
        getLoggedUserId()!,
        organizationId,
      ).then(
        () => {
          toastSuccess("organization:shared-profile.unfavorite.SUCCESS");
        },
        () => {
          toastError("organization:shared-profile.unfavorite.ERROR");
        },
      );
    };

  const deleteSharedProfile: OrganizationService["deleteSharedProfile"] = (
    profileUid,
    organizationId,
  ) => {
    return deleteSharedProfileRequest(
      profileUid,
      getLoggedUserId()!,
      organizationId,
    ).then(
      () => {
        toastSuccess("organization:shared-profile.delete.SUCCESS");
      },
      () => {
        toastError("organization:shared-profile.delete.ERROR");
      },
    );
  };

  const addPaymentMethodToOrganization: OrganizationService["addPaymentMethodToOrganization"] =
    (organizationId, paymentMethodId) => {
      return addPaymentMethodToOrganizationRequest(
        organizationId,
        paymentMethodId,
      );
    };

  const inviteToJoinOrganization: OrganizationService["inviteToJoinOrganization"] =
    (organizationId, invitation) => {
      return inviteToJoinOrganizationRequest(organizationId, invitation).then(
        () => {
          toastSuccess(
            "organization:users.invitations.invite-to-join-org.SUCCESS",
          );
        },
        (error) => {
          if (error?.response.status === 400) {
            toastError(
              "organization:users.invitations.invite-to-join-org.ERROR_USER_ALREADY_IN_ORG",
            );
          } else {
            toastError(
              "organization:users.invitations.invite-to-join-org.ERROR",
            );
          }

          return Promise.reject(error);
        },
      );
    };

  const updateInvitation: OrganizationService["updateInvitation"] = (
    organizationId,
    invitationId,
    invitation,
  ) => {
    return updateInvitationRequest(
      organizationId,
      invitationId,
      invitation,
    ).then(
      () => {
        toastSuccess(
          "organization:users.invitations.update-invitation.SUCCESS",
        );
      },
      () => {
        toastError("organization:users.invitations.update-invitation.ERROR");
      },
    );
  };

  const resendInvitationMail: OrganizationService["resendInvitationMail"] = (
    organizationId,
    invitationId,
  ) => {
    return resendInvitationMailRequest(organizationId, invitationId).then(
      () => {
        toastSuccess(
          "organization:users.invitations.send-invitation-mail.SUCCESS",
        );
      },
      () => {
        toastError("organization:users.invitations.send-invitation-mail.ERROR");
      },
    );
  };

  const getInvitation: OrganizationService["getInvitation"] = (
    invitationUid,
  ) => {
    return getInvitationRequest(invitationUid);
  };

  const buyCapsulesCredits: OrganizationService["buyCapsulesCredits"] = (
    organizationId,
    request,
  ) => {
    return buyCapsulesCreditsRequest(organizationId, request).then(
      () => {
        toastSuccess(
          "organization:subscription.capsules-credits.buy-credits.SUCCESS",
        );
      },
      (err) => {
        toastError(
          "organization:subscription.capsules-credits.buy-credits.ERROR",
        );
        return Promise.reject(err);
      },
    );
  };

  return {
    updateOrganization,
    archiveSharedProfile,
    unarchiveSharedProfile,
    favoriteSharedProfile,
    unfavoriteSharedProfile,
    deleteSharedProfile,
    addPaymentMethodToOrganization,
    inviteToJoinOrganization,
    updateInvitation,
    resendInvitationMail,
    getInvitation,
    buyCapsulesCredits,
  };
};

export function getUserOrganizationStatus(
  organizationId: Organization["id"],
  user?: User,
  invitation?: Invitation,
) {
  if (user && user.organizationId === organizationId) {
    return user.organizationMembershipStatus;
  } else {
    return !invitation?.userId
      ? "pending"
      : !invitation?.disabledDate
        ? "active"
        : "disabled";
  }
}
