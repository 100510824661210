import { Outlet, useRouteLoaderData } from "react-router-dom";
import CommonHeader from "../components/CommonHeader";
import CommonFooter from "../components/CommonFooter";
import { User } from "../services/user/userModel";

const PrivateArea = () => {
  const { loggedUser } = useRouteLoaderData("logged-user") as {
    loggedUser?: User;
  };

  return (
    <>
      <CommonHeader loggedUser={loggedUser} />

      <div className="app-layout --h">
        <div className="layout_content">
          <Outlet />
          <CommonFooter loggedUser={loggedUser} />
        </div>
      </div>
    </>
  );
};

export default PrivateArea;
