import { useMemo } from "react";
import { Organization } from "../../../services/organization/organizationModel";
import { useTranslation } from "react-i18next";
import { prettifySepa } from "../../../react-helpers/stripe";

const ProPaymentMethodForm = ({
  organization,
}: {
  organization: Organization;
}) => {
  const { t } = useTranslation(["organization"]);

  const card = useMemo(() => organization.stripeCustomer?.card, [organization]);
  const isOrganizationCardExpired = useMemo(() => {
    if (!organization?.stripeCustomer?.card) return true;

    const card = organization.stripeCustomer.card;
    const today = new Date();
    if (card.exp_year < today.getFullYear()) return true;
    if (
      card.exp_month < today.getMonth() + 1 &&
      card.exp_year == today.getFullYear()
    )
      return true;

    return false;
  }, [organization]);
  const sepa = useMemo(() => organization.stripeCustomer?.sepa, [organization]);

  return (
    <div>
      {!card && !sepa && (
        <div className="info --error">
          <div>
            {t("organization:subscription.payment-method.YOU_NEED_TO")}{" "}
            <button
              type="button"
              className="link --underlined"
              onClick={() =>
                window.open(
                  `/organization/pro/subscription/payment-method`,
                  "_blank",
                )
              }
            >
              {t(
                "organization:subscription.payment-method.RECORD_A_PAYMENT_METHOD",
              )}
            </button>
          </div>
        </div>
      )}

      {card && (
        <div className="cblocks--s">
          {isOrganizationCardExpired && (
            <div>
              <span className="chip --error">
                {t("organization:subscription.payment-method.EXPIRED")}
              </span>
            </div>
          )}

          <div className="info --no-icon --strong">
            {/* eslint-disable-next-line */}
            {card.display_brand.toLocaleUpperCase()} #### #### #### {card.last4}{" "}
            [
            {t("organization:subscription.payment-method.EXPIRE", {
              replace: {
                date: `${card.exp_month}/${card.exp_year}`,
              },
            })}
            ]
          </div>
        </div>
      )}

      {sepa && (
        <div className="cblocks--s">
          <div className="info --no-icon --strong">{prettifySepa(sepa)}</div>
        </div>
      )}

      {(!!card || !!sepa) && (
        <div className="cblock--s --txt--right">
          <button
            className="link"
            type="button"
            onClick={() =>
              window.open(
                `/organization/pro/subscription/payment-method`,
                "_blank",
              )
            }
          >
            {t(
              "organization:subscription.payment-method.UPDATE_PAYMENT_METHOD",
            )}
          </button>
        </div>
      )}
    </div>
  );
};

export default ProPaymentMethodForm;
