import {
  Link,
  NavLink,
  useLoaderData,
  useSearchParams,
} from "react-router-dom";
import { useEffect, useState } from "react";
import Logo from "../../../assets/logo-2MAX-large.png";
import CommonFooter from "../../../components/CommonFooter";
import { useKeepQueryParams } from "../../../router/routerHelpers";
import { t } from "i18next";
import Dialog from "../../../components/Dialog";
import presentation2mm from "../../../assets/video/2max_en_2_minutes.mp4";
import { ReturnButton } from "../../../components/ReturnButton";
import OrganizationRegisterForm from "./OrganizationRegisterForm";
import CandidateRegisterForm from "./CandidateRegisterForm";
import {
  Plan,
  RecurringInterval,
} from "../../../services/subscription/subscriptionModel";
import { NewUserData } from "../../../services/user/userModel";
import { NewOrganizationData } from "../../../services/organization/organizationModel";
import BankingInfoDialog from "./BankingInfoDialog";
import { ProvideStripeService } from "../../../services/stripe/stripeService";
import { cx } from "../../../react-helpers/css";

const RegisterPage = ({ isOrganization }: { isOrganization: boolean }) => {
  const { plans } = useLoaderData() as {
    plans: Plan[];
  };
  const [searchParams, setSearchParams] = useSearchParams();
  const keepQueryParams = useKeepQueryParams();
  const uid = searchParams.get("uid") ?? undefined;
  const seenSharedProfileDialog = searchParams.get("seen_spd");

  const [showSharedProfileDialog, setShowSharedProfileDialog] = useState(
    !!uid && !seenSharedProfileDialog,
  );
  const [subscriptionData, setSubscriptionData] = useState<{
    registerData: {
      user: NewUserData;
      organization: NewOrganizationData;
      profileShareUid?: string;
    };
    plan: Plan;
    recurrency: RecurringInterval;
    promoCode: string | null;
  } | null>(null);

  useEffect(() => {
    if (showSharedProfileDialog) {
      setSearchParams((p) => {
        p.set("seen_spd", "1");
        return p;
      });
    }
  }, [searchParams, setSearchParams, showSharedProfileDialog]);

  return (
    <div className="auth-screen">
      <img src={Logo} alt="2 Minutes Max" className="logo" />
      <div className={cx(["auth-card", isOrganization && "--wide"])}>
        <div className="side-image"></div>

        <div className="card_body">
          <div className="lblock">
            {isOrganization && (
              <OrganizationRegisterForm
                profileShareUid={uid}
                plans={plans}
                onRegister={(registerData, plan, recurrency, promoCode) => {
                  setSubscriptionData({
                    registerData,
                    plan,
                    recurrency,
                    promoCode,
                  });
                  return Promise.resolve();
                }}
              />
            )}
            {!isOrganization && <CandidateRegisterForm />}
          </div>
          <div className="cblock --txt--center">
            <p className="auth-cta">{t("register.ALREADY_REGISTERED")}</p>
            <NavLink className="link" to={keepQueryParams("/login")}>
              {t("register.LOGIN")}
            </NavLink>
          </div>
          {isOrganization && (
            <div className="cblock --txt--center">
              <Link
                className="btn--3"
                to={`/contact?object=${encodeURIComponent(t("register.NEED_A_QUOTE_MAIL_OBJECT"))}`}
              >
                {t("register.NEED_A_QUOTE_CONTACT_US")}
              </Link>
            </div>
          )}
          <div className="cblock --txt--center">
            <ReturnButton forcedUrl={keepQueryParams("/register-choice")} />
          </div>
        </div>
      </div>
      <CommonFooter />
      {subscriptionData && (
        <ProvideStripeService>
          <BankingInfoDialog
            onClose={() => setSubscriptionData(null)}
            plan={subscriptionData.plan}
            promoCode={subscriptionData.promoCode}
            recurrency={subscriptionData.recurrency}
            registerData={subscriptionData.registerData}
          />
        </ProvideStripeService>
      )}
      {showSharedProfileDialog && (
        <Dialog
          className="--branded"
          onClose={() => setShowSharedProfileDialog(false)}
        >
          <div>
            <div className="popup_head">
              <h2 className="popup_title">{t("auth:login.VIEW_PROFILE")}</h2>
            </div>
            <div className="popup_body">
              <video
                className="videoplayer"
                playsInline
                src={presentation2mm}
                controls
                onContextMenu={(e) => e.preventDefault()}
              />
              <div className="cblock --txt--center --pre-line">
                {t("organization:shared-profile-accepted")}
              </div>
            </div>
            <div className="popup_footer">
              <button
                className="btn --primary"
                onClick={() => setShowSharedProfileDialog(false)}
              >
                {t("auth:login.OK")}
              </button>
            </div>
          </div>
        </Dialog>
      )}
    </div>
  );
};

export default RegisterPage;
