import { useEffect, useMemo, useState } from "react";
import Dialog from "../../components/Dialog";
import { createCapsuleRequest } from "../../services/capsule/capsuleApi";
import {
  Link,
  useLoaderData,
  useNavigate,
  useRouteLoaderData,
} from "react-router-dom";
import { Capsule } from "../../services/capsule/capsuleModel";
import CapsuleCard from "./capsule/CapsuleCardComponent";
import { toastsWithIntl } from "../../services/toastService";
import { User } from "../../services/user/userModel";
import { cx } from "../../react-helpers/css";
import { useConfirmationWithIntl } from "../../components/ConfirmationDialog";
import { capsuleService } from "../../services/capsule/capsuleService";
import { useTranslation } from "react-i18next";
import useReload from "../../hooks/useReload";
import CapsuleForm from "./capsule/CapsuleForm";
import { ReturnButton } from "../../components/ReturnButton";

const OrganizationCompanyCapsules = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const reload = useReload();
  const { confirm } = useConfirmationWithIntl(["organization"]);
  const { toastError } = toastsWithIntl(["organization"]);
  const { archiveCapsule, unarchiveCapsule } = capsuleService();

  const { loggedUser } = useRouteLoaderData("logged-user") as {
    loggedUser: User;
  };
  const { capsules } = useLoaderData() as {
    capsules: Capsule[];
  };

  const filteredCapsules = useMemo(() => {
    return capsules
      .filter((c) => c.archivedAt === null)
      .sort((a, b) => (new Date(a.createdAt) > new Date(b.createdAt) ? 1 : -1))
      .sort((a) => {
        // put disabled profiles at the end of the list
        return a.disabledDate ? 1 : -1;
      });
  }, [capsules]);

  const archivedCapsules = useMemo(() => {
    return capsules
      .filter((c) => c.archivedAt !== null)
      .sort((a, b) => (new Date(a.createdAt) > new Date(b.createdAt) ? 1 : -1))
      .sort((a) => {
        // put disabled profiles at the end of the list
        return a.disabledDate ? 1 : -1;
      });
  }, [capsules]);

  const creditsRemaining = useMemo(
    () => loggedUser.organization!.maxCapsulesCount - capsules.length,
    [loggedUser.organization, capsules.length],
  );

  const [showArchived, setShowArchived] = useState(false);

  const [showNewCapsuleDialog, setShowNewCapsuleDialog] = useState(false);

  useEffect(() => {
    if (showArchived && archivedCapsules.length === 0) {
      setShowArchived(false);
    }
  }, [archivedCapsules.length, showArchived]);

  return (
    <div className="layout_content --bg--organization">
      <div className="page-content container --wrap">
        <div>
          <ReturnButton forcedUrl="/organization">
            {t("organization:RETURN_TO_RECRUITER_DASHBOARD")}
          </ReturnButton>
        </div>

        <div className="page_head lblock">
          <h2 className="page_title">
            {t("organization:capsules.YOUR_CAPSULES")}
          </h2>
          <p>{t("organization:capsules.CREATE_CAPSULE_PROMPT")}</p>
        </div>

        <div className="lblock--l">
          {!!loggedUser.adminOrga && (
            <div className="cblock--s">
              <Link className="btn--3" to="../pro/subscription">
                {t("organization:capsules.BUY_CREDITS")}
              </Link>
            </div>
          )}
          {capsules.length < loggedUser.organization!.maxCapsulesCount && (
            <div className="cblock--s body--50">
              <div className="ui-row --center--v">
                {t("organization:capsules.REMAINING_CREDITS", {
                  credits: creditsRemaining,
                })}
              </div>
            </div>
          )}
          {loggedUser.organization!.maxCapsulesCount > 0 &&
            capsules.length >= loggedUser.organization!.maxCapsulesCount && (
              <div className="cblock info --warning">
                {t("organization:capsules.NO_CREDITS_LEFT")}
              </div>
            )}
          {loggedUser.organization!.maxCapsulesCount == 0 && (
            <div className="lblock info --warning">
              {t("organization:capsules.NO_CREDITS_AVAILABLE")}
            </div>
          )}
        </div>

        <div className="lblock">
          {creditsRemaining > 0 && (
            <div className="info cblock">
              <div>{t("organization:capsules.BEFORE_YOU_START")}</div>
              <div>{t("organization:capsules.REPEAT_POSSIBILITY")}</div>
              <div>{t("organization:capsules.SHARING_LINK_UNCHANGED")}</div>
              <div>{t("organization:capsules.MORE_TIPS")}</div>
            </div>
          )}
        </div>

        <div className="lblock">
          <button
            className="btn"
            onClick={() => setShowNewCapsuleDialog(true)}
            disabled={
              capsules.length >= loggedUser.organization!.maxCapsulesCount
            }
          >
            {t("organization:capsules.NEW_CAPSULE")}
          </button>
        </div>

        {archivedCapsules.length > 0 && (
          <div className="lblock tabs">
            <button
              className={cx(["tab_item", !showArchived && "active"])}
              onClick={() => setShowArchived(false)}
            >
              {t("organization:capsules.ACTIVE_CAPSULES")}
            </button>
            <button
              className={cx(["tab_item", !!showArchived && "active"])}
              onClick={() => setShowArchived(true)}
            >
              {t("organization:capsules.ARCHIVED_CAPSULES")}
            </button>
          </div>
        )}

        {capsules.length > 0 && (
          <div className="lblock--l grid --fill">
            {!showArchived &&
              filteredCapsules.map((capsule) => (
                <CapsuleCard
                  key={capsule.id}
                  capsule={capsule}
                  isArchived={!!capsule.archivedAt}
                  onArchive={() => {
                    confirm(
                      "organization:capsule.archive.CONFIRM",
                      () =>
                        archiveCapsule(capsule.id).then(() => {
                          reload();
                        }),
                      true,
                    );
                  }}
                />
              ))}
            {!!showArchived &&
              archivedCapsules.map((capsule) => (
                <CapsuleCard
                  key={capsule.id}
                  capsule={capsule}
                  isArchived={!!capsule.archivedAt}
                  onUnarchive={() => {
                    confirm(
                      "organization:capsule.unarchive.CONFIRM",
                      () =>
                        unarchiveCapsule(capsule.id).then(() => {
                          reload();
                        }),
                      true,
                    );
                  }}
                />
              ))}
          </div>
        )}

        {capsules.length === 0 &&
          loggedUser.organization!.maxCapsulesCount > 0 && (
            <div className="lblock--l">
              <div className="info">
                {t("organization:capsules.NO_CAPSULES_YET")}
                <br />
                <button
                  className="btn--2 --force"
                  onClick={() => setShowNewCapsuleDialog(true)}
                  disabled={
                    capsules.length >= loggedUser.organization!.maxCapsulesCount
                  }
                >
                  {t("organization:capsules.CREATE_FIRST_CAPSULE")}
                </button>
              </div>
            </div>
          )}

        {showNewCapsuleDialog && (
          <Dialog
            className="--branded"
            onClose={() => setShowNewCapsuleDialog(false)}
          >
            <>
              <div className="popup_head">
                <div className="popup_title">
                  {t("organization:create-capsule.NEW_CAPSULE_DIALOG_TITLE")}
                </div>
              </div>

              <CapsuleForm
                onSubmit={(values) => {
                  return createCapsuleRequest(values).then(
                    (capsule) => {
                      return navigate(`/organization/capsules/${capsule.id}`);
                    },
                    (err) => {
                      if (err.response?.status === 412) {
                        toastError("organization:create-capsule.MAX_CAPSULES");
                        reload();
                        setShowNewCapsuleDialog(false);
                      }
                    },
                  );
                }}
                onCancel={() => setShowNewCapsuleDialog(false)}
              />
            </>
          </Dialog>
        )}
      </div>
    </div>
  );
};

export default OrganizationCompanyCapsules;
