import { useNavigate } from "react-router-dom";
import SubmitButton from "../../../../components/SubmitButton";
import SxForm from "../../../../forms/SxForm";
import ValidationErrors from "../../../../forms/ValidationErrors";
import SxField from "../../../../forms/fields/SxField";
import { authService } from "../../../../services/auth/authService";
import { Invitation } from "../../../../services/organization/organizationModel";
import { POSITIONS } from "../../../../services/user/userModel";
import { useMemo } from "react";
import { object, string } from "yup";
import { useTranslation } from "react-i18next";
import { useCountries } from "../../../../services/profile/profileService";
import { transformEmptyToUndefined } from "../../../../react-helpers/yup";
import { cx } from "../../../../react-helpers/css";

const { acceptInvitation } = authService();

const RegisterWithInvitationForm = ({
  invitation,
}: {
  invitation: Invitation;
}) => {
  const navigate = useNavigate();
  const { t } = useTranslation(["auth", "validation"]);

  const countries = useCountries();

  const isGuest = useMemo(
    () => invitation.membershipType === "GUEST",
    [invitation],
  );

  const initialValues = useMemo(() => {
    const initialValues = {
      firstName: invitation.lastname,
      lastName: invitation.firstname,
      email: invitation.email,
      password: "",
      phoneNumber: "",
      positionInOrganization: "",
    };

    return isGuest
      ? {
          ...initialValues,
          legalName: "",
          addressLine1: "",
          addressLine2: "",
          postalCode: "",
          city: "",
          country: "FR",
        }
      : initialValues;
  }, [invitation, isGuest]);

  const validationSchema = useMemo(() => {
    return object({
      firstName: string().label(t("auth:register.labels.FIRSTNAME")).required(),
      lastName: string().label(t("auth:register.labels.LASTNAME")).required(),
      email: string()
        .email()
        .label(t("auth:register.labels.EMAIL"))
        .disabled()
        .required(),
      password: string()
        .password()
        .label(t("auth:register.labels.PASSWORD"))
        .required(),
      phoneNumber: string().label(t("auth:register.labels.PHONE")),
      positionInOrganization: string()
        .label(t("auth:register.labels.POSITION"))
        .transform(transformEmptyToUndefined)
        .required()
        .oneOf(POSITIONS.map(([k]) => k)),
      ...(isGuest
        ? {
            legalName: string()
              .label(t("auth:register.labels.LEGAL_NAME"))
              .required(),
            addressLine1: string()
              .label(t("auth:register.labels.ADDRESS_LINE1"))
              .required(),
            addressLine2: string().label(
              t("auth:register.labels.ADDRESS_LINE2"),
            ),
            postalCode: string()
              .label(t("auth:register.labels.POSTAL_CODE"))
              .required(),
            city: string().label(t("auth:register.labels.CITY")).required(),
            country: string()
              .label(t("auth:register.labels.COUNTRY"))

              .oneOf(
                countries.map((c) => c[0]),
                t("validation:register.INVALID_COUNTRY"),
              )
              .required(),
          }
        : {}),
    });
  }, [t, countries, isGuest]);

  return (
    <SxForm
      initialValues={initialValues}
      onSubmit={(values) => {
        return acceptInvitation(invitation.uid, {
          user: {
            firstname: values.firstName,
            lastname: values.lastName,
            email: values.email,
            password: values.password,
            phoneNumber:
              values.phoneNumber && values.phoneNumber.length > 0
                ? values.phoneNumber
                : null,
            positionInOrganization: values.positionInOrganization as
              | string
              | null,
            searchedPost: null,
          },
          organization: isGuest
            ? ({
                legalName: values.legalName,
                email: values.email,
                phoneNumber: values.phoneNumber,
                addressLine1: values.addressLine1,
                addressLine2: values.addressLine2,
                postalCode: values.postalCode,
                city: values.city,
                country: values.country,
              } as any)
            : undefined,
        }).then(
          () => {
            navigate("/login");
          },
          () => {
            // DO NOTHING
          },
        );
      }}
      validationSchema={validationSchema}
    >
      <div className={cx([invitation.membershipType === "GUEST" && "grid-2"])}>
        <div>
          <div className="form-block">
            <h2 className="title--40">{t("auth:register.USER_ACCOUNT")}</h2>
          </div>
          <div className="grid-2 form-block">
            <SxField
              name="firstName"
              placeholder={t("auth:register.placeholders.FIRSTNAME")}
              noBlock
            />
            <SxField
              name="lastName"
              placeholder={t("auth:register.placeholders.LASTNAME")}
              noBlock
            />
          </div>
          <SxField
            name="email"
            placeholder={t("auth:register.placeholders.EMAIL")}
          />
          <SxField
            name="phoneNumber"
            placeholder={t("auth:register.placeholders.PHONE")}
          />
          <SxField
            name="password"
            placeholder={t("auth:register.placeholders.PASSWORD")}
          />
          <SxField name="positionInOrganization" as="select">
            <option value="">{t("register.placeholders.POSITION")}</option>
            {POSITIONS.map(([k, v]) => (
              <option key={k} value={k}>
                {v}
              </option>
            ))}
          </SxField>
        </div>

        {invitation.membershipType === "GUEST" && (
          <div>
            <div className="form-block">
              <h2 className="title--40">{t("auth:register.ORGANIZATION")}</h2>
            </div>
            <SxField
              name="legalName"
              placeholder={t("register.placeholders.LEGAL_NAME")}
            />
            <SxField name="addressLine1" />
            <SxField name="addressLine2" />
            <div className="grid-2 form-block">
              <SxField name="postalCode" noBlock />
              <SxField name="city" noBlock />
            </div>
            <SxField name={"country"} as={"select"}>
              {countries.map(([code, name]) => (
                <option key={code} value={code}>
                  {name}
                </option>
              ))}
            </SxField>
          </div>
        )}
      </div>

      <ValidationErrors />
      <div className="form_footer --txt--center">
        <SubmitButton className="btn">
          {invitation.membershipType === "GUEST"
            ? t("auth:join-org.CREATE_ACCOUNT_AND_ACTIVATE")
            : t("auth:join-org.JOIN")}
        </SubmitButton>
      </div>
    </SxForm>
  );
};

export default RegisterWithInvitationForm;
