import { ReactNode, createRef, useCallback, useMemo, useState } from "react";
import { Link, useLoaderData, useNavigate } from "react-router-dom";
import { Profile } from "../../services/profile/profileModel";
import SubmitButton from "../../components/SubmitButton";
import { profileService } from "../../services/profile/profileService";
import { useConfirmationWithIntl } from "../../components/ConfirmationDialog";
import { ReturnButton } from "../../components/ReturnButton";
import { QRCodeCanvas } from "qrcode.react";
import qrCodeBrand from "../../assets/logo-2max.png";
import { downloadURL } from "../../react-helpers/url";
import { loggerBuilder } from "../../services/logger";
import SuspenseVideoPlayer from "../../components/SuspenseVideoPlayer";
import Dialog from "../../components/Dialog";
import { cx } from "../../react-helpers/css";
import { toastsWithIntl } from "../../services/toastService";

import IconDownload from "../../assets/icons/icon-download.svg";
import IconEmail from "../../assets/icons/icon-email.svg";
import IconLinkedin from "../../assets/icons/icon-linkedin.svg";
import IconCopy from "../../assets/icons/icon-copy.svg";
import IconEdit from "../../assets/icons/icon-edit-white.svg";
import { thumbnailOfVideo } from "../../react-helpers/blob";
import IconImage from "../../assets/icons/icon-image-white.svg";
import { User } from "../../services/user/userModel";
import { useTranslation } from "react-i18next";
import useReload from "../../hooks/useReload";
import ProfileForm from "./profile/ProfileForm";
import { Tooltip } from "react-tooltip";

const logger = loggerBuilder("profile-page");

const { toastSuccess } = toastsWithIntl(["candidate"]);

const ProfilePage = (): ReactNode => {
  const { t } = useTranslation();
  const { profile, video } = useLoaderData() as {
    profile: Profile;
    video: Promise<Blob> | null;
    loggedUser: User;
  };
  const {
    updateProfileById,
    enableProfileById,
    disableProfileById,
    deleteProfileById,
    generateProfileCoverLetter,
  } = profileService();
  const { confirm } = useConfirmationWithIntl(["candidate"]);
  const navigate = useNavigate();
  const reload = useReload();

  const [showEdit, setShowEdit] = useState(false);
  const sharedProfileUrl = useMemo(
    () => window.location.origin + "/profile-share/" + profile.uid,
    [profile.uid],
  );

  const linkedInShareURL = `https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(sharedProfileUrl)}`;

  // REVIEW: @cedric do we keep the last 10 users visiting the shared link?
  // const lastsUsersVisiting = useMemo(() => {
  //   return profile.userVisiting.slice(0, 10);
  // }, [profile.userVisiting]);

  const ref = createRef<HTMLVideoElement>();

  const updateThumbnail = useCallback(
    async (video: HTMLVideoElement) => {
      const newThumbnail = await thumbnailOfVideo(video);

      return updateProfileById(profile.id, { thumbnail: newThumbnail });
    },
    [profile.id, updateProfileById],
  );

  const [showThumbnailPopup, setShowThumbnailPopup] = useState(false);

  const downloadCoverLetter = useCallback(
    (qrCode: HTMLCanvasElement) => {
      // if the cover letter is not generated yet, we generate it
      const qrCodeData = qrCode.toDataURL("image/jpeg");
      return generateProfileCoverLetter(profile.id, qrCodeData).then(
        (coverLetter) => {
          const url = URL.createObjectURL(
            new Blob([coverLetter], { type: "application/pdf" }),
          );
          downloadURL(url, "2_MAX.pdf");
          reload();
        },
      );
    },
    [profile.id, generateProfileCoverLetter, reload],
  );

  return (
    <div className="layout_content --bg--candidate">
      <div className="page-content container --wrap--m ">
        <div>
          <ReturnButton forcedUrl="/candidate">
            {t("candidate:profile.buttons.GO_BACK")}
          </ReturnButton>
        </div>
        <div className="page_head lblock">
          <div className="lrow --nogap--v --bottom">
            <div>
              <h1 className="page_title">{profile.name}</h1>
            </div>
            <div>
              <div className="lrow --gap--xxs">
                <SubmitButton
                  className={cx([
                    "btn--icon --simple",
                    profile.disabledDate ? "--unlock" : "--lock",
                  ])}
                  onClick={() =>
                    profile.disabledDate
                      ? enableProfileById(profile.id).then(() => reload())
                      : confirm(
                          "candidate:disable-profile.CONFIRM",
                          () =>
                            disableProfileById(profile.id).then(() => reload()),
                          true,
                        )
                  }
                  title={t("candidate:profile.buttons.tips.ACTIVATION", {
                    action: profile.disabledDate
                      ? t("candidate:profile.buttons.ENABLE")
                      : t("candidate:profile.buttons.DISABLE"),
                  })}
                />
                <button
                  className="btn--icon --simple --edit"
                  onClick={() => setShowEdit(true)}
                  title={t("candidate:profile.buttons.tips.EDIT")}
                />

                <SubmitButton
                  className="btn--icon --simple --delete"
                  onClick={() =>
                    confirm(
                      "candidate:delete-profile.CONFIRM",
                      () =>
                        deleteProfileById(profile.id).then(() =>
                          navigate(".."),
                        ),
                      true,
                    )
                  }
                  title={t("candidate:profile.buttons.tips.DELETE")}
                />
              </div>
            </div>
          </div>
          <div className="cblock lrow --gap--s --center--v">
            {profile.disabledDate ? (
              <div className="chip --2">
                {t("candidate:profile.PROFILE_DISABLED")}
              </div>
            ) : (
              <>
                {!profile.featured ? (
                  <SubmitButton
                    className="btn--2 --btn--s"
                    onClick={() =>
                      updateProfileById(profile.id, { featured: true }).then(
                        () => reload(),
                      )
                    }
                  >
                    {t("candidate:profile.buttons.SET_AS_MAIN_PROFILE")}
                  </SubmitButton>
                ) : (
                  <div className="chip --2">
                    {t("candidate:profile.MAIN_PROFILE")}
                  </div>
                )}

                <Tooltip id="access-key" />
                <div
                  className="info --compact --help"
                  data-tooltip-id="access-key"
                  data-tooltip-content={t(
                    "candidate:profile.ACCESS_KEY_EXPLANATION",
                  )}
                >
                  <strong>
                    {t("candidate:profile.ACCESS_KEY_IS")} {profile.accessCode}
                  </strong>
                  {t("candidate:profile.ACCESS_KEY_INFO")}
                </div>
              </>
            )}
          </div>
        </div>

        <div className="lrow --gap--l lblock--l">
          <div className="profile_video-col">
            {video && (
              <div>
                <div className="video-container">
                  <SuspenseVideoPlayer
                    videoPromise={video}
                    thumbnail={profile.thumbnail}
                  />
                </div>
                <div className="cblock ui-row --right">
                  <Link className="btn--2 --btn--s__m" to={"record"}>
                    <img src={IconEdit} alt="update video" className="icon" />
                    {t("candidate:profile.buttons.RETAKE_VIDEO")}
                  </Link>
                  <button
                    className="btn--2 --btn--s__m"
                    onClick={() => setShowThumbnailPopup(true)}
                  >
                    <img src={IconImage} alt="vignette" className="icon" />
                    {t("candidate:profile.buttons.UPDATE_THUMBNAIL")}
                  </button>
                </div>
              </div>
            )}

            {!profile.disabledDate && !video && (
              <Link to={"record"} className="video-placeholder">
                <canvas width="16" height="9" />
                <div>
                  <button className="btn --btn--l">
                    {t("candidate:profile.buttons.RECORD_VIDEO")}
                  </button>
                </div>
              </Link>
            )}
          </div>

          {!!video && !profile.disabledDate && (
            <div className="profile_sidebar">
              <div className="share_buttons">
                <button
                  className="btn--share"
                  onClick={() => {
                    void navigator.clipboard
                      .writeText(sharedProfileUrl)
                      .then(() => {
                        toastSuccess("candidate:share.LINK_COPIED");
                      });
                  }}
                  title={t("candidate:profile.buttons.tips.SHARE_LINK")}
                >
                  <img
                    src={IconCopy}
                    alt="copy link to clipboard"
                    className="icon"
                  />
                  {t("candidate:profile.buttons.share.LINK")}
                </button>
                <button
                  className="btn--share"
                  onClick={() => {
                    const qrRef = document.getElementById(
                      "qrcode",
                    ) as HTMLCanvasElement | null;
                    if (qrRef) {
                      void downloadCoverLetter(qrRef);
                    } else {
                      logger.warn("qr code not found");
                    }
                  }}
                  title={t("candidate:profile.buttons.tips.SHARE_PDF")}
                >
                  <img src={IconDownload} alt="download" className="icon" />
                  <span>{t("candidate:profile.buttons.share.PDF_2MAX")}</span>
                </button>
                <a
                  className="btn--share --linkedin"
                  href={linkedInShareURL}
                  target="_blank"
                  rel="noreferrer"
                  title={t("candidate:profile.buttons.tips.SHARE_LINKEDIN")}
                >
                  <img src={IconLinkedin} alt="Linkedin" className="icon" />
                  {t("candidate:profile.buttons.share.LINKEDIN")}
                </a>
                <a
                  className="btn--share"
                  href={`mailto:?body=${encodeURIComponent(sharedProfileUrl)}`}
                  target="_blank"
                  rel="noreferrer"
                  title={t("candidate:profile.buttons.tips.SHARE_EMAIL")}
                >
                  <img src={IconEmail} alt="Email" className="icon" />
                  {t("candidate:profile.buttons.share.EMAIL")}
                </a>

                <button
                  className="btn--share"
                  onClick={() => {
                    const qrRef = document.getElementById(
                      "qrcode",
                    ) as HTMLCanvasElement | null;
                    if (qrRef) {
                      downloadURL(qrRef.toDataURL("image/png"), "qrcode.png");
                    } else {
                      logger.warn("qr code not found");
                    }
                  }}
                  title={t("candidate:profile.buttons.tips.SHARE_QR_CODE")}
                >
                  <img src={IconDownload} alt="download" className="icon" />
                  {t("candidate:profile.buttons.share.QR_CODE_FOR_CV")}
                </button>
              </div>
              <QRCodeCanvas
                id={"qrcode"}
                includeMargin
                value={window.location.origin + "/profile-share/" + profile.uid}
                size={150}
                level="H"
                imageSettings={{
                  src: qrCodeBrand,
                  height: 50,
                  width: 50,
                  excavate: true,
                }}
                fgColor="#0c0557"
              />

              {/* REVIEW: @cedric and do we display their mails? */}
              {/* <div>
                  <h3>Les 10 dernières consultations</h3>
                  {lastsUsersVisiting.map((consultation, index) => (
                    <div key={index}>
                      <p>
                        {format(new Date(consultation.consultationDate), "P")}-{" "}
                        {consultation.user.email}
                      </p>
                    </div>
                  ))}
                </div> */}
            </div>
          )}
        </div>
      </div>
      {showEdit && (
        <Dialog className="--branded" onClose={() => setShowEdit(false)}>
          <>
            <div className="popup_head">
              <h2 className="popup_title">
                {t("candidate:update-profile.DIALOG_TITLE")}
              </h2>
            </div>

            <ProfileForm
              onSubmit={(values) => {
                return updateProfileById(profile.id, values).then(() => {
                  setShowEdit(false);
                  reload();
                });
              }}
              onCancel={() => setShowEdit(false)}
              profile={profile}
            />
          </>
        </Dialog>
      )}
      {video && showThumbnailPopup && (
        <Dialog
          className="popup--update-thumbnail"
          onClose={() => setShowThumbnailPopup(false)}
        >
          <>
            <div className="popup_head">
              <h2 className="popup_title">
                {t("candidate:profile.thumbnail.DIALOG_TITLE")}
              </h2>
            </div>
            <div className="popup_body">
              <div className="explanations-row">
                <p className="body--40">
                  {t("candidate:profile.thumbnail.THUMBNAIL_EXPLANATION")}
                </p>
              </div>

              <div className="lblock">
                <SuspenseVideoPlayer
                  videoPromise={video}
                  ref={ref}
                  thumbnail={profile.thumbnail}
                />
              </div>
            </div>
            <div className="popup_footer">
              <button
                className="btn--grey"
                onClick={() => setShowThumbnailPopup(false)}
              >
                {t("candidate:profile.buttons.CANCEL")}
              </button>
              <SubmitButton
                className="btn"
                onClick={() => {
                  return updateThumbnail(ref.current!).then(() => reload());
                }}
              >
                {t("candidate:profile.buttons.UPDATE")}
              </SubmitButton>
            </div>
          </>
        </Dialog>
      )}
    </div>
  );
};

export default ProfilePage;
