import { useTranslation } from "react-i18next";
import Dialog from "../../../components/Dialog";
import { Organization } from "../../../services/organization/organizationModel";
import { useMemo, useState } from "react";
import { calculateCreditsPrice } from "../../../services/subscription/subscriptionService";
import DisplayPrice from "../../../components/DisplayPrice";
import { useConfirmationWithIntl } from "../../../components/ConfirmationDialog";
import SubmitButton from "../../../components/SubmitButton";
import { organizationService } from "../../../services/organization/organizationService";

const MAX_CREDITS_AMOUNT = 100;

const { buyCapsulesCredits } = organizationService();

const BuyCapsulesCreditsDialog = ({
  organization,
  onClose,
}: {
  onClose(): void;
  organization: Organization;
}) => {
  const { t } = useTranslation(["organization"]);
  const { confirm } = useConfirmationWithIntl(["organization"]);
  const [creditsAmount, setCreditsAmount] = useState<number | null>(null);

  const estimatedPrice = useMemo(
    () =>
      (creditsAmount ?? 0) > 0 ? calculateCreditsPrice(creditsAmount!) : null,
    [creditsAmount],
  );

  return (
    <Dialog onClose={onClose} className="--branded">
      <>
        <div className="popup_head">
          <div className="popup_title">
            {t("organization:subscription.capsules-credits.DIALOG_TITLE")}
          </div>
        </div>
        <div className="popup_body">
          {estimatedPrice && (
            <div className="cblock">
              {t("organization:subscription.capsules-credits.ESTIMATED_PRICE", {
                replace: {
                  count: creditsAmount,
                },
              })}
              <DisplayPrice
                amount={estimatedPrice * 100}
                currency="eur"
                taxFree
              />
            </div>
          )}
          <div className="cblock">
            <input
              type="number"
              className="input"
              required
              max="100"
              placeholder={t(
                "organization:subscription.capsules-credits.CREDITS_AMOUNT_PLACEHOLDER",
              )}
              onChange={(ev) => {
                const num = Number(ev.target.value);
                if (!isNaN(num))
                  setCreditsAmount(
                    Math.min(Math.ceil(num), MAX_CREDITS_AMOUNT),
                  );
              }}
            />
          </div>
          <div
            className="field-tip"
            dangerouslySetInnerHTML={{
              __html: t("organization:subscription.capsules-credits.MAX_TIP", {
                replace: {
                  max: MAX_CREDITS_AMOUNT,
                },
              }),
            }}
          />
          <div className="cblock --txt--center">
            <SubmitButton
              className="btn--1"
              disabled={!estimatedPrice}
              onClick={() => {
                return confirm(
                  "organization:subscription.capsules-credits.buy-credits.CONFIRM",
                  async () => {
                    await buyCapsulesCredits(organization.id, {
                      creditsCount: creditsAmount ?? 0,
                      estimatedPrice: estimatedPrice ?? 0,
                    });
                    onClose();
                  },
                  true,
                  {
                    confirmText: t(
                      "organization:subscription.capsules-credits.buy-credits.CONFIRM_TEXT",
                    ),
                    replace: {
                      count: creditsAmount,
                      amount: (estimatedPrice ?? 0) * 1.2,
                    },
                  },
                );
              }}
            >
              {t("organization:subscription.capsules-credits.BUY")}
            </SubmitButton>
          </div>
        </div>
      </>
    </Dialog>
  );
};

export default BuyCapsulesCreditsDialog;
