import { useNavigate, useParams } from "react-router-dom";
import { authService } from "../../../services/auth/authService";
import SxForm from "../../../forms/SxForm";
import { object, string } from "yup";
import SxField from "../../../forms/fields/SxField";
import SubmitButton from "../../../components/SubmitButton";
import { validationTranslation } from "../../../internationalization/i18n";
import { useTranslation } from "react-i18next";

function ResetPasswordForm() {
  const { t } = useTranslation(["auth"]);
  const { guid } = useParams();
  const auth = authService();
  const navigate = useNavigate();

  return (
    <SxForm
      initialValues={{
        newPassword: "",
        newPasswordConfirmation: "",
      }}
      onSubmit={({ newPassword }) => {
        return auth.resetPassword(guid!, newPassword).then(() => {
          return navigate("/login");
        });
      }}
      validationSchema={object({
        newPassword: string()
          .password()
          .label(t("auth:reset-password.labels.PASSWORD"))
          .required(),
        newPasswordConfirmation: string()
          .label(t("auth:reset-password.labels.PASSWORD_CONFIRM"))
          .required()
          .test(
            "password-match",
            validationTranslation("auth:reset-password.PASSWORDS_MISMATCH"),
            function (value) {
              return value === this.parent.newPassword;
            },
          ),
      })}
    >
      <SxField
        name="newPassword"
        placeholder={t("auth:reset-password.placeholders.PASSWORD")}
      />
      <SxField
        as="password"
        name="newPasswordConfirmation"
        placeholder={t("auth:reset-password.placeholders.PASSWORD_CONFIRM")}
      />
      <div className="lblock--l --txt--center">
        <SubmitButton>{t("auth:reset-password.SUBMIT_BUTTON")}</SubmitButton>
      </div>
    </SxForm>
  );
}

export default ResetPasswordForm;
