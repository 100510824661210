import { useTranslation } from "react-i18next";
import Logo from "../../../assets/logo-2MAX-large.png";
import CommonFooter from "../../../components/CommonFooter";
import { Link } from "react-router-dom";
import { useKeepQueryParams } from "../../../router/routerHelpers";

const RegisterChoicePage = () => {
  const { t } = useTranslation(["auth"]);
  const keepQueryParams = useKeepQueryParams();

  return (
    <div className="auth-screen">
      <img src={Logo} alt="2 Minutes Max" className="logo" />
      <div className="auth-card">
        <div className="side-image"></div>

        <div className="card_body">
          <h1 className="title--50 --txt--center cblock">
            {t("auth:register.WHICH_ACCOUNT_TYPE")}
          </h1>

          <div className="lrow cblock --center--h">
            <div>
              <Link to={keepQueryParams("/register")} className="btn--1">
                {t("auth:register.CANDIDATE")}
              </Link>
            </div>
            <div>
              <Link
                to={keepQueryParams("/register/organization")}
                className="btn--2"
              >
                {t("auth:register.PROFESSIONAL")}
              </Link>
            </div>
          </div>

          <div className="cblock --txt--center">
            <p className="auth-cta">{t("register.ALREADY_REGISTERED")}</p>
            <Link className="link" to={keepQueryParams("/login")}>
              {t("register.LOGIN")}
            </Link>
          </div>
        </div>
      </div>
      <CommonFooter />
    </div>
  );
};

export default RegisterChoicePage;
