import baseAPI from "../api";
import { Organization } from "../organization/organizationModel";
import { Plan, Subscription } from "./subscriptionModel";

export function getPlansRequest() {
  return baseAPI.get("/plans").then(({ data }) => data);
}

export function cancelSubscriptionRequest(stripeSubscriptionId: string) {
  return baseAPI
    .post(`/stripe/subscriptions/${stripeSubscriptionId}/cancel`)
    .then(({ data }) => data);
}

export function subscribeToPlanRequest(
  organizationId: Organization["id"],
  planId: Plan["id"],
  recurrency: Subscription["recurringInterval"],
  promoCode: string,
) {
  return baseAPI
    .post(`/organization/${organizationId}/subscribe/${planId}`, {
      recurrency,
      promoCode,
    })
    .then(({ data }) => data);
}
