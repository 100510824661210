import {
  PropsWithChildren,
  createContext,
  createRef,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { downloadURL } from "../react-helpers/url";
import { loggerBuilder } from "../services/logger";
import { FFmpeg, createFFmpeg } from "@ffmpeg/ffmpeg";
import fixWebmDuration from "webm-duration-fix";
import { toastsWithIntl } from "./toastService";
import { useNavigate } from "react-router-dom";
import { useConfirmationWithIntl } from "../components/ConfirmationDialog";
import { useTranslation } from "react-i18next";

declare global {
  interface Window {
    // Global because on memory constrained devices we can be sure to load it only once
    ffmpeg: FFmpeg | null;
  }
}

export const MAX_HEIGHT = 720;
export const FRAMERATE = 24;

const logger = loggerBuilder("recorder");
const { toastError } = toastsWithIntl(["record"]);

interface RecorderService {
  timeElapsed: number | null;
  recording: boolean;
  ffmpegPercent: number | null;
  isRecorderReady: boolean;

  startRecorder(): Promise<MediaStream>;
  stopRecorder(): void;

  onStop(): Promise<Blob>;
  onStart(): void;
  onExport(filename: string): void;
  onCompress(video?: Blob, duration?: number): Promise<Blob>;
  onUpload(
    getSignedUploadLink: () => Promise<string>,
    video?: Blob,
    duration?: number,
  ): Promise<void>;
}

const RecorderContext = createContext<RecorderService | null>(null);

function useMediaRecorder() {
  const { toastError } = toastsWithIntl(["record"]);

  if (window.MediaRecorder) return window.MediaRecorder;
  else {
    toastError("record:NO_MEDIA_RECORDER", {
      id: "record.NO_MEDIA_RECORDER",
    });
    throw new Error("No MediaRecorder found");
  }
}

function useProvideRecorder(
  timerInterval = 1000,
  chunkDuration = 100,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  _maxUncompressedSize = 40 * 1024 * 1024,
): RecorderService {
  // HACK: On really old devices MediaRecorder does not exist :)
  const MediaRecorder = useMediaRecorder();
  const { confirm } = useConfirmationWithIntl(["record"]);
  const { t } = useTranslation(["record"]);
  const navigate = useNavigate();

  // Initialize ffmpeg
  const ffmpegLoadingRef = useRef<Promise<void> | null>(null);
  const unsupported = useRef<boolean>(false);
  useEffect(() => {
    if (unsupported.current) return;

    // If no support of SharedArrayBuffer
    try {
      const sab = new SharedArrayBuffer(128);
      if (sab === undefined)
        throw new Error("SharedArrayBuffer is not supported");
    } catch {
      confirm(
        "record:UNSUPPORTED_SHAREDARRAYBUFFER",
        () => {
          navigate("./..");
          return Promise.resolve();
        },
        undefined,
        {
          title: t("record:UNSUPPORTED_BROWSER"),
        },
      );

      unsupported.current = true;
      return;
    }

    if (!window.ffmpeg) {
      window.ffmpeg = createFFmpeg();
    }
    if (!window.ffmpeg.isLoaded()) {
      ffmpegLoadingRef.current = window.ffmpeg.load();
    }
  }, [confirm, navigate, t]);

  // `mediaLock` is used to not initialize the camera multiple times, this is the bridge between native and React
  const mediaLock = useRef<Promise<MediaStream> | null>(null);
  // `media` is the object that can be used in DOM / manipulations, it follow the React pattern
  const [media, setMedia] = useState<MediaStream | null>(null);

  const [recording, setRecording] = useState(false);
  const video = useRef(new Blob());
  const compressedVideo = useRef<Blob | null>(null);
  const [timer, setTimer] = useState<null | number>(null);
  const [timeElapsed, setTimeElapsed] = useState<null | number>(null);

  const [ffmpegPercent, setFFmpegPercent] = useState<number | null>(null);

  useEffect(() => {
    const interval = setInterval(() => {
      if (!timer) return setTimeElapsed(null);
      setTimeElapsed(Math.round((new Date().getTime() - timer) / 1000));
    }, timerInterval);

    return () => clearInterval(interval);
  }, [timer, timerInterval]);

  const videoFormat = useMemo(() => {
    let videoFormat: string;
    if (MediaRecorder.isTypeSupported("video/webm")) videoFormat = "webm";
    else if (MediaRecorder.isTypeSupported("video/mp4")) videoFormat = "mp4";
    else {
      logger.error("No supported video formats found");
      throw new Error("No supported video formats found");
    }
    return videoFormat;
  }, [MediaRecorder]);

  const mediaRecorder = useMemo(() => {
    if (!media) return null;

    // SOURCE: https://developer.mozilla.org/en-US/docs/Web/Media/Formats/Video_codecs
    // SOURCE: https://developer.mozilla.org/en-US/docs/Web/Media/Formats/Audio_codecs
    const mediaRecorder = new MediaRecorder(media, {
      mimeType: `video/${videoFormat}`,
    });

    return mediaRecorder;
  }, [videoFormat, media, MediaRecorder]);

  const startRecorder: RecorderService["startRecorder"] =
    useCallback(async () => {
      logger.info("asking to start recorder");
      if (unsupported.current) return null as any;

      // If the recorder is already initialized, just return the promise
      if (mediaLock.current) return mediaLock.current;

      // Else initialize and return the promise **instantly**
      // We do not want to await anything before setting the lock with a promise
      mediaLock.current = new Promise((resolve, reject) => {
        logger.info("initializing recorder");

        const constraints: MediaStreamConstraints = {
          audio: true,
          video: {
            height: {
              max: MAX_HEIGHT,
            },
            frameRate: FRAMERATE,
            facingMode: "user",
          },
        };

        // We need to wait for the user to allow access to the camera
        void navigator.mediaDevices.getUserMedia(constraints).then(
          (media) => {
            setMedia(media);
            resolve(media);
          },
          (e) => {
            if (e.message.includes("videoinput failed")) {
              toastError("record:record.NO_VIDEO", { id: "record.NO_VIDEO" });
            } else if (
              e.message.includes("not allowed") ||
              e.message.includes("Permission denied")
            ) {
              toastError("record:record.NOT_ALLOWED", {
                id: "record.NOT_ALLOWED",
              });
            } else if (e.message.includes("Could not start")) {
              toastError("record:record.COULD_NOT_START", {
                id: "record.COULD_NOT_START",
              });
            } else {
              logger.error(e);
            }
            reject(e);
          },
        );
      });

      return mediaLock.current;
    }, []);

  const stopRecorder: RecorderService["stopRecorder"] = useCallback(() => {
    if (!mediaLock.current) {
      logger.info("recorder was not started");
      setMedia(null);
    } else {
      const mediaPromise = mediaLock.current;
      mediaLock.current = null;

      logger.info("stopping recorder");
      void mediaPromise.then((media) => {
        media.getTracks().forEach((track) => track.stop());
        setMedia(null);
      });
    }
  }, []);

  const onStart: RecorderService["onStart"] = useCallback(
    () => mediaRecorder?.start(chunkDuration),
    [chunkDuration, mediaRecorder],
  );

  const onStop: RecorderService["onStop"] = useCallback(() => {
    return new Promise((resolve) => {
      function resolveOnStop() {
        // WEBM timings are broken on chrome so we need to fix it before playback
        const fixedVideoPromise =
          videoFormat === "webm"
            ? fixWebmDuration(video.current)
            : Promise.resolve(video.current);

        void fixedVideoPromise.then((fixedVideo) => {
          video.current = fixedVideo;
          resolve(fixedVideo);
        });
        mediaRecorder?.removeEventListener("stop", resolveOnStop);
      }
      stopRecorder();
      mediaRecorder?.addEventListener("stop", resolveOnStop);
      mediaRecorder?.stop();
      setTimer(null);
    });
  }, [mediaRecorder, stopRecorder, videoFormat]);

  useEffect(() => {
    if (!mediaRecorder) return;

    mediaRecorder.onstart = function () {
      logger.info("recorder started");
      video.current = new Blob();
      setTimer(new Date().getTime());
      setRecording(true);
    };
    mediaRecorder.onstop = function () {
      logger.info("recorder stopped");
      setRecording(false);
    };

    mediaRecorder.ondataavailable = function (blobEvent) {
      if (blobEvent.data && blobEvent.data.size > 0) {
        logger.debug("recorder received data status: ", this.state);

        // HACK: Required to concat multiple blobs
        const fileReader = new FileReader();
        fileReader.onload = function () {
          video.current = new Blob(
            [video.current, this.result as ArrayBuffer],
            {
              type: mediaRecorder.mimeType,
            },
          );
        };
        fileReader.readAsArrayBuffer(blobEvent.data);
      }
    };
  }, [mediaRecorder, onStop, videoFormat]);

  const onCompress: RecorderService["onCompress"] = useCallback(
    async (optionalVideo, optionalDuration) => {
      let duration: number | null = optionalDuration ?? null;
      const videoToUpload = optionalVideo ?? video.current;

      logger.info("waiting for ffmpeg");

      await ffmpegLoadingRef.current;

      logger.info("encoding video");
      const start = new Date().getTime();

      const ffmpeg = window.ffmpeg!;
      ffmpeg.setLogger(logger.debug);
      ffmpeg.setProgress((progress: any) => {
        if (progress.ratio === 0 && progress.duration > 0)
          duration = Math.max(progress.duration, optionalDuration ?? 0);
        logger.debug(duration, "progress", progress);

        const percent = Math.min(
          !!duration && !!progress.time
            ? Math.max(0, progress.time) / duration
            : Infinity,
          progress.ratio,
        );
        setFFmpegPercent(Math.max(0, Math.round(percent * 100)));
      });

      ffmpeg.FS(
        "writeFile",
        `video.${videoFormat}`,
        new Uint8Array(await videoToUpload.arrayBuffer()),
      );
      // https://trac.ffmpeg.org/wiki/Encode/H.264
      await ffmpeg.run(
        "-y",
        "-i",
        `video.${videoFormat}`,
        "-acodec",
        "aac",
        "-vcodec",
        "libx264",
        // Faster to encode/compress => Heavier in weight
        // Slower to encode/compress => Lighter in weight
        "-preset",
        // videoToUpload.size > maxUncompressedSize ? "superfast" : "ultrafast",
        "ultrafast",
        // Framerate in FPS
        "-r",
        "24",
        "-vf",
        // iw > ih => landscape
        // ih > iw => portrait
        // -2 => keep ratio in base 2 (! Required !)
        // \\ required to escape ','
        `scale=-2:min(${MAX_HEIGHT}\\,ih)`,
        "-crf",
        // Quality of the video between 0 and 51
        // 0 is lossless
        // 23 is default
        // 51 is worst
        // Sane range is 17-28
        // Visually lossless is 17-18
        "26",
        // Support less options but a better compatibility with older devices
        "-profile:v",
        "baseline",
        // Flag to move the metadata to the front of the video
        "-movflags",
        "+faststart",
        "video-export.mp4",
      );
      logger.info(
        `Encoded in ${Math.ceil((new Date().getTime() - start) / 100) / 10}s`,
      );
      setFFmpegPercent(null);

      const data = ffmpeg.FS("readFile", "video-export.mp4");
      if (data.length === 0) throw new Error("Failed to encode video");

      // Cleanup temp files
      ffmpeg.FS("unlink", `video.${videoFormat}`);
      ffmpeg.FS("unlink", "video-export.mp4");

      compressedVideo.current = new Blob([data], {
        type: "video/mp4",
      });

      return compressedVideo.current;
    },
    [videoFormat],
  );

  const onUpload: RecorderService["onUpload"] = useCallback(
    async (getSignedUploadLink, optionalVideo, optionalDuration) => {
      let videoToUpload: Blob;
      if (compressedVideo.current) {
        videoToUpload = compressedVideo.current;
      } else {
        videoToUpload = await onCompress(optionalVideo, optionalDuration);
      }

      const unplayableBlob = new Blob([
        new Uint8Array(await videoToUpload.arrayBuffer()).reverse(),
      ]);

      const uploadLink = await getSignedUploadLink();
      const result = await fetch(uploadLink, {
        method: "PUT",
        body: unplayableBlob,
        headers: {
          "Content-Type": "binary/octet-stream",
        },
      });
      logger.info(result);

      logger.info(
        `Uploading a ${Math.ceil((videoToUpload.size / 1024 / 1024) * 10) / 10}MB file`,
      );
    },
    [onCompress],
  );

  const onExport: RecorderService["onExport"] = useCallback(
    (name) => {
      const blobUrl = URL.createObjectURL(video.current);
      downloadURL(blobUrl, `${name}.mp4`);
      logger.info(
        `Downloading a ${
          Math.ceil((video.current.size / 1024 / 1024) * 10) / 10
        }MB file`,
      );
      URL.revokeObjectURL(blobUrl);
    },
    [video],
  );

  return {
    isRecorderReady: useMemo(() => !!media, [media]),
    timeElapsed,
    recording,
    ffmpegPercent,
    startRecorder,
    stopRecorder,
    onStop,
    onStart,
    onExport,
    onUpload,
    onCompress,
  };
}

export function ProvideRecorder({
  children,
  chunkDuration,
  timerInterval,
  maxUncompressedSize,
}: PropsWithChildren<{
  timerInterval?: number;
  chunkDuration?: number;
  maxUncompressedSize?: number;
}>) {
  const recorderProvider = useProvideRecorder(
    timerInterval,
    chunkDuration,
    maxUncompressedSize,
  );

  // On unmount stop recorder
  useEffect(() => {
    return () => recorderProvider.stopRecorder();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!navigator.mediaDevices) return "Unsupported navigator";

  return (
    <RecorderContext.Provider value={recorderProvider}>
      {children}
    </RecorderContext.Provider>
  );
}

export const VideoPreview = () => {
  const { startRecorder } = useRecorder()!;

  const videoPlayback = createRef<HTMLVideoElement>();

  useEffect(() => {
    if (!videoPlayback.current?.srcObject)
      void startRecorder().then((m) => {
        if (videoPlayback.current) videoPlayback.current.srcObject = m;
      });
  }, [startRecorder, videoPlayback]);

  return (
    <video
      className="videoplayer"
      playsInline
      autoPlay
      muted
      ref={videoPlayback}
      controlsList="nodownload"
    />
  );
};

// eslint-disable-next-line react-refresh/only-export-components
export function useRecorder() {
  return useContext(RecorderContext);
}
