import React from "react";
import ReactDOM from "react-dom/client";

import "./styles/main.less";
import "anylogger-loglevel";
import "./internationalization/i18n.ts";
import "./forms/validation/yup-init.ts";

import router from "./router/Router.tsx";
import { ProvideToasts } from "./services/toastService.tsx";
import { ProvideConfirmation } from "./components/ConfirmationDialog.tsx";
import {
  RouterProvider,
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from "react-router-dom";
import * as Sentry from "@sentry/react";

// TODO: Install Sentry on prod

Sentry.init({
  dsn: "https://a5ddbf3cbf033541f184c5024a9302ea@sentry.simplx.fr/3",
  // This enables automatic instrumentation (highly recommended)
  // If you only want to use custom instrumentation:
  // * Remove the BrowserTracing integration
  // * add Sentry.addTracingExtensions() above your Sentry.init() call
  integrations: [
    Sentry.browserTracingIntegration(),
    // Or, if you are using react router, use the appropriate integration
    // See docs for support for different versions of react router
    // https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/react-router/
    Sentry.reactRouterV6BrowserTracingIntegration({
      useEffect: React.useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes,
    }),
  ],

  // For finer control of sent transactions you can adjust this value, or
  // use tracesSampler
  tracesSampleRate: 1.0,

  // Set tracePropagationTargets to control for which URLs distributed tracing should be enabled
  environment: import.meta.env.PROD ? "production" : "local",
});

ReactDOM.createRoot(document.getElementById("root")!).render(
  <React.StrictMode>
    <ProvideToasts>
      <ProvideConfirmation>
        {/* NOTE: Place here around the Router all the Providers that need to persist during navigation */}
        <RouterProvider router={router} />
      </ProvideConfirmation>
    </ProvideToasts>
  </React.StrictMode>,
);
