import { RouteObject } from "react-router-dom";
import { authService } from "../services/auth/authService";
import { redirectOnCondition } from "./routerHelpers";
import PrivateArea from "../views/PrivateArea";
import { candidatesRoutes } from "./candidateRoutes";
import AccountPage from "../views/account/AccountPage";
import { userService } from "../services/user/userService";
import { organizationRoutes } from "./organizationRoutes";

const { isLoggedIn } = authService();
const { getLoggedUser } = userService();

export const privateRoutes: RouteObject = {
  path: "",
  id: "logged-user",
  async loader() {
    return {
      loggedUser: isLoggedIn() ? await getLoggedUser() : null,
    };
  },
  children: redirectOnCondition(
    [
      {
        path: "candidate",
        element: <PrivateArea />,
        children: [candidatesRoutes],
      },
      {
        path: "account",
        element: <PrivateArea />,
        children: [
          {
            path: "",
            element: <AccountPage />,
          },
        ],
      },
      {
        path: "organization",
        children: [organizationRoutes],
      },
    ],
    () => !isLoggedIn(),
    "/login",
  ),
};
