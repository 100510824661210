import { PromiseResult } from "./type";

export async function logElapsed(
  label: string,
  promise: Promise<unknown>,
  // eslint-disable-next-line no-console
  logger = console.log,
) {
  const start = Date.now();
  return promise.finally(() => {
    const elapsed = Date.now() - start;
    logger(`'${label}' took ${elapsed}ms`);
  });
}

// eslint-disable-next-line no-console
export function logElapsedBuilder(logger = console.log) {
  return (label: string, promise: Promise<unknown>) =>
    logElapsed(label, promise, logger);
}

export function promiseThenOpenUrl<
  P2,
  P1Inner extends [string, P2] | string,
  R = P1Inner extends string ? void : P2,
>(fn: () => Promise<P1Inner>): Promise<R> {
  const window_handle = window.open("about:blank");
  return fn().then(
    (arr: string | [string, P2]) => {
      let url = "";
      if (typeof arr === "string") url = arr;
      else url = arr[0];

      if (window_handle) window_handle.location.href = url;

      if (typeof arr !== "string") return arr[1] as unknown as R;
      else return Promise.resolve() as unknown as R;
    },
    (e) => {
      if (window_handle) window_handle.close();
      return Promise.reject(e);
    },
  );
}

export function setTimeoutPromise(delay: number): Promise<void> {
  return new Promise((resolve) => setTimeout(resolve, delay));
}

export async function promiseAllObject<
  K extends string | number | symbol,
  O extends Record<K, unknown>,
>(o: O): Promise<{ [k in keyof O]: PromiseResult<O[k]> }> {
  return Object.fromEntries(
    await Promise.all(
      Object.entries(o).map(async ([k, v]) => [k, await Promise.resolve(v)]),
    ),
  );
}

// NOTE: Trigger react Suspense component (till `use` hook is stabilised)
export interface Suspended<T> {
  read: () => T;
  readWrapError: () => T | { error: any };
}
export function wrapPromiseWithSuspender<T>(promise: Promise<T>) {
  let status = "pending";
  let response: T;

  const suspender = promise.then(
    (res) => {
      status = "success";
      response = res;
    },
    (err) => {
      status = "error";
      response = err;
    },
  );
  const read = () => {
    switch (status) {
      case "pending":
        throw suspender;
      case "error":
        throw response;
      default:
        return response;
    }
  };

  const readWrapError = () => {
    switch (status) {
      case "pending":
        throw suspender;
      case "error":
        return { error: response };
      default:
        return response;
    }
  };

  return { read, readWrapError };
}
