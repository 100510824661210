import { Navigate, RouteObject } from "react-router-dom";
import OrganizationDashboardPage from "../views/organization/OrganizationDashboardPage";
import OrganizationCandidatesProfilesPage from "../views/organization/OrganizationCandidatesProfilesPage";
import { userService } from "../services/user/userService";
import { getOrganizationRequest } from "../services/organization/organizationApi";
import { redirectOnCondition } from "./routerHelpers";
import ContactPage from "../views/contact/ContactPage";
import OrganizationCompanyCapsules from "../views/organization/OrganizationCompanyCapsules";
import { promiseAllObject } from "../react-helpers/promise";
import {
  getAllCapsulesRequest,
  getCapsuleRequest,
} from "../services/capsule/capsuleApi";
import CapsulePage from "../views/organization/capsule/CapsulePage";
import RecordPage from "../views/record/RecordPage";
import { fetchRetriable } from "../react-helpers/fetch";
import SubscriptionPage from "../views/organization/pro/SubscriptionPage";
import UsersPage from "../views/organization/pro/UsersPage";
import OrganizationPage from "../views/organization/pro/OrganizationPage";
import { subscriptionService } from "../services/subscription/subscriptionService";
import OrganizationRegisterPaymentMethodPage from "../views/organization/pro/OrganizationRegisterPaymentMethodPage";
import OrganizationArea from "../views/OrganizationArea";
import AccountPage from "../views/account/AccountPage";

const { getLoggedUser } = userService();
const { getPlans } = subscriptionService();

export const organizationRoutes: RouteObject = {
  path: "",
  children: redirectOnCondition(
    [
      {
        path: "",
        id: "organization",
        async loader() {
          const loggedUser = await getLoggedUser();

          const organization = await getOrganizationRequest(
            loggedUser.organizationId!,
          );

          return {
            organization,
          };
        },
        element: <OrganizationArea />,
        children: [
          {
            index: true,
            element: <OrganizationDashboardPage />,
          },
          {
            path: "candidates",
            element: <OrganizationCandidatesProfilesPage />,
          },
          {
            path: "capsules",
            element: <OrganizationCompanyCapsules />,
            async loader() {
              return promiseAllObject({
                capsules: getAllCapsulesRequest(),
              });
            },
          },
          {
            path: "capsules/:capsuleId",
            element: <CapsulePage />,
            async loader({ params }) {
              const capsule = await getCapsuleRequest(Number(params.capsuleId));
              let video = null;

              if (capsule.videoLink) {
                video = (async (videoLink) => {
                  const data = await fetchRetriable(videoLink, {
                    method: "GET",
                    retry: 3,
                  });
                  return new Blob(
                    [new Uint8Array(await data.arrayBuffer()).reverse()],
                    {
                      type: "video/mp4",
                    },
                  );
                })(capsule.videoLink);
              }

              return {
                capsule,
                video,
              };
            },
          },
          {
            path: "capsules/:capsuleId/record",
            loader: async ({ params }) => {
              return await promiseAllObject({
                capsule: getCapsuleRequest(Number(params.capsuleId)),
              });
            },
            errorElement: <Navigate to="./.." />,
            element: <RecordPage type="capsule" />,
          },
          {
            path: "contact",
            element: <ContactPage />,
          },
          {
            path: "account",
            element: <AccountPage />,
          },
          {
            path: "pro",
            children: redirectOnCondition(
              [
                {
                  path: "account",
                  element: <OrganizationPage />,
                },
                {
                  path: "subscription",
                  async loader() {
                    return promiseAllObject({
                      plans: getPlans(),
                    });
                  },
                  element: <SubscriptionPage />,
                },
                {
                  path: "users",
                  element: <UsersPage />,
                },
              ],
              async () => {
                return getLoggedUser().then(
                  (u) => !u.adminOrga,
                  () => true,
                );
              },
              "/organization",
            ),
          },
        ],
      },
      {
        path: "",
        children: [
          {
            path: "pro/subscription/payment-method",
            async loader() {
              const loggedUser = await getLoggedUser();

              return promiseAllObject({
                plans: getPlans(),
                organization: getOrganizationRequest(
                  loggedUser.organizationId!,
                ),
              });
            },
            element: <OrganizationRegisterPaymentMethodPage />,
          },
        ],
      },
    ],
    async () => {
      const loggedUser = await getLoggedUser();
      return !loggedUser.organizationId;
    },
    "/candidate",
  ),
};
