import { Field } from "formik";
import { ArrayElement, NonUndefined } from "../../react-helpers/type";
import { SxFieldProps } from "./SxField";

interface SxRadioProps {
  name: string;
  radio: ArrayElement<NonUndefined<SxFieldProps["radios"]>>;
  isDisabled?: boolean;
}

const SxRadio = ({
  name,
  radio: { value, label },
  isDisabled,
}: SxRadioProps) => {
  return (
    <label className="radio-btn">
      <Field type="radio" name={name} value={value} disabled={isDisabled} />
      {label}
    </label>
  );
};

export default SxRadio;
