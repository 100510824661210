import { Link } from "react-router-dom";
import { Profile } from "../../../services/profile/profileModel";
import { useMemo } from "react";
import { cx } from "../../../react-helpers/css";
import { useTranslation } from "react-i18next";
import {
  useCountries,
  useLangs,
} from "../../../services/profile/profileService";
import { useProgressiveImage } from "../../../services/imageService";
import SubmitButton from "../../../components/SubmitButton";
import { Tooltip } from "react-tooltip";

const ProfileCard = ({
  profile,
  isArchived,
  onArchive,
  onUnarchive,
  isFavorited,
  onFavorite,
  onUnfavorite,
  onDelete,
}: {
  profile: Profile;
  isArchived?: boolean;
  onArchive?: () => void;
  onUnarchive?: () => void;
  isFavorited?: boolean;
  onFavorite?: () => Promise<void> | void;
  onUnfavorite?: () => Promise<void> | void;
  onDelete?: () => Promise<void> | void;
}) => {
  const { t } = useTranslation();
  const countries = useCountries();
  const langs = useLangs();
  const country = useMemo(
    () => countries.filter((c) => c[0] === profile.country)[0][1],
    [profile.country, countries],
  );
  const language = useMemo(
    () => langs.filter((c) => c[0] === profile.lang)[0][1],
    [profile.lang, langs],
  );

  const thumbnail = useProgressiveImage(
    profile.thumbnail ?? profile.thumbnailUrl,
  );

  return (
    <Link
      className={cx([
        "profile-card --block",
        !!profile.disabledDate && "disabled",
        !!profile.user && "--org",
      ])}
      to={
        profile.user
          ? `/profile-share/${profile.uid}`
          : `../profiles/${profile.id}`
      }
    >
      {!!profile.user && (
        <div className="card--actions">
          {!isArchived ? (
            <button
              className="btn--archive"
              data-tooltip-id={`archive-${profile.uid}`}
              data-tooltip-content={t("candidate:tooltips.ARCHIVE")}
              onClick={(e) => {
                e.preventDefault();
                onArchive?.();
              }}
            />
          ) : (
            <button
              className="btn--unarchive"
              data-tooltip-id={`unarchive-${profile.uid}`}
              data-tooltip-content={t("candidate:tooltips.UNARCHIVE")}
              onClick={(e) => {
                e.preventDefault();
                onUnarchive?.();
              }}
            />
          )}

          {isFavorited !== undefined &&
            (!isFavorited ? (
              <SubmitButton
                type="button"
                className="btn--favorite"
                data-tooltip-id={`favorite-${profile.uid}`}
                data-tooltip-content={t("candidate:tooltips.FAVORITE")}
                onClick={(e) => {
                  e.preventDefault();
                  return onFavorite?.();
                }}
              />
            ) : (
              <SubmitButton
                type="button"
                data-tooltip-id={`unfavorite-${profile.uid}`}
                data-tooltip-content={t("candidate:tooltips.UNFAVORITE")}
                className="btn--unfavorite"
                onClick={(e) => {
                  e.preventDefault();
                  return onUnfavorite?.();
                }}
              />
            ))}

          {!!onDelete && (
            <SubmitButton
              type="button"
              className="btn--delete"
              data-tooltip-id={`delete-${profile.uid}`}
              data-tooltip-content={t("candidate:tooltips.DELETE")}
              onClick={(e) => {
                e.preventDefault();
                return onDelete?.();
              }}
            />
          )}
        </div>
      )}
      <div className="card_image_container">
        <canvas
          className="card_image_bg"
          width="16"
          height="9"
          style={{
            backgroundImage: thumbnail ? `url(${thumbnail})` : undefined,
          }}
        ></canvas>
        <canvas
          className="card_image"
          style={{
            backgroundImage: thumbnail ? `url(${thumbnail})` : undefined,
          }}
        />
      </div>

      <div className="card_body">
        <p className="profile_name">
          {profile.user
            ? `${profile.user?.firstname} ${profile.user?.lastname}`
            : profile.name}
        </p>
        <div className="profile_flags">
          <p className="chip">{country}</p>
          <p className="chip">{language}</p>
        </div>
      </div>
      {!!profile.disabledDate && (
        <div className="disabled-overlay">
          {t("candidate:profile.card.DISABLED")}
        </div>
      )}
      <Tooltip id={`archive-${profile.uid}`} />
      <Tooltip id={`unarchive-${profile.uid}`} />
      <Tooltip id={`favorite-${profile.uid}`} />
      <Tooltip id={`unfavorite-${profile.uid}`} />
      <Tooltip id={`delete-${profile.uid}`} />
    </Link>
  );
};

export default ProfileCard;
