import { NavLink, Outlet, useRouteLoaderData } from "react-router-dom";
import CommonHeader from "../components/CommonHeader";
import CommonFooter from "../components/CommonFooter";
import { User } from "../services/user/userModel";
import PrivateSidebar from "../components/PrivateSidebar";
import { useTranslation } from "react-i18next";
import { useCallback, useState } from "react";
import { cx } from "../react-helpers/css";

const OrganizationArea = () => {
  const { loggedUser } = useRouteLoaderData("logged-user") as {
    loggedUser?: User;
  };
  const { t } = useTranslation(["common", "organization"]);
  const [displayMobileMenu, setDisplayMobileMenu] = useState(false);

  const onClose = useCallback(() => setDisplayMobileMenu(false), []);

  return (
    <>
      <CommonHeader
        loggedUser={loggedUser}
        setDisplayMobileMenu={setDisplayMobileMenu}
      />

      <div
        className={cx(["app-layout --h", displayMobileMenu && "--menu-opened"])}
      >
        {loggedUser && (
          <PrivateSidebar user={loggedUser} onCloseMenu={onClose}>
            <nav className="container">
              <div className="cblock">
                <NavLink
                  to="/organization"
                  end
                  className="link"
                  onClick={onClose}
                >
                  {t("common:sidebar.HOME")}
                </NavLink>
              </div>
              <div className="cblock">
                <NavLink
                  className="link"
                  to={"/organization/account"}
                  onClick={onClose}
                >
                  {t("common:header.MY_ACCOUNT")}
                </NavLink>
              </div>
              <div className="cblock">
                <NavLink
                  to="/organization/candidates"
                  className="link"
                  onClick={onClose}
                >
                  {t("common:header.MY_CANDIDATES")}
                </NavLink>
              </div>
              <div className="cblock">
                <NavLink
                  to="/organization/capsules"
                  className="link"
                  onClick={onClose}
                >
                  {t("common:header.MY_LISTINGS")}
                </NavLink>
              </div>
              {loggedUser.adminOrga && (
                <div className="cblock">
                  <div className="cblock">
                    {t("organization:ADMINISTRATION")}
                  </div>
                  <div className="container--s cblock --bordered-left">
                    <div className="cblock">
                      <NavLink
                        to="/organization/pro/account"
                        className="link"
                        onClick={onClose}
                      >
                        {t("organization:pro-account.PAGE_TITLE")}
                      </NavLink>
                    </div>
                    <div className="cblock">
                      <NavLink
                        to="/organization/pro/subscription"
                        className="link"
                        onClick={onClose}
                      >
                        {t("organization:subscription.PAGE_TITLE")}
                      </NavLink>
                    </div>
                    <div className="cblock">
                      <NavLink
                        to="/organization/pro/users"
                        className="link"
                        onClick={onClose}
                      >
                        {t("organization:users.PAGE_TITLE")}
                      </NavLink>
                    </div>
                  </div>
                </div>
              )}
            </nav>
          </PrivateSidebar>
        )}
        <div className={"layout_content --bg--organization"}>
          <Outlet />
          <CommonFooter loggedUser={loggedUser} />
        </div>
      </div>
    </>
  );
};

export default OrganizationArea;
