import { NavLink } from "react-router-dom";
import { User } from "../services/user/userModel";
import { cx } from "../react-helpers/css";
import LanguageSwitcher from "./LanguageSwitcher";
import { useTranslation } from "react-i18next";

const CommonFooter = ({ loggedUser }: { loggedUser?: User }) => {
  const { t } = useTranslation(["common", "links"]);
  return (
    <div className={cx(["footer", loggedUser && "--private"])}>
      {
        //TODO: Replace links with 2min-max document links
      }
      <a
        className="link --light"
        href="https://www.2minutesmax.com/"
        target="_blank"
        rel="noreferrer"
      >
        {t("common:footer.WHO_ARE_WE")}
      </a>
      <a
        className="link --light"
        href={t("links:LEGAL_NOTICE")}
        target="_blank"
        rel="noreferrer"
      >
        {t("common:footer.LEGAL_NOTICE")}
      </a>
      <a
        className="link --light"
        href={t("links:TERMS_AND_CONDITIONS")}
        target="_blank"
        rel="noreferrer"
      >
        {t("common:footer.CGV_CGU")}
      </a>
      <NavLink
        className="link --light"
        to={
          !loggedUser
            ? "/contact"
            : !loggedUser.organizationId
              ? "/candidate/contact"
              : "/organization/contact"
        }
      >
        {t("common:footer.CONTACT")}
      </NavLink>
      <LanguageSwitcher className="--light" />
    </div>
  );
};

export default CommonFooter;
