import { useMemo } from "react";
import useYupField from "../validation/useYupField";
import { ErrorMessage, Field, FieldProps, useField } from "formik";
import { AnySchema } from "yup";
import { loggerBuilder } from "../../services/logger";
import SxRadio from "./SxRadio";
import SxCheckbox from "./SxCheckbox";
import { randomUUID } from "../../react-helpers/crypto";
import SxPassword from "./SxPassword";
import { cx } from "../../react-helpers/css";

const logger = loggerBuilder("forms-sxfield");

// NOTE You can add here props that will be added to each SxField component
export interface SxFieldProps {
  // The name of the field in Formik and Yup
  name: string;
  // The label of the field default the the Yup label if present
  label?: string | null;
  // The placeholder for fields that support it
  placeholder?: string;
  // Follow the Formik spec for as, change the type of field. Default to inferred from yup
  as?: `${FieldType}`;
  // Hide errors under the field
  noErrors?: boolean;
  // When multiple errors are present in the validation only show the first one
  firstErrorOnly?: boolean;
  children?: ((props: FieldProps<any>) => React.ReactNode) | React.ReactNode;
  radios?: {
    value: string | number;
    label: string;
  }[];
  checkboxes?: { value: string | number; label: string }[];
  disabled?: boolean;
  noBlock?: boolean;
}

enum FieldType {
  Text = "text",
  Email = "email",
  Password = "password",
  Number = "number",
  Textarea = "textarea",
  Select = "select",
  Date = "date",
  DateTime = "dateTime",
  Radio = "radio",
  Checkbox = "checkbox",
  // NOTE: Add new custom field types here
}
const FORMIK_AS_FIELDS: (FieldType | null)[] = [
  FieldType.Select,
  FieldType.Textarea,
];
const FORMIK_TYPE_FIELDS: (FieldType | null)[] = [
  FieldType.Text,
  FieldType.Number,
  FieldType.Email,
  FieldType.Date,
];

function getFieldType(fieldSchema: AnySchema): FieldType | null {
  if (fieldSchema.tests.some((t) => t.OPTIONS?.name === "email" ?? false)) {
    return FieldType.Email;
  }
  if (fieldSchema.meta()?.password) {
    return FieldType.Password;
  }
  if (fieldSchema.meta()?.multiline) {
    return FieldType.Textarea;
  }
  if (fieldSchema.type === "number") {
    return FieldType.Number;
  }
  if (fieldSchema.type === "date") {
    return FieldType.Date;
  }
  if (fieldSchema.type === "string") {
    return FieldType.Text;
  }
  if (fieldSchema.type === "radio") {
    return FieldType.Radio;
  }

  // NOTE: Add new field type inference from Yup here before the default return
  logger.info("No field type detected from Yup");
  return null;
}

function SxField({
  name,
  label,
  as,
  children,
  noErrors,
  firstErrorOnly,
  placeholder,
  radios,
  checkboxes,
  disabled,
  noBlock,
}: SxFieldProps) {
  const [field] = useField(name);
  const fieldSchema = useYupField(name);
  const asType: FieldType | null = useMemo(() => {
    if (as) return (as as FieldType) ?? null;
    return fieldSchema ? getFieldType(fieldSchema) : null;
  }, [as, fieldSchema]);

  const isRequired = useMemo(
    () => !fieldSchema?.describe().optional ?? false,
    [fieldSchema],
  );

  const isDisabled = useMemo(
    () => disabled ?? fieldSchema?.meta()?.disabled ?? false,
    [disabled, fieldSchema],
  );

  if (fieldSchema) {
    if (fieldSchema.meta()?.notVisible) return null;
  }

  if (asType === null) logger.warn(`No field type found for ${name}`);
  return (
    <div className={cx([noBlock !== true && "form-block"])}>
      {label !== null && (label ?? fieldSchema?.spec.label) ? (
        <label htmlFor={name} className="field-label">
          {label ?? fieldSchema?.spec.label}
          {isRequired && "*"}
        </label>
      ) : null}
      {[...FORMIK_AS_FIELDS, ...FORMIK_TYPE_FIELDS].includes(asType) && (
        <Field
          name={name}
          as={FORMIK_AS_FIELDS.includes(asType) ? asType : undefined}
          type={FORMIK_TYPE_FIELDS.includes(asType) ? asType : undefined}
          disabled={isDisabled}
          placeholder={placeholder}
          value={field.value ?? ""}
          className={asType === FieldType.Textarea ? "textarea" : "input"}
        >
          {children}
        </Field>
      )}
      {asType === FieldType.Password && (
        <SxPassword
          name={name}
          showTogglePassword
          disabled={isDisabled}
          placeholder={placeholder}
        />
      )}
      {asType === FieldType.Checkbox && (
        <div className="checkboxes-group" role="group">
          {(checkboxes ?? []).map((checkbox) => (
            <SxCheckbox
              key={checkbox.value}
              name={name}
              checkbox={checkbox}
              isDisabled={isDisabled}
            />
          ))}
        </div>
      )}
      {/* NOTE: If you have a custom field add it here */}
      {asType === FieldType.Radio && (
        <div className="radio-group" role="group">
          {(radios ?? []).map((radio) => (
            <SxRadio
              key={radio.value}
              name={name}
              radio={radio}
              isDisabled={isDisabled}
            />
          ))}
        </div>
      )}
      {!noErrors && (
        <ErrorMessage
          name={name}
          render={(err: any) => (
            <div>
              {(Array.isArray(err)
                ? firstErrorOnly
                  ? err.slice(0, 1)
                  : err
                : [{ type: randomUUID(), message: err }]
              ).map((err) => (
                <div key={err.type} className="field-error">
                  {err.message}
                </div>
              ))}
            </div>
          )}
        />
      )}
    </div>
  );
}

export default SxField;
