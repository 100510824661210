import { loggerBuilder } from "../services/logger";

const LOWER_A_CHARCODE = "a".charCodeAt(0);
export const LOWER_ALPHA_CHARCODES_LIST = [...Array(26)].map(
  (_, idx) => LOWER_A_CHARCODE + idx,
);
const UPPER_A_CHARCODE = "A".charCodeAt(0);
export const UPPER_ALPHA_CHARCODES_LIST = [...Array(26)].map(
  (_, idx) => UPPER_A_CHARCODE + idx,
);
const ZERO_CHARCODE = "0".charCodeAt(0);
export const NUMS_CHARCODES_LIST = [...Array(10)].map(
  (_, idx) => ZERO_CHARCODE + idx,
);

const logger = loggerBuilder("crypto");

export function getRandomChars(
  count: number,
  possibleChars = String.fromCharCode(
    ...[
      ...LOWER_ALPHA_CHARCODES_LIST,
      ...UPPER_ALPHA_CHARCODES_LIST,
      ...NUMS_CHARCODES_LIST,
    ],
  ),
) {
  return [...Array(count)]
    .map(() => possibleChars[Math.floor(Math.random() * possibleChars.length)])
    .join("");
}

export function randomUUID(): string {
  const uuidCharcodes = String.fromCharCode(
    ...[...LOWER_ALPHA_CHARCODES_LIST, ...NUMS_CHARCODES_LIST],
  );
  const c = crypto as any;
  // eslint-disable-next-line no-restricted-syntax
  if (c.randomUUID) return c.randomUUID();
  else {
    logger.warn(
      "Using shim for crypto.randomUUID, it's pseudo-random and should not be used for secure or mathematical operations.",
    );
    return `${getRandomChars(8, uuidCharcodes)}-${getRandomChars(
      4,
      uuidCharcodes,
    )}-${getRandomChars(4, uuidCharcodes)}-${getRandomChars(
      4,
      uuidCharcodes,
    )}-${getRandomChars(12, uuidCharcodes)}`;
  }
}
