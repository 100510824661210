import { ArrayElement } from "../../react-helpers/type";
import { Profile } from "../profile/profileModel";
import { Subscription } from "../subscription/subscriptionModel";
import { User } from "../user/userModel";

export interface Organization {
  id: number;
  legalName: string;
  slug: string;
  subscriptionStartDate: string | null;
  subscriptionEndDate: string | null;
  maxCapsulesCount: number;
  maxMembersCount: number;
  phoneNumber: string | null;
  email: string | null;
  addressLine1: string | null;
  addressLine2: string | null;
  postalCode: string | null;
  city: string | null;
  country: string | null;
  subscriptions?: Subscription[];
  sharedProfiles: {
    createdAt: string;
    archivedAt: string | null;
    favoritedAt: string | null;
    profileId: Profile["id"];
    profile: Profile;
  }[];
  stripeCustomer?: {
    name: string;
    card?: {
      last4: string;
      brand: string;
      display_brand: string;
      exp_year: number;
      exp_month: number;
    };
    sepa?: {
      bank_code: string;
      branch_code: string;
      country: string;
      last4: string;
    };
  };
  users?: User[];
  invitations?: Invitation[];
}

export type NewOrganizationData = {
  [k in keyof Pick<
    Organization,
    | "legalName"
    | "phoneNumber"
    | "email"
    | "addressLine1"
    | "addressLine2"
    | "postalCode"
    | "city"
    | "country"
  >]: NonNullable<Organization[k]>;
};

export interface Invitation {
  id: number;
  uid: string;
  email: string;
  firstname: string;
  lastname: string;
  membershipType: ArrayElement<typeof INVITATION_MEMBERSHIP_TYPES>;
  disabledDate?: string | null;
  organizationId: Organization["id"];
  organization?: Organization;
  userId?: User["id"] | null;
  user?: User | null;
}

export const INVITATION_MEMBERSHIP_TYPES = [
  "ADMIN",
  "MEMBER",
  "GUEST",
] as const;
