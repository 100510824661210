import { useRouteLoaderData } from "react-router-dom";
import { Organization } from "../../services/organization/organizationModel";
import ProfileCard from "../candidate/profile/ProfileCardComponent";

import { useSearch } from "../../react-helpers/array";
import { useEffect, useMemo, useState } from "react";
import { cx } from "../../react-helpers/css";
import { organizationService } from "../../services/organization/organizationService";
import { useConfirmationWithIntl } from "../../components/ConfirmationDialog";
import { useTranslation } from "react-i18next";
import useReload from "../../hooks/useReload";
import { ReturnButton } from "../../components/ReturnButton";

enum Tab {
  Shared,
  Archived,
  Favorited,
}

const OrganizationCandidatesProfilesPage = () => {
  const { t } = useTranslation();
  const { organization } = useRouteLoaderData("organization") as {
    organization: Organization;
  };
  const {
    archiveSharedProfile,
    unarchiveSharedProfile,
    favoriteSharedProfile,
    unfavoriteSharedProfile,
    deleteSharedProfile,
  } = organizationService();

  const { confirm } = useConfirmationWithIntl(["organization"]);
  const reload = useReload();

  const [searchedProfiles, setSearch] = useSearch(
    organization.sharedProfiles,
    (e) => ({
      profile: {
        user: {
          lastname: e.profile.user?.lastname,
          firstname: e.profile.user?.firstname,
        },
      },
    }),
  );

  const sharedProfiles = useMemo(() => {
    return searchedProfiles
      .filter((p) => p.archivedAt === null)
      .sort((a, b) => (new Date(a.createdAt) > new Date(b.createdAt) ? 1 : -1))
      .sort((a) => {
        return a.profile.disabledDate ? 1 : -1;
      });
  }, [searchedProfiles]);

  const archivedProfiles = useMemo(() => {
    return searchedProfiles
      .filter((p) => p.archivedAt !== null)
      .sort((a, b) => (new Date(a.createdAt) > new Date(b.createdAt) ? 1 : -1))
      .sort((a) => {
        return a.profile.disabledDate ? 1 : -1;
      });
  }, [searchedProfiles]);

  const favoritedProfiles = useMemo(() => {
    return searchedProfiles
      .filter((p) => p.favoritedAt !== null && p.archivedAt === null)
      .sort((a, b) => (new Date(a.createdAt) > new Date(b.createdAt) ? 1 : -1))
      .sort((a) => {
        // put disabled profiles at the end of the list
        return a.profile.disabledDate ? 1 : -1;
      });
  }, [searchedProfiles]);

  const [currentTab, setCurrentTab] = useState(Tab.Shared);

  useEffect(() => {
    if (currentTab === Tab.Archived && archivedProfiles.length === 0) {
      setCurrentTab(Tab.Shared);
    }
  }, [archivedProfiles.length, currentTab]);

  useEffect(() => {
    if (currentTab === Tab.Favorited && favoritedProfiles.length === 0) {
      setCurrentTab(Tab.Shared);
    }
  }, [favoritedProfiles.length, currentTab]);

  return (
    <div className="layout_content --bg--organization">
      <div className="page-content container --wrap">
        <div>
          <ReturnButton forcedUrl="/organization">
            {t("organization:RETURN_TO_RECRUITER_DASHBOARD")}
          </ReturnButton>
        </div>

        <div className="page_head lblock">
          <h2 className="section_title">
            {t("organization:candidates-profiles.MY_CANDIDATE_PROFILES")}
          </h2>
        </div>

        {(archivedProfiles.length > 0 || favoritedProfiles.length > 0) && (
          <div className="lblock tabs">
            <button
              className={cx([
                "tab_item",
                currentTab === Tab.Shared && "active",
              ])}
              onClick={() => setCurrentTab(Tab.Shared)}
            >
              {t("organization:candidates-profiles.SHARED_PROFILES")}
            </button>
            {favoritedProfiles.length > 0 && (
              <button
                className={cx([
                  "tab_item",
                  currentTab === Tab.Favorited && "active",
                ])}
                onClick={() => setCurrentTab(Tab.Favorited)}
              >
                {t("organization:candidates-profiles.FAVORITED_PROFILES")}
              </button>
            )}
            {archivedProfiles.length > 0 && (
              <button
                className={cx([
                  "tab_item",
                  currentTab === Tab.Archived && "active",
                ])}
                onClick={() => setCurrentTab(Tab.Archived)}
              >
                {t("organization:candidates-profiles.ARCHIVED_PROFILES")}
              </button>
            )}
          </div>
        )}

        {organization.sharedProfiles.length > 0 && (
          <>
            <div className="lblock--l grid-3">
              <input
                type="text"
                className="input"
                placeholder={t(
                  "organization:candidates-profiles.SEARCH_PROFILE",
                )}
                onChange={(e) => setSearch(e.target.value)}
              />
            </div>
            <div className="lblock--l grid --fill">
              {currentTab === Tab.Shared &&
                sharedProfiles.map((profile) => (
                  <ProfileCard
                    key={profile.profileId}
                    profile={profile.profile}
                    isArchived={!!profile.archivedAt}
                    onArchive={() => {
                      confirm(
                        "organization:shared-profile.archive.CONFIRM",
                        () =>
                          archiveSharedProfile(
                            profile.profile.uid,
                            organization.id,
                          ).then(() => {
                            reload();
                          }),
                        true,
                      );
                    }}
                    isFavorited={!!profile.favoritedAt}
                    onFavorite={async () => {
                      await favoriteSharedProfile(
                        profile.profile.uid,
                        organization.id,
                      );
                      reload();
                    }}
                    onUnfavorite={async () => {
                      await unfavoriteSharedProfile(
                        profile.profile.uid,
                        organization.id,
                      );
                      reload();
                    }}
                  />
                ))}
              {currentTab === Tab.Favorited &&
                favoritedProfiles.map((profile) => (
                  <ProfileCard
                    key={profile.profileId}
                    profile={profile.profile}
                    isArchived={!!profile.archivedAt}
                    onArchive={() => {
                      confirm(
                        "organization:shared-profile.archive.CONFIRM",
                        () =>
                          archiveSharedProfile(
                            profile.profile.uid,
                            organization.id,
                          ).then(() => {
                            reload();
                          }),
                        true,
                      );
                    }}
                    isFavorited={!!profile.favoritedAt}
                    onUnfavorite={async () => {
                      await unfavoriteSharedProfile(
                        profile.profile.uid,
                        organization.id,
                      );
                      reload();
                    }}
                  />
                ))}
              {currentTab === Tab.Archived &&
                archivedProfiles.map((profile) => (
                  <ProfileCard
                    key={profile.profileId}
                    profile={profile.profile}
                    isArchived={!!profile.archivedAt}
                    onUnarchive={() => {
                      confirm(
                        "organization:shared-profile.unarchive.CONFIRM",
                        () =>
                          unarchiveSharedProfile(
                            profile.profile.uid,
                            organization.id,
                          ).then(() => {
                            reload();
                          }),
                        true,
                      );
                    }}
                    onDelete={() => {
                      confirm(
                        "organization:shared-profile.delete.CONFIRM",
                        () =>
                          deleteSharedProfile(
                            profile.profile.uid,
                            organization.id,
                          ).then(() => {
                            reload();
                          }),
                        true,
                      );
                    }}
                  />
                ))}
            </div>
          </>
        )}

        {organization.sharedProfiles.length === 0 && (
          <div className="lblock--l info">
            {t("organization:candidates-profiles.NO_PROFILES_YET")}
            <div>
              {t("organization:candidates-profiles.NO_PROFILES_MESSAGE")}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default OrganizationCandidatesProfilesPage;
