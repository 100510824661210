import { useTranslation } from "react-i18next";
import {
  Plan,
  RecurringInterval,
} from "../services/subscription/subscriptionModel";
import { useState } from "react";
import { cx } from "../react-helpers/css";
import SubmitButton from "./SubmitButton";
import DisplayPlan from "./DisplayPlan";

const ChoosePlan = ({
  plans,
  onChoice,
}: {
  plans: Plan[];
  onChoice(choice: Plan, recurrency: RecurringInterval): void | Promise<any>;
}) => {
  const { t } = useTranslation(["subscription"]);
  const [planRecurrency, setPlanRecurrency] =
    useState<RecurringInterval>("monthly");

  return (
    <div className="lblock">
      <div className="cblock">
        <h2 className="title--50 --txt--center">
          {t("subscription:CHOOSE_YOUR_SUBSCRIPTION")}
        </h2>
      </div>

      <div className="cblock lrow --center--h">
        <div>{t("subscription:MENSUAL")}</div>
        <div>
          <button
            type="button"
            className={cx([
              "btn--toggle",
              planRecurrency === "monthly" && "--opt1",
              planRecurrency !== "monthly" && "--opt2",
            ])}
            onClick={() =>
              setPlanRecurrency((prev) =>
                prev === "monthly" ? "annual" : "monthly",
              )
            }
          />
        </div>
        <div>{t("subscription:ANNUAL")}</div>
      </div>

      <div className="cblock grid-2">
        {plans.map((plan) => (
          <DisplayPlan
            plan={plan}
            key={plan.id}
            recurrency={planRecurrency}
            cta={
              <div className="cblock --center--h">
                <SubmitButton
                  className="btn--2 --block"
                  onClick={() => onChoice(plan, planRecurrency)}
                >
                  {t("subscription:CHOOSE_THIS_PLAN")}
                </SubmitButton>
              </div>
            }
          />
        ))}
      </div>
    </div>
  );
};

export default ChoosePlan;
