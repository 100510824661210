import { Link } from "react-router-dom";
import { authService } from "../../../services/auth/authService";
import SxForm from "../../../forms/SxForm";
import { object, string } from "yup";
import SxField from "../../../forms/fields/SxField";
import SubmitButton from "../../../components/SubmitButton";
import { userService } from "../../../services/user/userService";
import { t } from "i18next";

function LoginForm({ profileShareUid }: { profileShareUid?: string }) {
  const auth = authService();
  const { getLoggedUser } = userService();

  return (
    <SxForm
      initialValues={{
        email: "",
        password: "",
      }}
      onSubmit={(loginData) => {
        return auth.login(loginData).then(async () => {
          const loggedUser = await getLoggedUser();

          // NOTE: Used to reload completely the html HEADERS when on login
          // So that we can load stripe without problem
          // SOURCE: https://github.com/stripe/stripe-js/issues/417
          if (profileShareUid) {
            window.location.href = `/profile-share/${profileShareUid}`;
          } else {
            window.location.href = loggedUser.organizationId
              ? "/organization"
              : "/candidate";
          }
        });
      }}
      validationSchema={object({
        email: string().label(t("auth:login.EMAIL")).email().required(),
        password: string()
          .label(t("auth:login.PASSWORD"))
          .password()
          .required(),
      })}
    >
      <SxField name="email" />
      <SxField name="password" />
      <div className="--txt--right">
        <Link className="link --underlined" to="/lost-password">
          {t("auth:login.FORGOT_PASSWORD")}
        </Link>
      </div>
      <div className="lblock--l --txt--center">
        <SubmitButton type="submit">{t("auth:login.SIGN_IN")}</SubmitButton>
      </div>
    </SxForm>
  );
}

export default LoginForm;
