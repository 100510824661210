import { useTranslation } from "react-i18next";
import {
  Invitation,
  Organization,
} from "../../../services/organization/organizationModel";
import { User } from "../../../services/user/userModel";
import { getUserOrganizationStatus } from "../../../services/organization/organizationService";
import { Dispatch, SetStateAction } from "react";
import { cx } from "../../../react-helpers/css";

const UsersTable = ({
  organization,
  users,
  setInviteAndManageUser,
}: {
  organization: Organization;
  users: (User & { invitation?: Invitation })[];
  setInviteAndManageUser: Dispatch<
    SetStateAction<{ user?: User; invitation?: Invitation } | null>
  >;
}) => {
  const { t } = useTranslation(["organization"]);

  return users.length > 0 ? (
    <table className="lblock table">
      <thead>
        <tr>
          <th>{t("organization:users.table-headers.NAME")}</th>
          <th>{t("organization:users.table-headers.STATUS")}</th>
          <th>{t("organization:users.table-headers.EMAIL")}</th>
          <th>{t("organization:users.table-headers.ROLE")}</th>
          <th>{t("organization:users.table-headers.USED_CREDITS")}</th>
          <th />
        </tr>
      </thead>
      <tbody>
        {users.map((user) => {
          const userStatus = getUserOrganizationStatus(
            organization.id,
            user.invitation &&
              (user.invitation.membershipType === "GUEST" ||
                !user.invitation.userId)
              ? undefined
              : user,
            user.invitation,
          );

          return (
            <tr key={`user-${user.id}`}>
              <td>
                {user.lastname} {user.firstname}
              </td>
              <td>
                <span
                  className={cx([
                    "user-status-chip --block",
                    userStatus === "active" && "--active",
                    userStatus === "disabled" && "--disabled",
                  ])}
                >
                  {userStatus === "active" &&
                    t("organization:users.status.ACTIVE")}
                  {userStatus === "pending" &&
                    t("organization:users.status.PENDING")}
                  {userStatus === "disabled" &&
                    t("organization:users.status.DISABLED")}
                </span>
              </td>
              <td>{user.email}</td>
              <td>
                {user.invitation?.membershipType === "GUEST"
                  ? t("organization:users.role.GUEST")
                  : user.adminOrga ||
                      user.invitation?.membershipType === "ADMIN"
                    ? t("organization:users.role.ADMIN")
                    : t("organization:users.role.MEMBER")}
              </td>
              <td>
                {(user.capsules?.length ?? 0) > 0 && user.capsules?.length}
              </td>
              <td>
                <button
                  className="btn--icon --simple --edit"
                  onClick={() => {
                    setInviteAndManageUser(
                      user.invitation
                        ? {
                            invitation: {
                              ...user.invitation,
                              firstname: user.firstname,
                              lastname: user.lastname,
                              email: user.email,
                            },
                          }
                        : {
                            user,
                          },
                    );
                  }}
                />
              </td>
            </tr>
          );
        })}
      </tbody>
    </table>
  ) : (
    <div className="info --compact lblock">
      {t("organization:users.NO_USERS_FOUND")}
    </div>
  );
};

export default UsersTable;
