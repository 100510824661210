import {
  CardElement,
  Elements,
  IbanElement,
  useElements,
} from "@stripe/react-stripe-js";
import { useNavigate } from "react-router-dom";
import { authService } from "../../../services/auth/authService";
import { NewUserData } from "../../../services/user/userModel";
import { NewOrganizationData } from "../../../services/organization/organizationModel";
import {
  Plan,
  RecurringInterval,
} from "../../../services/subscription/subscriptionModel";
import SxForm from "../../../forms/SxForm";
import SubmitButton from "../../../components/SubmitButton";
import { useTranslation } from "react-i18next";
import Dialog from "../../../components/Dialog";
import DisplayPrice from "../../../components/DisplayPrice";
import { useStripeService } from "../../../services/stripe/stripeService";
import { addDays } from "date-fns";
import { useCallback, useState } from "react";
import { StripeError } from "@stripe/stripe-js";
import { cx } from "../../../react-helpers/css";
import IconStripeCard from "../../../assets/icons/icon-stripe-card.svg";
import IconStripeSepa from "../../../assets/icons/icon-stripe-sepa.svg";

const { registerOrganization } = authService();

const BankingInfoForm = ({
  registerData,
  plan,
  recurrency,
  promoCode,
  onClose,
}: {
  onClose: () => void;
  registerData: {
    user: NewUserData;
    organization: NewOrganizationData;
    profileShareUid?: string;
  };
  plan: Plan;
  recurrency: RecurringInterval;
  promoCode: string | null;
}) => {
  const { t } = useTranslation(["common", "subscription"]);
  const elements = useElements();
  const navigate = useNavigate();
  const { stripe } = useStripeService();

  const [paymentMethod, setPaymentMethod] = useState<"card" | "iban">("card");

  const handleSubmit = useCallback(async () => {
    if (!stripe || !elements) return;

    let stripeError: StripeError | undefined;
    let paymentMethodId: string | undefined;

    if (paymentMethod === "card") {
      const cardElement = elements.getElement(CardElement);
      if (cardElement) {
        const { error, paymentMethod } = await stripe.createPaymentMethod({
          type: "card",
          card: cardElement,
        });

        stripeError = error;
        paymentMethodId = paymentMethod?.id;
      }
    } else if (paymentMethod === "iban") {
      const ibanElement = elements.getElement(IbanElement);
      if (ibanElement) {
        const { error, paymentMethod } = await stripe.createPaymentMethod({
          type: "sepa_debit",
          sepa_debit: ibanElement,
          billing_details: {
            name: `${registerData.organization.legalName}`,
            email: registerData.user.email,
          },
        });

        stripeError = error;
        paymentMethodId = paymentMethod?.id;
      }
    }

    if (!stripeError && paymentMethodId) {
      await registerOrganization({
        ...registerData,
        paymentMethodId,
        planId: plan.id,
        recurrency,
        promoCode,
      }).then(
        () => {
          onClose();
          navigate("/login");
        },
        () => {
          onClose();
        },
      );
    }
  }, [
    elements,
    navigate,
    onClose,
    paymentMethod,
    plan.id,
    promoCode,
    recurrency,
    registerData,
    stripe,
  ]);

  return (
    <SxForm initialValues={{}} onSubmit={handleSubmit}>
      <div className="popup_body lblock">
        <div className="lblock payment-method-selector">
          <button
            className={cx([
              "btn--payment-method --card",
              paymentMethod === "card" && "--active",
            ])}
            type="button"
            onClick={() => {
              setPaymentMethod("card");
            }}
          >
            <img src={IconStripeCard} alt="stripe card" className="icon" />
            {t("subscription:PAYMENT_METHOD_CARD")}
          </button>
          <button
            className={cx([
              "btn--payment-method --sepa",
              paymentMethod === "iban" && "--active",
            ])}
            type="button"
            onClick={() => {
              setPaymentMethod("iban");
            }}
          >
            <img src={IconStripeSepa} alt="stripe iban" className="icon" />
            {t("subscription:PAYMENT_METHOD_IBAN")}
          </button>
        </div>

        <div
          className="lblock card-element-wrapper"
          hidden={paymentMethod !== "card"}
        >
          <CardElement
            options={{
              hidePostalCode: true,
            }}
          />
        </div>
        <div
          className="lblock iban-element-wrapper"
          hidden={paymentMethod !== "iban"}
        >
          <IbanElement
            options={{
              supportedCountries: ["SEPA"],
              placeholderCountry: "FR",
            }}
          />
        </div>
      </div>

      <div className="popup_footer">
        <SubmitButton type="submit" className="btn">
          {t("subscription:START_TRIAL_PERIOD")}
        </SubmitButton>
      </div>
    </SxForm>
  );
};

const BankingInfoDialog = ({
  onClose,
  registerData,
  plan,
  recurrency,
  promoCode,
}: {
  onClose: () => void;
  registerData: {
    user: NewUserData;
    organization: NewOrganizationData;
    profileShareUid?: string;
  };
  plan: Plan;
  recurrency: "monthly" | "annual";
  promoCode: string | null;
}) => {
  const { t } = useTranslation(["subscription"]);
  const { stripe } = useStripeService();

  return (
    <Dialog onClose={onClose}>
      <>
        <div className="popup_head">
          <div className="popup_title">{t("subscription:SECURE_PAYMENT")}</div>
        </div>
        <div>
          <div className="popup_body">
            <div className="card --bg">
              <div className="card_body --txt--center cblocks --xs">
                {recurrency === "annual" ? (
                  <>
                    <h1 className="page_title">
                      <DisplayPrice
                        amount={plan.stripeAnnualPrice.amount * 1.2}
                        taxFree={false}
                        currency={plan.stripeAnnualPrice.currency}
                      />{" "}
                      <small>
                        (
                        <DisplayPrice
                          amount={plan.stripeAnnualPrice.amount}
                          taxFree
                          currency={plan.stripeAnnualPrice.currency}
                        />
                        )
                      </small>
                    </h1>
                    <p className="body--30">
                      {t("subscription:ANNUAL_WITHDRAWAL", {
                        replace: {
                          startDate: addDays(new Date(), 30),
                        },
                      })}
                    </p>
                  </>
                ) : (
                  <>
                    <h1 className="page_title">
                      <DisplayPrice
                        amount={plan.stripeMonthlyPrice.amount * 1.2}
                        taxFree={false}
                        currency={plan.stripeMonthlyPrice.currency}
                      />{" "}
                      <small>
                        (
                        <DisplayPrice
                          amount={plan.stripeMonthlyPrice.amount}
                          taxFree
                          currency={plan.stripeMonthlyPrice.currency}
                        />
                        )
                      </small>
                    </h1>
                    <p className="body--30">
                      {t("subscription:MENSUAL_WITHDRAWAL", {
                        replace: {
                          startDate: addDays(new Date(), 30),
                        },
                      })}
                    </p>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>

        <Elements stripe={stripe}>
          <BankingInfoForm
            registerData={registerData}
            plan={plan}
            promoCode={promoCode}
            recurrency={recurrency}
            onClose={onClose}
          />
        </Elements>
      </>
    </Dialog>
  );
};

export default BankingInfoDialog;
