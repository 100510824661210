import { useLoaderData, useRouteLoaderData } from "react-router-dom";
import { Organization } from "../../../services/organization/organizationModel";
import { useTranslation } from "react-i18next";
import ProPaymentMethodForm from "./ProPaymentMethodForm";
import DisplaySubscription from "../../../components/DisplaySubscription";
import useReload from "../../../hooks/useReload";
import { subscriptionService } from "../../../services/subscription/subscriptionService";
import SubscriptionForm from "./SubscriptionForm";
import { Plan } from "../../../services/subscription/subscriptionModel";
import Dialog from "../../../components/Dialog";
import { useEffect, useMemo, useState } from "react";
import { ReturnButton } from "../../../components/ReturnButton";
import BuyCapsulesCreditsDialog from "./BuyCapsulesCreditsDialog";
import { useConfirmationWithIntl } from "../../../components/ConfirmationDialog";
import { User } from "../../../services/user/userModel";

const { cancelSubscription } = subscriptionService();

const SubscriptionPage = () => {
  const { loggedUser } = useRouteLoaderData("logged-user") as {
    loggedUser: User;
  };
  const { organization } = useRouteLoaderData("organization") as {
    organization: Organization;
  };
  const { plans } = useLoaderData() as {
    plans: Plan[];
  };
  const { t } = useTranslation(["organization"]);
  const { confirm } = useConfirmationWithIntl(["organization"]);
  const reload = useReload();
  const [showSubscribeDialog, setShowSubscribeDialog] = useState(false);
  const [showBuyCreditsDialog, setShowBuyCreditsDialog] = useState(false);

  const invitation = useMemo(() => {
    return loggedUser.invitations?.[0] ?? null;
  }, [loggedUser]);

  useEffect(() => {
    function onVisibilityChange() {
      if (document.visibilityState !== "hidden") reload();
    }

    document.addEventListener("visibilitychange", onVisibilityChange);

    return () => {
      document.removeEventListener("visibilitychange", onVisibilityChange);
    };
  }, [reload]);

  return (
    <div className="layout_content --bg--organization">
      <div className="page-content container --wrap">
        <ReturnButton fallbackUrl="../.." />
        <h1 className="page_title">
          {t("organization:subscription.PAGE_TITLE")}
        </h1>

        <div className="lblock grid">
          {!!organization.stripeCustomer && (
            <div className="card --compact">
              <div className="card_head --stretch--h">
                <h2 className="--txt--center">
                  {t("organization:subscription.SUBSCRIPTION")}
                </h2>
              </div>
              <div className="card_body">
                {organization.subscriptions?.[0] ? (
                  <DisplaySubscription
                    subscription={organization.subscriptions[0]}
                    cta={
                      !organization.subscriptions[0].stripeSubscription
                        ?.cancelAt ? (
                        <div className="cblock --txt--center">
                          <button
                            className="link --danger"
                            onClick={() => {
                              confirm(
                                "organization:subscription.cancel.CONFIRM",
                                async () => {
                                  await cancelSubscription(
                                    organization.subscriptions![0]
                                      .stripeSubscriptionId!,
                                  );
                                  reload();
                                },
                                true,
                                {
                                  confirmText: t(
                                    "organization:subscription.cancel.CONFIRM_TEXT",
                                  ),
                                },
                              );
                            }}
                          >
                            {t("organization:subscription.CANCEL")}
                          </button>
                        </div>
                      ) : undefined
                    }
                  />
                ) : (
                  <div className="--txt--center">
                    <button
                      className="btn--1"
                      onClick={() => setShowSubscribeDialog(true)}
                    >
                      {t("organization:subscription.NEW_SUBSCRIPTION")}
                    </button>
                  </div>
                )}
              </div>
            </div>
          )}
          <div className="card --compact">
            <div className="card_head --stretch--h">
              <h2 className="--txt--center">
                {t("organization:subscription.CAPSULES_CREDITS")}
              </h2>
            </div>
            <div className="card_body --txt--center">
              <div className="body--40 cblock">
                {t("organization:subscription.capsules-credits.CREDITS_LEFT")}
              </div>
              <div className="title--60">{organization.maxCapsulesCount}</div>
              <div className="cblock">
                <button
                  className="btn--3"
                  onClick={() => setShowBuyCreditsDialog(true)}
                >
                  {t("organization:subscription.capsules-credits.BUY")}
                </button>
              </div>
            </div>
          </div>
          <div className="card --compact --small-side-padding">
            <div className="card_head --stretch--h">
              <h2 className="--txt--center">
                {t("organization:subscription.PAYMENT_METHOD")}
              </h2>
            </div>
            <div className="card_body">
              <ProPaymentMethodForm organization={organization} />
            </div>
          </div>
          {invitation && (
            <div className="card --compact --small-side-padding">
              <div className="card_head --stretch--h">
                <h2 className="--txt--center">
                  {t("organization:subscription.INVITATION")}
                </h2>
              </div>
              <div className="card_body">
                {(invitation.organization?.subscriptions?.length ?? 0) > 0 ? (
                  <div className="info --success">
                    {t(
                      "organization:subscription.INVITATION_VALID_SUB_DESCRIPTION",
                      {
                        replace: {
                          pro: invitation.organization?.legalName,
                        },
                      },
                    )}
                  </div>
                ) : (
                  <div className="info --warning">
                    {t(
                      "organization:subscription.INVITATION_EXPIRED_SUB_DESCRIPTION",
                      {
                        replace: {
                          pro: invitation.organization?.legalName,
                        },
                      },
                    )}
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      </div>

      {showSubscribeDialog && (
        <Dialog
          onClose={() => setShowSubscribeDialog(false)}
          className="--branded"
        >
          <div className="popup_body">
            <SubscriptionForm
              plans={plans}
              organization={organization}
              onFinished={() => {
                reload();
                setShowSubscribeDialog(false);
              }}
            />
          </div>
        </Dialog>
      )}
      {showBuyCreditsDialog && (
        <BuyCapsulesCreditsDialog
          onClose={() => setShowBuyCreditsDialog(false)}
          organization={organization}
        />
      )}
    </div>
  );
};

export default SubscriptionPage;
