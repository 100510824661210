import { Link, useRouteLoaderData } from "react-router-dom";
import { Organization } from "../../services/organization/organizationModel";
import { useTranslation } from "react-i18next";
import UsersOverview from "./UsersOverview";
import { User } from "../../services/user/userModel";
import { useMemo } from "react";

const OrganizationDashboardPage = () => {
  const { t } = useTranslation(["organization"]);
  const { organization } = useRouteLoaderData("organization") as {
    organization: Organization;
  };
  const { loggedUser } = useRouteLoaderData("logged-user") as {
    loggedUser?: User;
  };

  const invitation = useMemo(() => {
    return loggedUser?.invitations?.[0] ?? null;
  }, [loggedUser]);
  const isValidInvitation = useMemo(() => {
    return (invitation?.organization?.subscriptions?.length ?? 0) > 0;
  }, [invitation]);

  return (
    <div className="layout_content --bg--organization">
      <div className="page-content container --wrap">
        <h1 className="page_title">
          {t("organization:dashboard.WELCOME_RECRUITER_DASHBOARD")}
        </h1>

        {!isValidInvitation &&
          (organization.subscriptions ?? []).length === 0 && (
            <div className="lblock info --warning">
              {t("organization:dashboard.NO_SUBSCRIPTION")}{" "}
              <Link className="link --underlined" to="pro/subscription">
                {t("organization:dashboard.SUBSCRIBE")}
              </Link>
            </div>
          )}
        {!organization.stripeCustomer && (
          <div className="lblock info --warning">
            {t("organization:dashboard.NO_PAYMENT_METHOD")}{" "}
            <Link className="link --underlined" to="pro/subscription">
              {t("organization:dashboard.REGISTER_PAYMENT_METHOD")}
            </Link>
          </div>
        )}
        {invitation &&
          (isValidInvitation ? (
            <div className="lblock info --success">
              {t("organization:subscription.INVITATION_VALID_SUB_DESCRIPTION", {
                replace: {
                  pro: invitation.organization?.legalName,
                },
              })}
            </div>
          ) : (
            <div className="lblock info --warning">
              {t(
                "organization:subscription.INVITATION_EXPIRED_SUB_DESCRIPTION",
                {
                  replace: {
                    pro: invitation.organization?.legalName,
                  },
                },
              )}
            </div>
          ))}
        {organization && !loggedUser?.adminOrga && (
          <div className="lblock info --success">
            {t("organization:MEMBER_OF_ORGANIZATION", {
              replace: {
                pro: organization.legalName,
              },
            })}
          </div>
        )}

        <div className="organisation-dashboard_tiles">
          <Link to="candidates" className="dashboard_tile">
            <canvas className="tile_img --candidates" />
            <h2 className="tile_title">
              {t("organization:dashboard.MY_CANDIDATES")}
            </h2>
          </Link>
          <Link to="capsules" className="dashboard_tile">
            <canvas className="tile_img --capsules" />
            <h2 className="tile_title">
              {t("organization:dashboard.MY_JOB_POSTINGS")}
            </h2>
          </Link>
          <a
            href={t("organization:ORGANIZATION_GUIDE_LINK")}
            target="_blank"
            rel="noreferrer"
            className="dashboard_tile --guide"
          >
            <canvas width="2" height="3" />
          </a>
          {!!loggedUser?.adminOrga && (
            <>
              <Link to="pro/account" className="dashboard_tile">
                <h2 className="tile_title">{t("organization:PRO_ACCOUNT")}</h2>
              </Link>
              <Link to="pro/subscription" className="dashboard_tile">
                <h2 className="tile_title">
                  {t("organization:SUBSCRIPTION_CREDITS")}
                </h2>
              </Link>
              <Link to="pro/users" className="dashboard_tile">
                <h2 className="tile_title">{t("organization:USERS")}</h2>
                <UsersOverview
                  organization={organization}
                  users={organization.users ?? []}
                  invitations={organization.invitations ?? []}
                />
              </Link>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default OrganizationDashboardPage;
