import { useTranslation } from "react-i18next";
import { User } from "../../services/user/userModel";
import { useMemo } from "react";
import {
  Invitation,
  Organization,
} from "../../services/organization/organizationModel";
import { Link } from "react-router-dom";

const UsersOverview = ({
  organization,
  users,
  invitations,
  onNewUser,
}: {
  organization: Organization;
  users: User[];
  invitations: Invitation[];
  onNewUser?: () => void;
}) => {
  const { t } = useTranslation(["organization"]);

  const nonDisabledUsers = useMemo(
    () => users.filter((u) => u.organizationMembershipStatus === "active"),
    [users],
  );

  const adminsCount = useMemo(
    () => nonDisabledUsers.reduce((acc, u) => (u.adminOrga ? acc + 1 : acc), 0),
    [nonDisabledUsers],
  );

  const guestsCount = useMemo(
    () =>
      invitations
        .filter((i) => !i.disabledDate)
        .reduce(
          (acc, i) =>
            i.membershipType === "GUEST" && i.userId ? acc + 1 : acc,
          0,
        ),
    [invitations],
  );

  const pendingCount = useMemo(
    () =>
      invitations
        .filter((i) => !i.disabledDate)
        .reduce((acc, i) => (i.userId == null ? acc + 1 : acc), 0),
    [invitations],
  );

  const seatsLeft = useMemo(() => {
    return Math.max(
      organization.maxMembersCount -
        (nonDisabledUsers.length + guestsCount + pendingCount),
      0,
    );
  }, [
    organization.maxMembersCount,
    nonDisabledUsers.length,
    guestsCount,
    pendingCount,
  ]);

  return (
    <div className="users-overview">
      <div className="category-card --new-user --txt--center">
        <div>{t("organization:users.AVAILABLE_SEATS")}</div>
        <div className="title--70">{seatsLeft}</div>
        {onNewUser &&
          (seatsLeft > 0 ? (
            <div>
              <button className="btn--white" onClick={onNewUser}>
                {t("organization:users.NEW_USER")}
              </button>
            </div>
          ) : (
            <>
              <div>{t("organization:users.NO_SEAT_LEFT")}</div>
              <div>
                <Link
                  to={`/organization/contact?object=${encodeURIComponent(
                    t("organization:users.NEED_MORE_SEAT_OBJECT"),
                  )}`}
                  className="btn--white"
                >
                  {t("organization:users.CONTACT_2MM")}
                </Link>
              </div>
            </>
          ))}
      </div>

      <div className="category-card">
        <div>{t("organization:users-labels.ADMINS")}</div>
        <div className="count">{adminsCount}</div>
      </div>
      <div className="category-card">
        <div>{t("organization:users-labels.MEMBERS")}</div>
        <div className="count">{nonDisabledUsers.length - adminsCount}</div>
      </div>
      <div className="category-card">
        <div>{t("organization:users-labels.GUESTS")}</div>
        <div className="count">{guestsCount}</div>
      </div>
      <div className="category-card --pending">
        <div>{t("organization:users-labels.PENDING")}</div>
        <div className="count">{pendingCount}</div>
      </div>
    </div>
  );
};

export default UsersOverview;
