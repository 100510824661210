import { ReactNode } from "react";
import { useTranslation } from "react-i18next";
import DisplayPrice from "./DisplayPrice";
import { Subscription } from "../services/subscription/subscriptionModel";
import { cx } from "../react-helpers/css";
import { parseISO } from "date-fns";

const DisplaySubscription = ({
  subscription,
  cta,
}: {
  subscription: Subscription;
  cta?: ReactNode;
}) => {
  const { t } = useTranslation(["subscription", "common"]);
  const isMonthly = subscription.recurringInterval === "monthly";

  const isTrialing = subscription.stripeSubscription?.status === "trialing";
  const isCanceled =
    subscription.stripeSubscription?.status === "canceled" ||
    !!subscription.stripeSubscription?.cancelAt;

  return (
    <div>
      {(isTrialing || isCanceled) && (
        <div className="cblock --txt--center">
          <span
            className={cx([
              "subscription-status-chip",
              isTrialing && "--trialing",
              isCanceled && "--canceled",
            ])}
          >
            {isCanceled
              ? t("subscription:CANCELED")
              : isTrialing && t("subscription:TRIALING")}
          </span>
        </div>
      )}
      <div className="title--40 cblock --txt--center">
        {subscription.plan.label}
      </div>

      <div className="title--60 cblock --txt--center">
        <DisplayPrice
          amount={
            isMonthly
              ? subscription.plan.stripeMonthlyPrice.amount
              : subscription.plan.stripeAnnualPrice.amount
          }
          currency={
            isMonthly
              ? subscription.plan.stripeMonthlyPrice.currency
              : subscription.plan.stripeAnnualPrice.currency
          }
          addon={
            isMonthly ? t("subscription:PER_MONTH") : t("subscription:PER_YEAR")
          }
        />
      </div>

      <div className="cblock --txt--center body--40">
        {t("subscription:END_OF_COMMITMENT")}{" "}
        <strong>
          {t("common:COMPACT_DATE", {
            replace: {
              date: parseISO(subscription.endDate),
            },
          })}
        </strong>
      </div>

      {cta}
    </div>
  );
};

export default DisplaySubscription;
