import { useTranslation } from "react-i18next";
import { ReturnButton } from "../../../components/ReturnButton";
import {
  Invitation,
  Organization,
} from "../../../services/organization/organizationModel";
import { useRouteLoaderData } from "react-router-dom";
import { useSearch } from "../../../react-helpers/array";
import { useMemo, useState } from "react";
import UsersOverview from "../UsersOverview";
import InviteAndManageUserDialog from "./InviteAndManageUserDialog";
import { User } from "../../../services/user/userModel";
import { randomUUID } from "../../../react-helpers/crypto";
import { getUserOrganizationStatus } from "../../../services/organization/organizationService";
import UsersTable from "./UsersTable";

const UsersPage = () => {
  const { t } = useTranslation(["organization"]);
  const { organization } = useRouteLoaderData("organization") as {
    organization: Organization;
  };

  const users = useMemo(() => {
    const mergedUsers: Record<
      string | number,
      User & { invitation?: Invitation }
    > = {};

    organization.users?.forEach((user) => {
      mergedUsers[user.id] = user;
    });

    organization.invitations?.forEach((invitation) => {
      if (invitation.userId && invitation.user) {
        if (!mergedUsers[invitation.userId]) {
          // User is not in the orgs users but has a user account so it's an INVITED
          mergedUsers[invitation.userId] = {
            ...invitation.user,
            invitation,
            adminOrga: false,
            organizationMembershipStatus: invitation.disabledDate
              ? "disabled"
              : "active",
          };
        } else {
          // Do nothing user already in organization.users
        }
      } else {
        // User does not have an account it's pending
        const id = randomUUID() as any;
        mergedUsers[id] = {
          ...invitation,
          id,
          invitation,
          adminOrga: false,
        };
      }
    });

    return Object.values(mergedUsers);
  }, [organization.users, organization.invitations]);

  const [searchedUsers, setSearch] = useSearch(users ?? []);

  const [inviteAndManageUser, setInviteAndManageUser] = useState<{
    user?: User;
    invitation?: Invitation;
  } | null>(null);

  const memberCount = useMemo(
    () =>
      users.reduce(
        (acc, user) =>
          getUserOrganizationStatus(
            organization.id,
            user.invitation &&
              (user.invitation.membershipType === "GUEST" ||
                !user.invitation.userId)
              ? undefined
              : user,
            user.invitation,
          ) !== "disabled"
            ? acc + 1
            : acc,
        0,
      ) ?? 0,
    [users, organization.id],
  );

  const seatsLeft = useMemo(() => {
    return Math.max(organization.maxMembersCount - memberCount, 0);
  }, [organization.maxMembersCount, memberCount]);

  return (
    <div className="layout_content --bg--organization">
      <div className="page-content container --wrap">
        <ReturnButton fallbackUrl="../.." />
        <h1 className="page_title">{t("organization:users.PAGE_TITLE")}</h1>

        <div className="lblock lrow --stretch--v">
          <UsersOverview
            organization={organization}
            users={organization.users ?? []}
            invitations={organization.invitations ?? []}
            onNewUser={() => {
              setInviteAndManageUser({});
            }}
          />
        </div>

        <div className="lblock">
          <div className="lblock--l grid-3">
            <input
              type="text"
              className="input"
              placeholder={t("organization:users.SEARCH_USERS")}
              onChange={(e) => setSearch(e.target.value)}
            />
          </div>

          <UsersTable
            users={searchedUsers}
            organization={organization}
            setInviteAndManageUser={setInviteAndManageUser}
          />
        </div>
      </div>

      {inviteAndManageUser && (
        <InviteAndManageUserDialog
          onClose={() => setInviteAndManageUser(null)}
          user={inviteAndManageUser.user}
          invitation={inviteAndManageUser.invitation}
          organization={organization}
          seatsLeft={seatsLeft}
        />
      )}
    </div>
  );
};

export default UsersPage;
